import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  
  const CustomerDialog = (props) => {
    const { open, setOpen, customerOpt, setCustomerOpt } = props;
  
    const [customerDetails, setCustomerDetails] = useState(null);
  
    const handleClose = () => {
      setOpen(false);
    };
    const handleClick = () => {
      setCustomerOpt([...customerOpt, customerDetails]);
      setOpen(false);
    };
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Create Customer"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <Stack direction="column" spacing={1}>
                <Typography variant="body2">Enter customer value</Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  onChange={(e) =>
                    setCustomerDetails({
                      ...customerDetails,
                      value: e.target.value,
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack direction="column" spacing={1}>
                <Typography variant="body2">Enter customer label</Typography>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  onChange={(e) =>
                    setCustomerDetails({
                      ...customerDetails,
                      label: e.target.value,
                    })
                  }
                />
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleClick} autoFocus variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  export default CustomerDialog;
  