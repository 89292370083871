import {Grid, MenuItem, Stack, TextField, Typography, IconButton, Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import { format, parseISO } from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import eurLogo from "../../../../assets/images/logo/sanEurLogoJpeg.jpeg";
import intlLogo from "../../../../assets/images/logo/sanIntlLogoJpeg.jpeg";
import { companies, paymentTerm, titles } from "../../../../utils/menu-items";
import { updateMultipleOrdersApi } from "../../../../components/api/OrderApiService";
import { ButtonGroup } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { retrieveFilteredAddressesApi } from "../../../../components/api/AddressApiService";
import { updateMultipleShippingInstructionsApi } from "../../../../components/api/ShippingInstructionApiService";


const convertImageToBase64 = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

const createExcelWithLogoAndData = async (proformaDetails) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("My Sheet");
  const len = proformaDetails?.items.length;

  const buyingCompany =
  proformaDetails?.buyingCompany === "SGE"
    ? "SGE"
    : "SGI";

  const buyingCompanyHeader =
    proformaDetails?.buyingCompany === "SGE"
      ? "SanGroup Europe Kft."
      : "SANGROUP INTERNATIONAL PTE.LTD.";
  const buyingAddressOne =
    proformaDetails?.buyingCompany === "SGE"
      ? "Stefania ut 101-103"
      : "11 Irwing Place, # 09--02 Tai Seng Point Singapore 369551";
  const buyingAddressTwo =
    proformaDetails?.buyingCompany === "SGE"
      ? "H-1143 Budapest, Hungary"
      : "Company Registration / Tax No : 201717395N";
  const buyingAddressThree =
    proformaDetails?.buyingCompany === "SGE"
      ? "Registration no: 01-09-400244 VAT no: Hu27842588"
      : "sgi.info@sangroupintl.com";

      const buyingInfoOne =
      proformaDetails?.buyingCompany === "SGE"
        ? "Stefánia út 101-103, H-1143 Budapest, Hungary"
        : "UOB United Overseas Bank, Singapore USD Acc: 396-900-795-7 Bank code: 7275 ; SWIFT: UOVBSGSG";
      const buyingInfoTwo =
      proformaDetails?.buyingCompany === "SGE"
        ? ""
        : "Corr Bank: Bank of America New York ; SWIFT (BOFA): BOFAUS3N";
      const buyingInfoThree =
      proformaDetails?.buyingCompany === "SGE"
        ? "Bank: OTP Bank, Budapest"
        : "UOB United Overseas Bank, Singapore";
      const buyingInfoFour =
      proformaDetails?.buyingCompany === "SGE"
        ? "USD IBAN HU05117631344129801700000000"
        : "Singapore Main Branch";
      const buyingInfoFive =
      proformaDetails?.buyingCompany === "SGE"
        ? "SWIFT: OTPVHUHBXXX"
        : "80 Raffles Place, UOB Plaza 1, #15-02, Singapore 048624";


  const totalNumberOfCases = proformaDetails?.items.reduce((total, item) => {
    return total + (item?.numberOfCases || 0);
  }, 0);

  const totalNetWeight = proformaDetails?.items.reduce((total, item) => {
    return total + (item?.volume || 0);
  }, 0);

  const totalTotalAmount = proformaDetails?.items.reduce((total, item) => {
    return total + (item?.totalAmount || 0);
  }, 0);

  var logo;
  // Convert and add the intlLogo
  if (proformaDetails.buyingCompany === "SGI") {
    logo = intlLogo;
  } else if (proformaDetails.buyingCompany === "SGE") {
    logo = eurLogo;
  } else {
    logo = intlLogo;
  }
  convertImageToBase64(logo, async (base64Image) => {
    const imageId = workbook.addImage({
      base64: base64Image,
      extension: "jpeg",
    });

    worksheet.addImage(imageId, "B2:C4");
    worksheet.pageSetup.printArea = `B1:H${45+len}`;

    worksheet.pageSetup.fitToPage = true;
    worksheet.pageSetup.fitToWidth = 1;
    worksheet.pageSetup.fitToHeight = 0;


    const startRow = 3;
    const headerRowNumber = startRow + 20; 

    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF4F81BD" }, 
    };

    const dataForExcel = [
      [],
      [],
      [],
      [],
      [,"                                                                       PROFORMA INVOICE                                                                                         "],
      [,"From:", "" ,,, "Date: ",proformaDetails?.proformaDate
      ? format(
          parseISO(proformaDetails.proformaDate),
          "dd/MMM/yyyy",
        )
      : "",],
      [,,,,,"Invoice No: ", (proformaDetails?.proformaNumber|| "")],
      [,buyingCompanyHeader],
      [,buyingAddressOne,,,,"Place of Loading: ", (proformaDetails?.pointOfLoading|| "")],
      [,buyingAddressTwo],
      [,buyingAddressThree,,,,"Place of Discharge: ", (proformaDetails?.pointOfUnloading|| "")],
      [],
      [,,,,,"Country of Origin: ", (proformaDetails?.origin|| "")],
      [],
      [,"Invoice To:",,,,"Reference To: ", (proformaDetails?.soNumber|| "")],
      [, proformaDetails?.customerName],
      [,proformaDetails?.companyAddressOne],
      [,proformaDetails?.companyAddressTwo],
      [,,,,,"Delivery Terms: ", (proformaDetails?.deliveryTerms|| "")],
      [],
      [,
        "Grade and Mark",
        "Net Weight (Kg)",
        "# of Cartons",
        "Gross Weight (Kg)",
        "Description",
        "Unit Price "+(proformaDetails?.currency || "")+"/Kg (Net)",
        "Total Price ("+(proformaDetails?.currency || "")+")",
      ],
      ...proformaDetails?.items.map((item, index) => [
        ,
        (item?.supplierGradeMark ? ("Grade  "+ item?.supplierGradeMark):"")+"\n"+(item?.crop ? ("Crop:   "+item?.crop):""),
        item?.volume? new Intl.NumberFormat('en-EN', { style: 'decimal', minimumFractionDigits: 2 }).format(item?.volume): '',
        item?.numberOfCases? new Intl.NumberFormat('en-EN', { style: 'decimal', minimumFractionDigits: 2 }).format(item?.numberOfCases): '',
        item?.grossWeight,
        item?.descriptionOfGoods || "",
        item?.finalPrice? new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(item?.finalPrice): '',
        item?.totalAmount? new Intl.NumberFormat('en-EN', { style: 'decimal', minimumFractionDigits: 2 }).format(item?.totalAmount): '',
      ]),
      ["",
        "Totals:",
        totalNetWeight? new Intl.NumberFormat('en-EN', { style: 'decimal', minimumFractionDigits: 2 }).format(totalNetWeight) : '',
        totalNumberOfCases? new Intl.NumberFormat('en-EN', { style: 'decimal', minimumFractionDigits: 2 }).format(totalNumberOfCases)
        : ''," "," "," "," ",
      ],
      [],
      [,
        "Payment Terms: " + (proformaDetails?.paymentTerm || "")
      ],
      [],
      [],
      [,,,, "Total:", (proformaDetails?.deliveryTerms|| ""),,totalTotalAmount? new Intl.NumberFormat('en-EN', { style: 'decimal', minimumFractionDigits: 2 }).format(totalTotalAmount): ''],
      [],
      [],
      [,buyingCompanyHeader],
      [,buyingInfoOne],
      [,buyingInfoTwo],
      [,buyingInfoThree],
      [,buyingInfoFour],
      [,buyingInfoFive],
      [],
      [],
      [],
      [,,,
        buyingCompany ==='SGI' ? ("         "+buyingAddressOne) : "",
        buyingCompany ==='SGE' ? ("      "+buyingAddressOne):null,
      ],
      [, , ,buyingCompany ==='SGI' ? ("                    "+buyingAddressTwo) : "",
      buyingCompany ==='SGE' ? buyingAddressTwo:null,],
    ];

    // Example: Inserting a row of data
    dataForExcel.forEach((row, index) => {
      const rowIndex = startRow + index;
      const excelRow = worksheet.getRow(rowIndex);
      row.forEach((cell, cellIndex) => {
        const cellAddress = excelRow.getCell(cellIndex + 1);
        cellAddress.value = cell;
   
        if (rowIndex === headerRowNumber) {
          cellAddress.font = { bold: true, size: 8, };
          cellAddress.alignment = {
            vertical: "middle",
            wrapText: "true",
            horizontal:"center",
          };
          if (cellIndex === 1) {
            cellAddress.alignment = {
              vertical: "middle",
              wrapText: "true",
              horizontal:"center",
            };
            worksheet.getColumn(cellIndex+1).width = 20;
            worksheet.getColumn(cellIndex+2).width = 9;
            worksheet.getColumn(cellIndex+4).width = 10;
            worksheet.getColumn(cellIndex+6).width = 12;
            worksheet.getColumn(cellIndex+8).width = 12;
              worksheet.getColumn(cellIndex+3).width = 12;
              worksheet.getColumn(cellIndex+5).width = 20;
              worksheet.getColumn(cellIndex+7).width = 12;
            }else if (cellIndex === 6 || cellIndex === 7 ) {
              cellAddress.alignment = {
                  vertical: "middle",
                  wrapText: "true",
                  horizontal:"center",
                };
            }else{
            cellAddress.alignment = {
                vertical: "middle",
                wrapText: "true",
                horizontal:"center",
              };
          }
          cellAddress.font = {
            size: 8,
            bold: true,
          };
          cellAddress.border = {
            top: { style: "thin" , color: { argb: "6e6e6e" }},
            bottom: { style: "thin" , color: { argb: "6e6e6e" }},
            left: { style: "thin", color: { argb: "6e6e6e" } },
            right: { style: "thin", color: { argb: "6e6e6e" } },
          };
        } else if (rowIndex === 3) {
            cellAddress.border = {
                top: { style: "medium" },
              };
          } else if (rowIndex === 7) {
          cellAddress.font = {
            bold: true,
            underline: true,
            size: 13,
            color: { argb: "002060" },
          };
        } 
        else if (rowIndex === 8) {
          cellAddress.font = {
            underline: true,
            size: 8,
          };
          cellAddress.alignment = {
            vertical: "middle",
          };
          if (cellIndex === 5) {
            cellAddress.alignment = {
              vertical: "middle",
              horizontal:"right",
            };
          }
        }else if(rowIndex === 9) {
          cellAddress.font = {
            size: 8,
          };
          if (cellIndex === 5) {
            cellAddress.alignment = {
              vertical: "middle",
              horizontal:"right",
            };
          }
        }
        else if(rowIndex === 10) {
          cellAddress.font = {
            size: 8,
            bold: true,
          };
          if (cellIndex === 5) {
            cellAddress.alignment = {
              vertical: "middle",
              horizontal:"right",
            };
          }
        }
        else if (rowIndex > 23 && rowIndex <= 23 + len) {

          
  const item = proformaDetails?.items[rowIndex - 24]; 
  const isNotEmpty = Object.values(item).some(value => value !== "");

  if (isNotEmpty) {
    cellAddress.border = {
      left: { style: "thin", color: { argb: "6e6e6e" } },
      right: { style: "thin", color: { argb: "6e6e6e" } },
      bottom: { style: "hair", color: { argb: "6e6e6e" } },
    };
  }else{
    cellAddress.border = {
      left: { style: "thin", color: { argb: "6e6e6e" } },
      right: { style: "thin", color: { argb: "6e6e6e" } },
    };
  }
          if(cellIndex !== 1){
            cellAddress.alignment = {
              wrapText: "true",
              vertical: "middle",
              horizontal: "center",
            };
        }else{
          cellAddress.alignment = {
            wrapText: "true",
            vertical: "middle",
          };
        }
          cellAddress.font = {
            size: 8,
          };
        }
       else if (rowIndex === 11 || rowIndex === 12 || rowIndex === 13 ) {
            cellAddress.font = {
              size: 8,
            };
            if (cellIndex === 5) {
              cellAddress.alignment = {
                vertical: "middle",
                horizontal:"right",
              };
            }
        } else if (rowIndex === 15) {
              cellAddress.font = {
                size: 8,
              }; 
              if (cellIndex === 5) {
                cellAddress.alignment = {
                  vertical: "middle",
                  horizontal:"right",
                };
              }
          }else if (rowIndex === 17 || rowIndex === 18 || rowIndex === 19 || rowIndex === 20) { 
                cellAddress.font = {
                  size: 8,
              }
              if (rowIndex === 17 && cellIndex === 1) {
                cellAddress.font = {
                  size: 8,
                  underline: true,
                };
                cellAddress.alignment = {
                  vertical: "middle",
                };
              }else if (cellIndex === 5) {
                cellAddress.alignment = {
                  vertical: "middle",
                  horizontal:"right",
                };
              }else{
                cellAddress.alignment = {
                  vertical: "middle",
                };
              }
          }else if (rowIndex === 21) {  
                cellAddress.font = {
                    size: 8,
                  };
                  if (cellIndex === 5) {
                    cellAddress.alignment = {
                      vertical: "middle",
                      horizontal:"right",
                    };
                  }
          }else if (rowIndex === 24 + len) {  
                cellAddress.font = {
                  size: 8,
                  bold: true,
                };
                cellAddress.alignment = {
                  vertical: "middle",
                  horizontal: "center",
                };
                cellAddress.border = {
                  left: { style: "thin", color: { argb: "6e6e6e" } },
                  right: { style: "thin", color: { argb: "6e6e6e" } },
                  top: { style: "thin", color: { argb: "6e6e6e" } },
                  bottom: { style: "thin", color: { argb: "6e6e6e" } },
                };
          }else if (rowIndex === 26 + len) {  
            cellAddress.font = {
              size: 8,
            };
       }else if (rowIndex === 29 + len) {  
         cellAddress.font = {
            size: 12,
            underline: "double",
            bold: true,
         };
          } else if (rowIndex > 31+len && rowIndex <= 37 + len) {
            cellAddress.font = {
              size: 7,
            };
          } else if (rowIndex > 40+len && rowIndex <= 42 + len) {
            cellAddress.font = {
              size: 7,
              color: { argb: "a0a3a2" },
            };
          }
      });
      excelRow.commit();
    });

    // Adjust column widths
    /*worksheet.columns.forEach((column, i) => {
        column.width = 10; // Adjust based on your content
      });*/

    // Generate and save the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "OfferWithLogo.xlsx");
  });
};


const ExportProformaOrder = (props) => {
  const { setExportProformaDetailsPopup, exportProformaDetailsPopup } = props;
  const [entities, setEntities] = useState([]);
  const[filteredAddresses, setFilteredAddresses] = useState([]);
  const[selectedAddress, setSelectedAddress] = useState(null);
  const [items, setItems] = useState(
    exportProformaDetailsPopup && exportProformaDetailsPopup.data
      ? (() => {
          const mappedItems = exportProformaDetailsPopup.data.map((salesOrder) => ({
            supplierGradeMark: salesOrder?.offer?.item?.buyingGrade || "",
            crop: salesOrder?.offer?.item?.crop || "",
            numberOfCases: salesOrder?.numberOfCases || "",
            netWeightPerCase: salesOrder?.netWeightPerCase || "",
            customerGradeMark: salesOrder?.offer?.salesGrade || "",
            volume: salesOrder?.volume || "",
            price: salesOrder?.finalPrice || "",
            incoterm: salesOrder?.incoterm || "",
            estimatedTimeOfDeparture: salesOrder?.estimatedTimeOfDeparture
              ? new Date(salesOrder?.estimatedTimeOfDeparture).toLocaleDateString(
                  "en-GB"
                )
              : "",
            finalPrice: salesOrder?.finalPrice || "",
            totalAmount: salesOrder?.totalAmount || "",
            descriptionOfGoods: salesOrder?.descriptionOfGoods || "",
          }));
  
          // Ensure the array has exactly 10 elements
          while (mappedItems.length < 8) {
            mappedItems.push({
              supplierGradeMark: "",
              crop: "",
              numberOfCases: "",
              netWeightPerCase: "",
              customerGradeMark: "",
              volume: "",
              price: "",
              incoterm: "",
              estimatedTimeOfDeparture: "",
              finalPrice: "",
              totalAmount: "",
            });
          }
          return mappedItems;
        })()
      : Array(8).fill({
        supplierGradeMark: "",
        crop: "",
        numberOfCases: "",
        netWeightPerCase: "",
        customerGradeMark: "",
        volume: "",
        price: "",
        incoterm: "",
        estimatedTimeOfDeparture: "",
        finalPrice: "",
        totalAmount: "",
        })
  );

  const updateShippingInstructionsForAll = () => {
    updateMultipleShippingInstructionsApi(exportProformaDetailsPopup?.data)
                .then((response) => {
                  console.log(response?.data);
                  toast.success("Shipping Instruction(s) updated successfully.");
                })
                .catch((error) => {
                  console.log(error.response?.data);
                  toast.error("Shipping Instruction(s) could not be updated.");
                });
  };
  const [proformaDetails, setProformaDetails] = useState({
    soNumber: exportProformaDetailsPopup?.data[0]?.salesOrderNumber || "",
    proformaDate: exportProformaDetailsPopup?.data[0]?.proformaDate || "",
    invoiceNumber: exportProformaDetailsPopup?.data[0]?.proformaNumber || "",
    pointOfLoading: exportProformaDetailsPopup?.data[0]?.portOfLoading || "",
    pointOfUnloading: exportProformaDetailsPopup?.data[0]?.portOfDischarge || "",
    origin: exportProformaDetailsPopup?.data[0]?.offer?.item?.origin,
    referenceTo: exportProformaDetailsPopup?.data[0]?.customerReference || "",
    deliveryTerms: (exportProformaDetailsPopup?.data[0]?.incoterm || "" )+ " " +(exportProformaDetailsPopup?.data[0]?.portOfDischarge || ""),
    items: items,
    paymentTerm: exportProformaDetailsPopup?.data[0]?.paymentTerm || "",
    vendor: exportProformaDetailsPopup?.data[0]?.offer?.stakeholder || "",
    customer: exportProformaDetailsPopup?.data[0]?.offer?.stakeholder || "",
    descriptionOfGoods: exportProformaDetailsPopup?.data[0]?.descriptionOfGoods || "",
    descriptionTwo: "",
    currency:exportProformaDetailsPopup?.data[0]?.finalPriceCurrency || "",
    transportationContact: exportProformaDetailsPopup?.data[0]?.transportationContact || "",
    buyingCompany: exportProformaDetailsPopup?.data[0]?.company|| "",
    customerName: exportProformaDetailsPopup?.data[0]?.offer?.stakeholder?.name || "",
    companyAddressOne: "",
    companyAddressTwo: "",
  });

  const filterAddress = ({
    id: 0,
    stakeholderId: exportProformaDetailsPopup?.data[0]?.offer?.stakeholder?.id,
    stakeholderIdList: [exportProformaDetailsPopup?.data[0]?.offer?.stakeholder?.id],
    type: "",
    typeList: ["Pay To", "Bill To", "Ship To"],
    street: "",
    streetList: [],
    zipCode: "",
    zipCodeList: [],
    city: "",
    cityList: [],
    county: "",
    countyList: [],
    country: "",
    countryList: [],
    remark: "",
    recordStatusList: ["ACTIVE"],
  });

  function retrieveStakeholderAddresses(filterAddress) {
    retrieveFilteredAddressesApi(filterAddress)
      .then((response) => {
        setFilteredAddresses(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  var today = new Date();
  const formattedToday = format(today, "yyyy-MM-dd");

  useEffect(() => {
    if(!exportProformaDetailsPopup?.data[0]?.proformaDate){
      setExportProformaDetailsPopup(prevState => ({
        ...prevState,
        data: prevState.data.map(item => ({
          ...item,
          proformaDate: formattedToday,
        })),
      }));
    }
    retrieveStakeholderAddresses(filterAddress);
  }, []);

  return (
    // Added return statement here
    <>
      <Stack direction="column">
        <Typography variant="body1" mb={2}>
          Selected Items
        </Typography>
        {exportProformaDetailsPopup &&
          exportProformaDetailsPopup.data &&
          exportProformaDetailsPopup.data.map((proforma, index) => (
            <Grid container key={proforma.id} spacing={2}
            style={{ border: '1px solid #a8aebd', borderRadius: '25px', marginBottom: '30px', paddingBottom:'15px'}}>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2"> SI ID </Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={proforma?.id || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <Typography variant="body2">Supplier Grade Mark</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={proforma?.offer?.item?.buyingGrade || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1.5}>
                <Typography variant="body2">Customer Grade Mark</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={proforma?.offer?.salesGrade || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Crop</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={proforma?.offer?.item?.crop || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2"># Of Cartons</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={proforma?.numberOfCases || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Net Weight/Carton</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={proforma?.netWeightPerCase || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Volume</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={proforma?.volume || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Final Price Per Kg</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={proforma?.finalPrice ? (proforma?.finalPrice +" "+ proforma?.finalPriceCurrency):""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Total Amount</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={proforma?.totalAmount || ""}
                />
              </Grid>
              <Grid item xs={12} sm={9.5}>
            <Typography variant="body2">
            Description Of Goods
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proforma?.descriptionOfGoods}
              disabled
            />
          </Grid> 
            </Grid>
          ))}
        <Typography variant="body1" mb={2} marginTop={5}>
          Proforma Invoice Details
        </Typography>
        <Grid container spacing={2} marginBottom={5}>

        <Grid item xs={12} sm={2}>
            <Typography variant="body2">Proforma Invoice Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.proformaNumber}
              onChange={(e) => {
                setProformaDetails({
                  ...proformaDetails,
                  proformaNumber: e.target.value,
                });
                setExportProformaDetailsPopup(prevState => ({
                  ...prevState,
                  data: prevState.data.map(item => ({
                    ...item,
                    proformaNumber: e.target.value, 
                  })),
                }));
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Proforma Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              value={
                exportProformaDetailsPopup?.data[0]?.proformaDate
                  ? format(
                      parseISO(exportProformaDetailsPopup?.data[0]?.proformaDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                setProformaDetails({
                  ...proformaDetails,
                  proformaDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                })
                setExportProformaDetailsPopup(prevState => ({
                  ...prevState,
                  data: prevState.data.map(item => ({
                    ...item,
                    proformaDate: e.target.value
                    ? e.target.value + "T00:00:00.000+00:00"
                    : null,
                  })),
                }));
              }}
            />
          </Grid>

        <Grid item xs={12} sm={1.5}>
            <Typography variant="body2">PO Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.poNumber}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={1.5}>
            <Typography variant="body2">SO Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.soNumber}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={1.5}>
            <Typography variant="body2">SI Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.siNumber}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={1.5}>
            <Typography variant="body2">SI Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              value={
                proformaDetails?.siDate
                  ? format(
                      parseISO(proformaDetails.siDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={2}> </Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Buying Company</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-title"
              value={proformaDetails?.buyingCompany}
              disabled
            >
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SI To</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.siTo}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Contact Person</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.vendor?.contactPerson}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipper</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.shipper?.name}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Consignee</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.consignee?.name}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Notify Parties</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.notify?.name}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
      <Typography variant="body2">Select Address Line 1</Typography>
      <Autocomplete
        options={filteredAddresses}
        getOptionLabel={(option) => {
          if (!option) return '';
          const type = option?.type || "";
          const street = option?.street || "";
          const city = option?.city || "";
      
          const addressPart1 = [street, city].filter(Boolean).join(", ");
          return `${type}: ${addressPart1}`;
        }}
        value={selectedAddress}
        onChange={(event, newValue) => {
          const newTerm = newValue ? newValue.value : "";
          setSelectedAddress(newValue);

    const street = newValue?.street || "";
    const city = newValue?.city || "";
    const county = newValue?.county || "";
    const zipCode = newValue?.zipCode || "";
    const country = newValue?.country || "";

    const companyAddressOne = [street, city].filter(Boolean).join(", ");
    const companyAddressTwo = [county, zipCode, country].filter(Boolean).join(", ");

    setProformaDetails((prevDetails) => ({
      ...prevDetails,
      companyAddressOne: companyAddressOne,
      companyAddressTwo: companyAddressTwo,
    }));
  }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
        renderTags={() => null}
      />
    </Grid>

          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
              Seller Company Address Line 2
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={(selectedAddress?.country||"") + "  " + (selectedAddress?.zipCode||"")}
            />
          </Grid>
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Address Line One</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.companyAddressOne}
              onChange={(e) => {
                setProformaDetails({
                  ...proformaDetails,
                  companyAddressOne: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
            {selectedAddress && (<Typography variant="body2" style={{ color: "red" }}>(Please use this field to make changes to the address as it will appear on the PDF. Note that your changes will not affect the actual address information.)</Typography>)}
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Address Line Two</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.companyAddressTwo}
              onChange={(e) => {
                setProformaDetails({
                  ...proformaDetails,
                  companyAddressTwo: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
            {selectedAddress && (<Typography variant="body2" style={{ color: "red" }}>(Please use this field to make changes to the address as it will appear on the PDF. Note that your changes will not affect the actual address information.)</Typography>)}
          </Grid>
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
              Place Of Loading
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.pointOfLoading || ""}
              disabled
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
            Place Of Discharge
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.pointOfUnloading || ""}
              disabled
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>

          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
            Revision
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={proformaDetails?.revision}
            />
          </Grid> 
         
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
            Description Line 2
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.descriptionTwo}
              onChange={(e) => {
                setProformaDetails({
                  ...proformaDetails,
                  descriptionTwo: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid> 

<Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
            Contact Details For Transportation
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={proformaDetails?.transportationContact}
              onChange={(e) => {
                setProformaDetails({
                  ...proformaDetails,
                  transportationContact: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid> 
        
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>{
              setExportProformaDetailsPopup({
                data: null,
                visible: false,
              })
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {createExcelWithLogoAndData(proformaDetails)
              updateShippingInstructionsForAll()}}
          >
            Create Shipping Instruction File
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default ExportProformaOrder;
