import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";

import TextField from "@mui/material/TextField";
import { visuallyHidden } from "@mui/utils";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ExportExcel from "../../../utils/excelExport";
import { Stack } from "@mui/material";
import { useState } from "react";

function createData(
  id,
  vendorOfferId,
  vendorName,
  customerOfferId,
  customerName,
  itemId,
  itemSalesGrade,
  itemBuyingGrade,
  itemCustomerGrade,
  sampleId,
  costId,
  remark,
) {
  return {
    id,
    vendorOfferId,
    vendorName,
    customerOfferId,
    customerName,
    itemId,
    itemSalesGrade,
    itemBuyingGrade,
    itemCustomerGrade,
    sampleId,
    costId,
    remark,
  };
}

const rows = [
  createData(
    1241,
    12432,
    "AOI",
    7874,
    "PT Namora",
    445,
    "4GFS",
    "3PO3",
    "J3JFO",
    2564,
    65451,
    "-",
  ),
  createData(
    5461,
    64543,
    "AOI",
    9997,
    "Thanh Hoa",
    4654,
    "7CC4",
    "5REG",
    "6O4U",
    54590,
    91215,
    "-",
  ),
  createData(
    3411,
    76395,
    "Beluka",
    8622,
    "ULT Dubai/Mr Lin",
    44598,
    "2VRT",
    "4BL7",
    "C45KV",
    7153,
    40502,
    "-",
  ),
  createData(
    1661,
    98564,
    "PTBPT",
    1254,
    "Thanh Hoa",
    9905,
    "ER6ER5",
    "24V5",
    "2VOT4",
    6248,
    6545,
    "-",
  ),
  createData(
    1722,
    73145,
    "PTBPT",
    3354,
    "Jie Ying Tobacco",
    7054,
    "243DT",
    "DCT4",
    "34VR",
    98,
    64747,
    "-",
  ),
  createData(
    9583,
    46456,
    "ULT",
    7822,
    "Hanwa",
    20256,
    "WER65",
    "EWR56",
    "554RT",
    4745,
    70540,
    "-",
  ),
  createData(
    5324,
    66576,
    "ULT",
    1121,
    "Jie Ying Tobacco",
    45,
    "QEW5",
    "SDF6",
    "QW6R546",
    544,
    1205,
    "-",
  ),
  createData(
    1123,
    95494,
    "CTC",
    9987,
    "Hanwa",
    1235,
    "RG4ET",
    "4ET4",
    "5WQR5",
    935,
    6510,
    "-",
  ),
  createData(
    5432,
    67853,
    "Socotab",
    3365,
    "Khatoco",
    9824,
    "EW54",
    "GGF6",
    "GER43",
    1022,
    7812,
    "-",
  ),
  createData(
    9995,
    23344,
    "CTC",
    7787,
    "Khatoco",
    3541,
    "5623F",
    "34FD",
    "RE56",
    6055,
    6445,
    "-",
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "vendorOfferId",
    numeric: true,
    disablePadding: true,
    label: "Vendor Offer ID",
  },
  {
    id: "vendorName",
    numeric: true,
    disablePadding: true,
    label: "Vendor Name",
  },
  {
    id: "customerOfferId",
    numeric: true,
    disablePadding: true,
    label: "Customer Offer ID",
  },
  {
    id: "customerName",
    numeric: true,
    disablePadding: true,
    label: "Customer Name",
  },
  {
    id: "itemId",
    numeric: true,
    disablePadding: false,
    label: "Item ID",
  },
  {
    id: "itemSalesGrade",
    numeric: true,
    disablePadding: false,
    label: "Item Sales Grade",
  },
  {
    id: "itemBuyingGrade",
    numeric: true,
    disablePadding: false,
    label: "Item Buying Grade",
  },
  {
    id: "itemCustomerGrade",
    numeric: true,
    disablePadding: false,
    label: "Item Customer Grade",
  },
  {
    id: "sampleId",
    numeric: true,
    disablePadding: false,
    label: "Sample ID",
  },
  {
    id: "costId",
    numeric: true,
    disablePadding: false,
    label: "Cost ID",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: false,
    label: "Remark",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, setShowFilters, showFilters, rows } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" spacing={1} alignItems={"center"}>
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ fontWeight: 600, fontSize: "18px" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Offers
          </Typography>
        )}

        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack direction={"row"} spacing={1} sx={{ mr: 2 }}>
        <ExportExcel excelData={rows} fileName={"ExcelExport"} buttonLabel="Export Table"/>

        <Button disableElevation variant="outlined" size="small">
          Add
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px" }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function OfferView() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showFilters, setShowFilters] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage],
  );

  return (
    <div>
      {showFilters && (
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <Grid
            container
            spacing={1}
            justifyContent="flex-start"
            alignItems="center"
          >
            <TextField id="outlined-search" label="Vendor" type="search" />
            <TextField id="outlined-search" label="Customer" type="search" />
            <TextField
              id="outlined-search"
              label="Item SGI Grade"
              type="search"
            />
            <TextField
              id="outlined-search"
              label="Item Buying Grade"
              type="search"
            />
            <TextField
              id="outlined-search"
              label="Item Customer Grade"
              type="search"
            />
            <TextField id="outlined-search" label="Sample" type="search" />
            <TextField id="outlined-search" label="Cost" type="search" />
            <TextField id="outlined-search" label="Remark" type="search" />
          </Grid>
          <br></br>
          <Grid
            container
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item xs={3} sm={1}>
              <Button disableElevation variant="contained" fullWidth>
                Search
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box sx={{ width: "100%", mt: 2 }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            showFilters={showFilters}
            setShowFilters={setShowFilters}
            rows={rows}
          />
          <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    minWidth: 750,
                    '& .MuiTableCell-head': {
                      backgroundColor: '#20315d',
                      color: 'white',
                    },
                  }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      style={
                        index % 2
                          ? { background: "#f0f0f0" }
                          : { background: "white" }
                      }
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell padding="normal">
                        <Button>Edit</Button>
                      </TableCell>
                      <TableCell align="center">{row.id}</TableCell>
                      <TableCell align="center">{row.vendorOfferId}</TableCell>
                      <TableCell align="center">{row.vendorName}</TableCell>
                      <TableCell align="center">
                        {row.customerOfferId}
                      </TableCell>
                      <TableCell align="center">{row.customerName}</TableCell>
                      <TableCell align="center">{row.itemId}</TableCell>
                      <TableCell align="center">{row.itemSalesGrade}</TableCell>
                      <TableCell align="center">
                        {row.itemBuyingGrade}
                      </TableCell>
                      <TableCell align="center">
                        {row.itemCustomerGrade}
                      </TableCell>
                      <TableCell align="center">{row.sampleId}</TableCell>
                      <TableCell align="center">{row.costId}</TableCell>
                      <TableCell align="center">{row.remark}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </div>
  );
}
