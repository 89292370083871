import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  LoadingOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  AppstoreAddOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
};

const utilities = {
  id: "utilities",
  title: "Main Menu",
  type: "accordion",
  children: [
    {
      id: "item",
      title: "Items",
      type: "item",
      url: "/item-view",
      icon: icons.BarcodeOutlined,
    },
    {
      id: "sample",
      title: "Samples",
      type: "item",
      url: "/sample-view",
      icon: icons.AntDesignOutlined,
    },
    // {
    //   id: "offer",
    //   title: "Offers",
    //   type: "item",
    //   url: "/offer-view",
    //   icon: icons.FileTextOutlined,
    // },
    // {
    //   id: "vendor-offer",
    //   title: "Vendor Offers",
    //   type: "item",
    //   url: "/vendor-offer-view",
    //   icon: icons.FileTextOutlined,
    // },

    // {
    //   id: "customer-offer",
    //   title: "Customer Offers",
    //   type: "item",
    //   url: "/customer-offer-view",
    //   icon: icons.FileTextOutlined,
    // },
    /* {
      id: "cost",
      title: "Admin Costs",
      type: "item",
      url: "/admin-cost-view",
      icon: icons.DollarOutlined,
    },*/
    {
      id: "cost",
      title: "Costs",
      type: "item",
      url: "/cost-view",
      icon: icons.DollarOutlined,
    },
    {
      id: "trade",
      title: "Trade History",
      type: "item",
      url: "/trade-view",
      icon: icons.DatabaseOutlined,
    },
    {
      id: "inventory",
      title: "Inventory",
      type: "item",
      url: "/inventory-view",
      icon: icons.DatabaseOutlined,
    },
    {
      id: "vendor",
      title: "Vendors",
      type: "item",
      url: "/vendor-view",
      icon: icons.UserOutlined,
    },
    {
      id: "customer",
      title: "Customers",
      type: "item",
      url: "/customer-view",
      icon: icons.UserOutlined,
    },
    {
      id: "consignee",
      title: "Consignees",
      type: "item",
      url: "/consignee-view",
      icon: icons.UserOutlined,
    },
    {
      id: "address",
      title: "Addresses",
      type: "item",
      url: "/stakeholder-address-view",
      icon: icons.UserOutlined,
    },

    /*{
      id: "shipping-instruction",
      title: "Shipping Instructions",
      type: "item",
      url: "/shipping-instruction-view",
      icon: icons.SendOutlined,
    },*/
  ],
};

export default utilities;
