import { apiClient } from './components/api/ApiClient.js';

apiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('accessToken'); 
    if (token) {
      config.headers['Authorization'] = token;
    }
    return config;
  },
);
