import { Button, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect } from "react";
import AddShippingInstructionView from "./AddShippingInstructionView";
import { createMultipleShippingInstructionsApi } from "../../../components/api/ShippingInstructionApiService";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

const AddShippingInstruction = (props) => {
  const {
    setAddViewStatesArray,
    addViewStatesArray,
    rows,
    setRows,
    setAddView,
    showSIPopup,
  } = props;

  const navigate = useNavigate();

  useEffect(() => {
    var today = new Date();
    const formattedToday = format(today, "yyyy-MM-dd");
    let dummyArr = addViewStatesArray;
    dummyArr[0] = {
      ...dummyArr[0],
      shippingInstructionDate: formattedToday,
      pointOfLoading: showSIPopup?.data?.portOfLoading || "",
      pointOfUnloading: showSIPopup?.data?.portOfDischarge || "",
      shipmentRequest: showSIPopup?.data || null,
      shipmentRequestId: showSIPopup?.data?.id || "",
      isSiNumberRetrieved: false,
      destination: showSIPopup?.data?.destination || "",
      description: showSIPopup?.data?.salesOrder?.descriptionOfGoods || "",
      selectedFreightOffer: null,
      status: "Open",
      show: true,
    };
    setAddViewStatesArray([...dummyArr]);
  }, []);

  const handleAddAnother = () => {
    addViewStatesArray.map((offer) => (offer.show = false));
    setAddViewStatesArray([
      ...addViewStatesArray,
      {
        shipmentRequestId: null,
        shipmentRequest: null,
        destination:  null,
        pointOfLoading: null,
        pointOfUnloading: null,
        isSiNumberRetrieved: true,
        selectedFreightOffer: addViewStatesArray[0]?.selectedFreightOffer || null,
        consigneeId: addViewStatesArray[0]?.consigneeId || null,
        notifyId: addViewStatesArray[0]?.notifyId || null,
        shippingInstructionType:
          addViewStatesArray[0]?.shippingInstructionType || null,
        shippingInstructionNumber:
          addViewStatesArray[0]?.shippingInstructionNumber || null,
        finalShippingInstructionNumber:
          addViewStatesArray[0]?.finalShippingInstructionNumber || null,
        shippingInstructionDate:
          addViewStatesArray[0]?.shippingInstructionDate || null,
        status: addViewStatesArray[0]?.status || null,
        dateOfLoadingRemark: addViewStatesArray[0]?.dateOfLoadingRemark || null,
        freightOfferId: addViewStatesArray[0]?.freightOfferId || null,
        transportationContact:
          addViewStatesArray[0]?.transportationContact || null,
        freightRemark: addViewStatesArray[0]?.freightRemark || null,
        cartonNumber: addViewStatesArray[0]?.cartonNumber || null,
        grossWeight: addViewStatesArray[0]?.grossWeight || null,
        shippedBy: addViewStatesArray[0]?.shippedBy || null,
        shipperId: addViewStatesArray[0]?.shipperId || null,
        toBeShippedBy20: null,
        toBeShippedBy40: null,
        toBeShippedBy40HC: null,
        toBeShippedByFtl: null,
        billOfLadingNumber: addViewStatesArray[0]?.billOfLadingNumber || null,
        billOfLadingDate: addViewStatesArray[0]?.billOfLadingDate || null,
        cmrNumber: addViewStatesArray[0]?.cmrNumber || null,
        cmrDate: addViewStatesArray[0]?.cmrDate || null,
        estimatedTimeOfDeparture:
          addViewStatesArray[0]?.estimatedTimeOfDeparture || null,
        estimatedTimeOfArrival:
          addViewStatesArray[0]?.estimatedTimeOfArrival || null,
        description: null,
        revision: addViewStatesArray[0]?.revision || null,
        remark: addViewStatesArray[0]?.remark || null,
        link: addViewStatesArray[0]?.link || null,
        recordStatus: addViewStatesArray[0]?.recordStatus || null,
        show: true,
      },
    ]);
  };
  return (
    <Stack spacing={1}>
      <Typography variant="h4" mb={1}>
        Create New Shipping Instruction
      </Typography>
      {addViewStatesArray.map((offer, index) => (
        <AddShippingInstructionView
          key={index}
          index={index}
          addShippingInstructionArray={addViewStatesArray}
          setAddShippingInstructionArray={setAddViewStatesArray}
          offer={offer}
          showSIPopup={showSIPopup}
          setAddView={setAddView}
          setRows={setRows}
          rows={rows}
        />
      ))}
      <Stack direction="column" spacing={1} mt={1}>
        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="text" onClick={() => handleAddAnother()}>
            + Add another
          </Button>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button variant="outlined" onClick={() => setAddView(false)}>
            Cancel
          </Button>
          <Button
          variant="contained"
          onClick={async () => {
            let hasPermission = true;
            const username = localStorage.getItem("username");

            addViewStatesArray.forEach((shippingInstruction) => {
              if (shippingInstruction?.shipmentRequest?.assignedUser?.email !== username) {
                toast.error(`You are not the assigned user for shipment request ${shippingInstruction?.shipmentRequestId}`);
                hasPermission = false;
              }
            });

                if (hasPermission) {
                  const newTab = window.open("", "_blank");
                  createMultipleShippingInstructionsApi(addViewStatesArray)
                    .then((response) => {
                      toast.success("Shipping Instruction added successfully.");
                      if (newTab) {
                        newTab.location.href = "/shipping-instruction-view";
                      }
                      setAddView(false);
                    })
                    .catch((error) => {
                      toast.error(error.response.data.message);
                      console.log(error.response.data);
                    });
                }
              }}
            >
            Add
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AddShippingInstruction;
