import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import toast from "react-hot-toast";
  import { useNavigate } from "react-router-dom";
  import { createStakeholderApi, retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
  import { recordStatus } from "../../../utils/menu-items";
  
  const AddConsignee = (props) => {
    const navigate = useNavigate();
    const { setAddViewStates, addViewStates, rows, setRows, setAddView } = props;
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    function retrieveCustomers() {
        retrieveAllStakeholdersApi("CUSTOMER")
          .then((response) => {
            setCustomers(response?.data);
          })
          .catch((error) => console.log(error.response?.data));
      }
    useEffect(
      () =>
        setAddViewStates({
          ...addViewStates,
          type: "CONSIGNEE",
        }),
      [],
    );
    return (
      <>
        <Stack direcion="column">
          <Typography variant="body1" mb={2}>
            Create New Consignee
          </Typography>
          <Grid container xs={12} spacing="8px">
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Consignee Code</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    code: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Consignee Name</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    name: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Contact Person</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    contactPerson: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Reference Customer</Typography>
              <Autocomplete
                options={customers}
                getOptionLabel={(option) =>
                  `${option?.id || ""} - ${option?.name || ""}`
                }
                value={selectedCustomer || customer}
                onChange={(event, newValue) => {
                  setSelectedCustomer(newValue);
                  setAddViewStates({
                    ...addViewStates,
                    reference: newValue ? newValue?.name : null,
                  })
                }}
                onOpen={retrieveCustomers}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
                renderTags={() => null}
              />
              {selectedCustomer && (
                <Box mt={2}>
                  <Chip
                    label={`${selectedCustomer?.id || ""} - ${
                      selectedCustomer?.name || ""
                    }`}
                    onDelete={() => setSelectedCustomer(null)}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Status</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                label={"ACTIVE"}
                id="outlined-select-status"
                select
                onChange={(e) =>
                  setAddViewStates({
                    ...addViewStates,
                    status: e.target.value,
                  })
                }
              >
                {recordStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1} mt={1}>
            <Button variant="outlined" onClick={() => setAddView(false)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                const newId = Math.floor(Math.random() * 100000);
                setAddViewStates({ ...addViewStates, id: newId });
                setRows([addViewStates, ...rows]);
                setAddView(false);
  
                createStakeholderApi(addViewStates)
                  .then((response) => {
                    toast.success("Consignee added successfully.");
                    window.location.reload();
                    navigate("/consignee-view");
                  })
                  .catch((error) => {
                    toast.error("Something went wrong.");
                    console.log(error.response.data);
                  });
              }}
            >
              Add
            </Button>
          </Stack>
        </Stack>
      </>
    );
  };
  
  export default AddConsignee;
  