import { Box, useMediaQuery } from "@mui/material";

import logo from "../../../../assets/images/logo/sanIntlLogoSvg.svg";
import MobileSection from "./MobileSection";
import Profile from "./Profile";
import Search from "./Search";
import {
  Typography,
} from "@mui/material";
const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
    {}
    <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
      <img src={logo} style={{ height: 45, paddingLeft: 20 }}></img>
    </div>

    {}
    <Typography
      variant="h1"
      noWrap
      component="a"
      sx={{
        flexGrow: 1,
        fontWeight: 700,
        letterSpacing: '.4rem',
        color: '#20315d',
        textDecoration: 'none',
        textAlign: 'center'
      }}
    >
      DATABOX
    </Typography>

    {}
    <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </div>
  </div>
  );
};

export default HeaderContent;
