import { Autocomplete, Grid, MenuItem, Stack, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { format, parseISO } from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import eurLogo from "../../../../assets/images/logo/sanEurLogoJpeg.jpeg";
import intlLogo from "../../../../assets/images/logo/sanIntlLogoJpeg.jpeg";
import { companies, titles } from "../../../../utils/menu-items";
import { retrieveFilteredAddressesApi } from "../../../../components/api/AddressApiService";

const convertImageToBase64 = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

const createExcelWithLogoAndData = async (offerDetails) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("My Sheet");
  const len = offerDetails?.items.length;

  const sellingCompany =
  offerDetails?.sellingCompany === "SGE"
    ? "SGE"
    : "SGI";

  const sellingCompanyHeader =
  offerDetails?.sellingCompany === "SGE"
    ? "SanGroup Europe Kft."
    : "SANGROUP INTERNATIONAL PTE.LTD.";
const sellingAddressOne =
offerDetails?.sellingCompany === "SGE"
    ? "Stefania ut 101-103"
    : "Head Office : 11 Irwing Place, # 09--02 Tai Seng Point Singapore 369551";
const sellingAddressTwo =
offerDetails?.sellingCompany === "SGE"
    ? "H-1143 Budapest, Hungary"
    : "Company Registration / Tax No : 201717395N ";
const sellingAddressThree =
offerDetails?.sellingCompany === "SGE"
    ? "Registration no: 01-09-400244 VAT no: Hu27842588"
    : "sgi.info@sangroupintl.com";

  var logo;
  // Convert and add the intlLogo
  if (offerDetails.sellingCompany === "SGI") {
    logo = intlLogo;
  } else if (offerDetails.sellingCompany === "SGE") {
    logo = eurLogo;
  } else {
    logo = intlLogo;
  }
  convertImageToBase64(logo, async (base64Image) => {
    const imageId = workbook.addImage({
      base64: base64Image,
      extension: "jpeg",
    });

    worksheet.addImage(imageId, "A1:E5");

    const startRow = 6;
    const headerRowNumber = startRow + 8; // Assuming header is the 7th row in dataForExcel

    // Styling for headers
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF4F81BD" }, // Change color as needed
    };

    // Data for the document (your structured data goes here)
    const dataForExcel = [
      [offerDetails?.companyName],
      [offerDetails?.companyAddressOne],
      [offerDetails?.companyAddressTwo],
      [],
      ["Dear " + offerDetails?.contactPerson +","],
      ["We are pleased to offer you the following tobaccos."],
      [],
      ["OFFER TO:  " + offerDetails?.companyName],
      [
        "Origin",
        "Crop Year",
        "Type",
        "Grade",
        "Leaf Form",
        "Packaging",
        "Volume KGS/monthly",
        "Nicotine %",
        "Sugar %",
        "Price " + offerDetails?.incoterm,
      ],
      ...offerDetails?.items.map((item) => [
        item?.origin,
        item?.crop,
        item?.type,
        item?.buyingGrade,
        item?.leafForm,
        item?.packaging,
        item?.volume,
        item?.nicotine,
        item?.sugar,
        item?.price,
      ]),
      [],
      ["Terms and Conditions:"],
      [],
      ["* Value Date: " + offerDetails?.valueDate],
      ["* Shipment: " + offerDetails?.shipment],
      ["* Payment term: " + offerDetails?.paymentTerm],
      ["* Finance Charge: " + offerDetails?.quality],
      ["* Storage Charge: " + offerDetails?.fumigation],
      ["* Packing: " + offerDetails?.packaging],
      ["* " + offerDetails?.termsOfDelivery],
      ["* " + offerDetails?.commentsOne],
      ["* " + offerDetails?.commentsTwo],
      [],
      [],
      [],
      [
        "Thank you for your continues support and cooperation with us. Look forward to hearing from you soon.",
      ],
      ["Birgul Sandal"],
      [],
      [],
      [],
      [],
      [],
      [],
      [
        "",
        "",
        sellingCompany ==='SGI' ? sellingAddressOne : "",
        sellingCompany ==='SGE' ? "":null,
        sellingCompany ==='SGE' ? ("      "+sellingAddressOne):null,
      ],
      ["", "", "",  sellingCompany ==='SGI' ? sellingAddressTwo : "",
      sellingCompany ==='SGE' ? sellingAddressTwo:null,],

      ["", "", "", sellingCompany ==='SGE' ? sellingAddressThree:"", sellingCompany ==='SGI' ? sellingAddressThree : null,
      ],
    ];

    // Example: Inserting a row of data
    dataForExcel.forEach((row, index) => {
      const rowIndex = startRow + index;
      const excelRow = worksheet.getRow(rowIndex);
      row.forEach((cell, cellIndex) => {
        const cellAddress = excelRow.getCell(cellIndex + 1);
        cellAddress.value = cell;

        if (rowIndex === headerRowNumber) {
          // Apply header styles
          cellAddress.fill = headerFill;
          cellAddress.font = { bold: true, color: { argb: "FFFFFFFF" } };
          cellAddress.alignment = {
            wrapText: "true",
            vertical: "middle",
            horizontal: "center",
          };
          cellAddress.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        } else if (rowIndex === 6) {
          cellAddress.font = { bold: true };
        } else if (rowIndex === 13) {
          cellAddress.font = { bold: true };
        } else if (rowIndex === 16 + len) {
          cellAddress.font = { bold: true, underline: true, size: "9" };
        } else if (rowIndex > 14 && rowIndex <= 14 + len) {
          cellAddress.border = {
            top: { style: "thin", color: "black" },
            left: { style: "thin", color: "black" },
            bottom: { style: "thin", color: "black" },
            right: { style: "thin", color: "black" },
          };
        } else if (rowIndex === 31 + len) {
          cellAddress.font = { size: "12" };
          cellAddress.alignment = { vertical: "middle" };
        } else if (
          rowIndex === 18 + len ||
          rowIndex === 19 + len ||
          rowIndex === 20 + len ||
          rowIndex === 21 + len ||
          rowIndex === 22 + len ||
          rowIndex === 23 + len ||
          rowIndex === 24 + len ||
          rowIndex === 25 + len ||
          rowIndex === 26 + len
        ) {
          cellAddress.font = { size: "9" };
        } else if (
          rowIndex === 38 + len ||
          rowIndex === 39 + len ||
          rowIndex === 40 + len
        ) {
          cellAddress.font = { color: { argb: "a0a3a2" } };
          cellAddress.alignment = { vertical: "middle" };
        }
      });
      excelRow.commit();
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "OfferWithLogo.xlsx");
  });
};


const CreateOfferPdf = (props) => {
  const { setPdfPopup, pdfPopup } = props;
  const[filteredAddresses, setFilteredAddresses] = useState([]);
  const[selectedAddress, setSelectedAddress] = useState(null);
  const [items, setItems] = useState(
    pdfPopup && pdfPopup.data
      ? pdfPopup.data.map((offer) => ({
          id: offer?.item?.id || "",
          origin: offer?.item?.origin || "",
          crop: offer?.item?.crop || "",
          type: offer?.item?.type || "",
          buyingGrade: offer?.item?.buyingGrade || "",
          leafForm: offer?.item?.leafForm || "",
          nicotine: offer?.item?.nicotine || "",
          sugar: offer?.item?.sugar || "",
          volume: offer?.volume || "",
          packaging: offer?.packaging
  ? offer.netWeightPerCase
    ? `${offer.packaging} / ${offer.netWeightPerCase}`
    : offer.packaging
  : offer.netWeightPerCase || "",
          price: offer?.finalOfferPrice || "",
          incoterm: offer?.incoterm || "",
        }))
      : [],
  );
  const [offerDetails, setOfferDetails] = useState({
    items: items,
    customerTitle: "",
    companyName: pdfPopup?.data[0]?.stakeholder?.name || "",
    contactPerson: pdfPopup?.data[0]?.stakeholder?.contactPerson || "",
    packaging: pdfPopup?.data[0]?.packaging || "",
    valueDate: pdfPopup?.data[0]?.validityDate ? new Date(pdfPopup?.data[0]?.validityDate).toLocaleDateString('en-GB') : "",
    paymentTerm: pdfPopup?.data[0]?.paymentTerm || "",
    remark: pdfPopup?.data[0]?.remark || "",
    incoterm: pdfPopup?.data[0]?.incoterm || "",
    sellingCompany: pdfPopup?.data[0]?.company || "",
    companyAddressOne:"",
    companyAddressTwo:"",
    commentsOne:"",
    commentsTwo:"",
    quality:"",
    fumigation:"",
    termsOfDelivery:"",
    shipment:""
});
const filterAddress = ({
  id: 0,
  stakeholderId: pdfPopup?.data[0]?.stakeholder?.id,
  stakeholderIdList: [pdfPopup?.data[0]?.stakeholder?.id],
  type: "",
  typeList: ["Pay To", "Bill To", "Ship To"],
  street: "",
  streetList: [],
  zipCode: "",
  zipCodeList: [],
  city: "",
  cityList: [],
  county: "",
  countyList: [],
  country: "",
  countryList: [],
  remark: "",
  recordStatusList: ["ACTIVE"],
});

function retrieveStakeholderAddresses(filterAddress) {
  retrieveFilteredAddressesApi(filterAddress)
    .then((response) => {
      setFilteredAddresses(response?.data);
    })
    .catch((error) => console.log(error.response?.data));
}

useEffect(() => {
  retrieveStakeholderAddresses(filterAddress)
}, []);

    return ( // Added return statement here
      <>
      <Stack direction="column">
        <Typography variant="body1" mb={2}>
          Selected Items
        </Typography>
        {pdfPopup &&
          pdfPopup.data &&
          pdfPopup.data.map((offer, index) => (
            <Grid container key={offer.id} spacing={2}>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2"> Item ID </Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={offer?.item?.id || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Origin</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={offer?.item?.origin || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Crop Year</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={offer?.item?.crop || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Type</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={offer?.item?.type || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Buying Grade</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={offer?.item?.buyingGrade || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Sales Grade</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={offer?.item?.salesGrade || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Leaf Form</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={offer?.item?.leafForm || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Nicotine</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={offer?.item?.nicotine || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Sugar</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={offer?.item?.sugar || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
            <Typography variant="body2">Packaging</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={offer?.packaging
                ? offer.netWeightPerCase
                  ? `${offer.packaging} / ${offer.netWeightPerCase}`
                  : offer.packaging
                : offer.netWeightPerCase || ""
              }
            />
          </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Volume KGS / Monthly</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={offer?.volume || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Price {offer?.incoterm}</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={offer?.finalOfferPrice || ""}
                />
              </Grid>
            </Grid>
          ))}
        <Typography variant="body1" mb={2} marginTop={5}>
          Selected Offer Details
        </Typography>
        <Grid container spacing={2} marginBottom={5}>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Selling Company</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-title"
              select
              value={offerDetails?.sellingCompany}
              onChange={(e) =>
                setOfferDetails({
                  ...offerDetails,
                  sellingCompany: e.target.value,
                })
              }
            >
              {companies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={10}></Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Buying Company Name</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={offerDetails?.companyName}
            />
          </Grid>
          <Grid item xs={12} sm={10}></Grid>
          <Grid item xs={12} sm={5.5}>
      <Typography variant="body2">Select Address Line 1</Typography>
      <Autocomplete
        options={filteredAddresses}
        getOptionLabel={(option) => {
          if (!option) return '';
          const type = option?.type || "";
          const street = option?.street || "";
          const city = option?.city || "";
      
          const addressPart1 = [street, city].filter(Boolean).join(", ");
          return `${type}: ${addressPart1}`;
        }}
        value={selectedAddress}
        onChange={(event, newValue) => {
          const newTerm = newValue ? newValue.value : "";
          setSelectedAddress(newValue);

    const street = newValue?.street || "";
    const city = newValue?.city || "";
    const county = newValue?.county || "";
    const zipCode = newValue?.zipCode || "";
    const country = newValue?.country || "";

    const companyAddressOne = [street, city].filter(Boolean).join(", ");
    const companyAddressTwo = [county, zipCode, country].filter(Boolean).join(", ");

    setOfferDetails((prevDetails) => ({
      ...prevDetails,
      companyAddressOne: companyAddressOne,
      companyAddressTwo: companyAddressTwo,
    }));
  }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
        renderTags={() => null}
      />
    </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
              Buying Company Address Line 2
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={(selectedAddress?.country||"") + "  " + (selectedAddress?.zipCode||"")}
            />
          </Grid>
          <Grid item xs={12} sm={1}></Grid>

          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Address Line One</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offerDetails?.companyAddressOne}
              onChange={(e) => {
                setOfferDetails({
                  ...offerDetails,
                  companyAddressOne: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
            {selectedAddress && (<Typography variant="body2" style={{ color: "red" }}>(Please use this field to make changes to the address as it will appear on the PDF. Note that your changes will not affect the actual address information.)</Typography>)}
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Address Line Two</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offerDetails?.companyAddressTwo}
              onChange={(e) => {
                setOfferDetails({
                  ...offerDetails,
                  companyAddressTwo: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
             {selectedAddress && (<Typography variant="body2" style={{ color: "red" }}>(Please use this field to make changes to the address as it will appear on the PDF. Note that your changes will not affect the actual address information.)</Typography>)}
          </Grid>

          <Grid item xs={12} sm={1}></Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Contact Person</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={pdfPopup?.data[0]?.stakeholder?.contactPerson}
            />
          </Grid>
          <Grid item xs={12} sm={10}></Grid>

          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Value Date</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={
                pdfPopup?.data[0]?.validityDate
                  ? format(
                      parseISO(pdfPopup?.data[0]?.validityDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Payment Term</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={pdfPopup?.data[0]?.paymentTerm}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={pdfPopup?.data[0]?.remark}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Shipment</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offerDetails?.shipment}
              onChange={(e) => {
                setOfferDetails({
                  ...offerDetails,
                  shipment: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Finance Charge</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offerDetails?.quality}
              onChange={(e) => {
                setOfferDetails({
                  ...offerDetails,
                  quality: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Storage Charge</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offerDetails?.fumigation}
              onChange={(e) => {
                setOfferDetails({
                  ...offerDetails,
                  fumigation: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Comment</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offerDetails?.termsOfDelivery}
              onChange={(e) => {
                setOfferDetails({
                  ...offerDetails,
                  termsOfDelivery: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Comment One</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offerDetails?.commentsOne}
              onChange={(e) => {
                setOfferDetails({
                  ...offerDetails,
                  commentsOne: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Comment Two</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={offerDetails?.commentsTwo}
              onChange={(e) => {
                setOfferDetails({
                  ...offerDetails,
                  commentsTwo: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setPdfPopup({
                data: null,
                visible: false,
              })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => createExcelWithLogoAndData(offerDetails)}
          >
            Create Offer File
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default CreateOfferPdf;
