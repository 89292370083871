import { apiClient } from "./ApiClient";

export const createItemApi = (item) =>
  apiClient.post(`/item-view/items/add`, item);

export const updateItemApi = (itemId, item) =>
  apiClient.put(`/item-view/items/edit/${itemId}`, item);

export const retrieveAllItemsApi = () => apiClient.get(`/item-view/items`);

export const retrieveFilteredItemsApi = (item) =>
  apiClient.post(`/item-view/items/filter`, item);

export const deleteItemsByIdApi = (idList) =>
  apiClient.delete(`/item-view/items/delete`, idList);

export const retrieveItemByIdApi = (itemId) => apiClient.get(`/item-view/items/get/${itemId}`);
