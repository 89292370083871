import { apiClient } from "./ApiClient";

export const createStakeholderApi = (stakeholder) =>
  apiClient.post(`/stakeholders/add`, stakeholder);

export const retrieveAllStakeholdersApi = (stakeholderType) =>
  apiClient.get(`/stakeholders/${stakeholderType}`);
  
export const retrieveStakeholderByIdApi = (stakeholderId) =>
apiClient.get(`/stakeholders/stakeholder/${stakeholderId}`);

export const updateStakeholderApi = (stakeholderId, stakeholder) =>
  apiClient.put(`/stakeholders/edit/${stakeholderId}`, stakeholder);

export const retrieveFilteredStakeholdersApi = (stakeholder) =>
  apiClient.post(`/stakeholders/filter`, stakeholder);
