import pages from "./pages";
import dashboard from "./dashboard";
import utilities from "./utilities";
import logistics from "./logistics";
import support from "./support";
import accordion from "./accordion";
import order from "./order";

const menuItems = {
  items: [dashboard, pages, accordion, order, logistics, utilities, support],
};

export default menuItems;
