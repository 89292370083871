import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, MenuItem, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { retrieveAllItemsApi } from "../../../components/api/ItemApiService";
import {
  retrieveAllSamplesApi,
  retrieveFilteredSamplesApi,
} from "../../../components/api/SampleApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import ExportExcel from "../../../utils/excelExport";
import { approved, status, type } from "../../../utils/menu-items";
import AddSample from "./AddSample";
import EditSample from "./EditSample";

const approvalDetails = [
  {
    value: "X",
    label: "X",
  },
  {
    value: "Y",
    label: "Y",
  },
  {
    value: "Z",
    label: "Z",
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "purpose",
    numeric: false,
    disablePadding: false,
    label: "Sample Purpose",
  },
  {
    id: "itemId",
    numeric: true,
    disablePadding: true,
    label: "Item ID",
  },
  {
    id: "itemType",
    numeric: false,
    disablePadding: false,
    label: "Item Type",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: false,
    label: "Image Url",
  },
  {
    id: "sender",
    numeric: false,
    disablePadding: false,
    label: "Sender",
  },

  {
    id: "receiver",
    numeric: false,
    disablePadding: false,
    label: "Receiver",
  },
  {
    id: "salesGrade",
    numeric: false,
    disablePadding: false,
    label: "Sales Grade",
  },
  {
    id: "salesNicotine",
    numeric: true,
    disablePadding: false,
    label: "Sales Nicotine",
  },

  {
    id: "salesSugar",
    numeric: true,
    disablePadding: false,
    label: "Sales Sugar",
  },
  {
    id: "salesOrigin",
    numeric: false,
    disablePadding: false,
    label: "Sales Origin",
  },
  {
    id: "salesCrop",
    numeric: false,
    disablePadding: false,
    label: "Sales Crop",
  },
  {
    id: "deliveryDate",
    numeric: false,
    disablePadding: false,
    label: "Date Of Delivery",
  },

  {
    id: "receiveDate",
    numeric: false,
    disablePadding: false,
    label: "Date Of Receive",
  },
  {
    id: "courier",
    numeric: false,
    disablePadding: false,
    label: "Courier",
  },

  {
    id: "awbNumber",
    numeric: false,
    disablePadding: false,
    label: "AWB Number",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: false,
    label: "Remark",
  },
  {
    id: "customerFeedback",
    numeric: false,
    disablePadding: false,
    label: "Customer Feedback",
  },
  {
    id: "approved",
    numeric: false,
    disablePadding: false,
    label: "Approved",
  },

  {
    id: "approvalDetails",
    numeric: false,
    disablePadding: false,
    label: "Approval Details",
  },
  {
    id: "paidBy",
    numeric: false,
    disablePadding: false,
    label: "Paid By",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, showFilters, setShowFilters, rows, setAddView, selected,
    selectedData } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="#1E2685"
        >
          Samples
        </Typography>

        <Typography></Typography>
          {numSelected > 0 ? (
          <Typography
          sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (<Typography></Typography>)}

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      </Stack>
      <Stack direction={"row"} spacing={1} sx={{ mr: 2 }}>
        <ExportExcel excelData={selectedData} fileName={"SampleExport"} buttonLabel="Export Table"/>

        <Button
          disableElevation
          variant="outlined"
          size="small"
          onClick={() => setAddView(true)}
        >
          Add
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px" }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function SampleView() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [addView, setAddView] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [rows, setRows] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [items, setItems] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);
  const [componentsState, setComponentsState] = useState({
    item: {
      dropdownOpen: false,
      inputValue: "",
    },
    sender: {
      dropdownOpen: false,
      inputValue: "",
    },
    receiver: {
      dropdownOpen: false,
      inputValue: "",
    },
  });

  const updateComponentState = (componentKey, newState) => {
    setComponentsState((prevState) => ({
      ...prevState,
      [componentKey]: {
        ...prevState[componentKey],
        ...newState,
      },
    }));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [filterSample, setFilterSample] = useState({
    id: 0,
    itemTypeList: [],
    itemIdList: [],
    senderIdList: [],
    receiverIdList: [],
    purposeList: [],
    salesGrade: "",
    salesOrigin: "",
    courier: "",
    awbNumber: "",
    remark: "",
    customerFeedback: "",
    approvedList: [],
    approvalDetailsList: [],
    paidBy: "",
    statusList: ["ACTIVE"],
  });
  useEffect(() => retrieveSamples(), []);

  function retrieveSamples() {
    retrieveAllSamplesApi()
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER")
    ])
      .then(([vendorResponse, customerResponse]) => {
        setStakeholders([
          ...vendorResponse?.data,
          ...customerResponse?.data
        ]);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [editSample, setEditSample] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }else{
      setSelected([]);
      setSelectedData([]);
    }
  };


  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editSample],
  );

  return (
    <div>
      {addView ? (
        <AddSample
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
        />
      ) : editSample.visible ? (
        <EditSample
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editSample={editSample}
          setEditSample={setEditSample}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >

                <Autocomplete
        multiple
        id="outlined-select-item"
        disableClearable
        open={componentsState.item.dropdownOpen}
        onOpen={() => updateComponentState("item", { dropdownOpen: true })}
        inputValue={componentsState.item.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("item", { inputValue: newInputValue });
          }
        }}
        options={[
          ...items.filter((item) =>
            filterSample.itemIdList.includes(item.id),
          ),
          ...items.filter(
            (item) => !filterSample.itemIdList.includes(item.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.buyingGrade || ""} - ${
            option?.crop || ""
          }`
        }
        value={items.filter((item) =>
          filterSample.itemIdList.includes(item.id),
        )}
        onChange={(event, newValue) => {
          setFilterSample({
            ...filterSample,
            itemIdList: newValue.map((item) => item.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.buyingGrade || ""} - ${
              option?.crop || ""
            }`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterSample?.itemIdList.length > 0
              ? `Items Selected (${filterSample?.itemIdList.length})`
              : "Item";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.item.inputValue}
              onChange={(e) =>
                updateComponentState("item", { inputValue: e.target.value })
              }
              onMouseDown={retrieveItems}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("item", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

                <TextField
                  label="Type"
                  type="search"
                  id="outlined-select-item-type"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterSample.itemTypeList || [],
                    onChange: (e) => {
                      setFilterSample({
                        ...filterSample,
                        itemTypeList: e.target.value,
                      });
                    },
                  }}
                >
                  {type.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Autocomplete
        multiple
        id="outlined-select-sender"
        disableClearable
        open={componentsState.sender.dropdownOpen}
        onOpen={() =>
          updateComponentState("sender", { dropdownOpen: true })
        }
        inputValue={componentsState.sender.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("sender", { inputValue: newInputValue });
          }
        }}
        options={[
          ...stakeholders.filter((option) =>
            filterSample.senderIdList.includes(option.id),
          ),
          ...stakeholders.filter(
            (option) =>
              !filterSample.senderIdList.includes(option.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option.id} - ${option.type} - ${option.name}`
        }
        value={stakeholders.filter((option) =>
          filterSample.senderIdList.includes(option.id),
        )}
        onChange={(event, newValue) => {
          setFilterSample({
            ...filterSample,
            senderIdList: newValue.map((option) => option.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.type || ""} - ${option?.name || ""}`}
          </li>
        )}
        renderInput={(params) => {

          const label =
            filterSample?.senderIdList.length > 0
              ? `Senders Selected (${filterSample?.senderIdList.length})`
              : "Sender";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.sender.inputValue}
              onChange={(e) =>
                updateComponentState("sender", {
                  inputValue: e.target.value,
                })
              }
              onMouseDown={retrieveStakeholders}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("sender", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

<Autocomplete
        multiple
        id="outlined-select-receiver"
        disableClearable
        open={componentsState.receiver.dropdownOpen}
        onOpen={() =>
          updateComponentState("receiver", { dropdownOpen: true })
        }
        inputValue={componentsState.receiver.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("receiver", { inputValue: newInputValue });
          }
        }}
        options={[
          ...stakeholders.filter((option) =>
            filterSample.receiverIdList.includes(option.id),
          ),
          ...stakeholders.filter(
            (option) =>
              !filterSample.receiverIdList.includes(option.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option.id} - ${option.type} - ${option.name}`
        }
        value={stakeholders.filter((option) =>
          filterSample.receiverIdList.includes(option.id),
        )}
        onChange={(event, newValue) => {
          setFilterSample({
            ...filterSample,
            receiverIdList: newValue.map((option) => option.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.type || ""} - ${option?.name || ""}`}
          </li>
        )}
        renderInput={(params) => {
          
          const label =
            filterSample?.receiverIdList.length > 0
              ? `Receivers Selected (${filterSample?.receiverIdList.length})`
              : "Receiver";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.receiver.inputValue}
              onChange={(e) =>
                updateComponentState("receiver", {
                  inputValue: e.target.value,
                })
              }
              onMouseDown={retrieveStakeholders}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("receiver", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

                <TextField
                  id="outlined-search"
                  label="Sales Grade"
                  value={filterSample?.salesGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterSample({
                      ...filterSample,
                      salesGrade: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Sales Nicotine"
                  value={filterSample?.salesNicotine || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterSample({
                      ...filterSample,
                      salesNicotine: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Sales Sugar"
                  value={filterSample?.salesSugar || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterSample({
                      ...filterSample,
                      salesSugar: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-search"
                  label="Sales Origin"
                  value={filterSample?.salesOrigin || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterSample({
                      ...filterSample,
                      salesOrigin: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Sales Crop Year"
                  value={filterSample?.salesCrop || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterSample({
                      ...filterSample,
                      salesCrop: e.target.value,
                    })
                  }
                />
                <TextField
                  id="date"
                  type="date"
                  label="Date Of Delivery"
                  value={filterSample?.deliveryDate || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterSample({
                      ...filterSample,
                      deliveryDate: e.target.value,
                    })
                  }
                />
                <TextField
                  id="date"
                  type="date"
                  label="Date Of Receive"
                  value={filterSample?.receiveDate || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterSample({
                      ...filterSample,
                      receiveDate: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-search"
                  label="Courier"
                  value={filterSample?.courier || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterSample({
                      ...filterSample,
                      courier: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-search"
                  label="AWB Number"
                  value={filterSample?.awbNumber || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterSample({
                      ...filterSample,
                      awbNumber: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-search"
                  label="Remark"
                  value={filterSample?.remark || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterSample({
                      ...filterSample,
                      remark: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Customer Feedback"
                  value={filterSample?.customerFeedback || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterSample({
                      ...filterSample,
                      customerFeedback: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Approved"
                  type="number"
                  id="outlined-select-approved"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterSample.approvedList || [],
                    onChange: (e) => {
                      setFilterSample({
                        ...filterSample,
                        approvedList: e.target.value,
                      });
                    },
                  }}
                >
                  {approved.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Approval Details"
                  type="search"
                  id="outlined-select-approvalDetails"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterSample.approvalDetailsList || [],
                    onChange: (e) => {
                      setFilterSample({
                        ...filterSample,
                        approvalDetailsList: e.target.value,
                      });
                    },
                  }}
                >
                  {approvalDetails.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-search"
                  label="Paid By"
                  value={filterSample?.paidBy || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterSample({
                      ...filterSample,
                      paidBy: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Status"
                  type="search"
                  id="outlined-select-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterSample.statusList || [],
                    onChange: (e) => {
                      setFilterSample({
                        ...filterSample,
                        statusList: e.target.value,
                      });
                    },
                  }}
                >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      retrieveFilteredSamplesApi(filterSample)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      setFilterSample({
                        id: 0,
                        itemTypeList: [],
                        itemIdList: [],
                        senderIdList: [],
                        receiverIdList: [],
                        purposeList: [],
                        salesGrade: "",
                        salesNicotine: null,
                        salesSugar: null,
                        salesCrop: null,
                        salesOrigin: "",
                        deliveryDate: "",
                        receiveDate: "",
                        courier: "",
                        awbNumber: "",
                        remark: "",
                        customerFeedback: "",
                        approvedList: [],
                        approvalDetailsList: [],
                        paidBy: "",
                        statusList: ["ACTIVE"],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ width: "100%", mt: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                rows={rows}
                setAddView={setAddView}
                selected={selected}
                selectedData={selectedData}
              />
              <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    minWidth: 750,
                    '& .MuiTableCell-head': {
                      backgroundColor: '#20315d',
                      color: 'white',
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  style={{ width: "max-content" }}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#f0f0f0" }
                              : { background: "white" }
                          }
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              onClick={(e) => {
                                setEditSample({
                                  visible: true,
                                  data: row,
                                  index: index,
                                });
                                e.preventDefault();
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell align="center">{row?.id}</TableCell>
                          <TableCell align="center">{row?.purpose}</TableCell>
                          <TableCell align="center">{row?.item?.id}</TableCell>
                          <TableCell align="center">{row?.item?.type}</TableCell>
                          <TableCell align="center">
                            <a
                              href={row?.image}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {row?.image &&         
                              <Button
                                     disableElevation
                                     variant="outlined"
                                   >
                                  Sample Image
                                   </Button>}
                             </a>
                          </TableCell>
                          <TableCell align="center">
                            {row?.sender?.name}
                          </TableCell>
                          <TableCell align="center">
                            {row?.receiver?.name}
                          </TableCell>
                          <TableCell align="center">{row?.salesGrade}</TableCell>
                          <TableCell align="center">
                            {row?.salesNicotine}
                          </TableCell>
                          <TableCell align="center">{row?.salesSugar}</TableCell>
                          <TableCell align="center">
                            {row?.salesOrigin}
                          </TableCell>
                          <TableCell align="center">{row?.salesCrop}</TableCell>
                          <TableCell align="center">
                            {row?.deliveryDate
                              ? format(new Date(row?.deliveryDate), "dd/MM/yyyy")
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.receiveDate
                              ? format(new Date(row?.receiveDate), "dd/MM/yyyy")
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row?.courier}</TableCell>
                          <TableCell align="center">{row?.awbNumber}</TableCell>
                          <TableCell align="center">{row?.remark}</TableCell>
                          <TableCell align="center">
                            {row?.customerFeedback}
                          </TableCell>
                          <TableCell align="center">{row?.approved}</TableCell>
                          <TableCell align="center">
                            {row?.approvalDetails}
                          </TableCell>
                          <TableCell align="center">{row?.paidBy}</TableCell>
                          <TableCell align="center">{row?.timestamp ? format(new Date(row.timestamp), 'dd/MM/yyyy HH:mm'): ''}</TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">{row?.status}</TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
        </>
      )}
    </div>
  );
}
