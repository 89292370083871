import { lazy } from "react";

import Loadable from "../components/Loadable";
import MinimalLayout from "../layout/MinimalLayout";

const AuthLogin = Loadable(lazy(() => import("../pages/authentication/Login")));
const AuthRegister = Loadable(
  lazy(() => import("../pages/authentication/Register")),
);
const AuthChangePassword = Loadable(
  lazy(() => import("../pages/authentication/ChangePassword")),
);
//
const LoginRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/",
      element: <AuthLogin />,
    },
    {
      path: "authenticate",
      element: <AuthLogin />,
    },
    {
      path: "register",
      element: <AuthRegister />,
    },
    {
      path: "change-password",
      element: <AuthChangePassword />,
    },
  ],
};

export default LoginRoutes;
