import { Grid, MenuItem, TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useState } from "react";
import {
  color,
  companies,
  currencies,
  incoterms,
  leafForm,
  offerStatus,
  origin,
  paymentTerm,
  portsOfLoading,
  portsOfDischarge,
  recordStatus,
  sampleStatus,
  status,
  type,
  units,
} from "../../../utils/menu-items";

const VendorOfferSearch = (props) => {
  const {
    setFilterVendorOffer,
    filterVendorOffer,
    retrieveItems,
    items,
    retrieveVendors,
    vendors,
    retrieveSamples,
    samples,
  } = props;

  const [componentsState, setComponentsState] = useState({
    origin: {
      dropdownOpen: false,
      inputValue: "",
    },
    item: {
      dropdownOpen: false,
      inputValue: "",
    },
    stakeholder: {
      dropdownOpen: false,
      inputValue: "",
    },
    type: {
      dropdownOpen: false,
      inputValue: "",
    },
    leafForm: {
      dropdownOpen: false,
      inputValue: "",
    },
    storage: {
      dropdownOpen: false,
      inputValue: "",
    },
    cost: {
      dropdownOpen: false,
      inputValue: "",
    },
    firstSample: {
      dropdownOpen: false,
      inputValue: "",
    },
    secondSample: {
      dropdownOpen: false,
      inputValue: "",
    },
    paymentTerm: {
      dropdownOpen: false,
      inputValue: "",
    },
    portOfLoading: {
      dropdownOpen: false,
      inputValue: "",
    },
    portOfDischarge: {
      dropdownOpen: false,
      inputValue: "",
    },
  });

  // Function to update state for a specific component
  const updateComponentState = (componentKey, newState) => {
    setComponentsState((prevState) => ({
      ...prevState,
      [componentKey]: {
        ...prevState[componentKey],
        ...newState,
      },
    }));
  };

  return (
    <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
      <TextField
        id="outlined-search"
        label="Offer Year"
        value={filterVendorOffer?.offerYear || ""}
        type="number"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            offerYear: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-search"
        label="Offer Number"
        value={filterVendorOffer?.offerGroupNumber || ""}
        type="number"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            offerGroupNumber: e.target.value,
          })
        }
      />

      <Autocomplete
        multiple
        id="outlined-select-item"
        disableClearable
        open={componentsState.item.dropdownOpen}
        onOpen={() => updateComponentState("item", { dropdownOpen: true })}
        inputValue={componentsState.item.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("item", { inputValue: newInputValue });
          }
        }}
        options={[
          ...items.filter((item) =>
            filterVendorOffer.itemIdList.includes(item.id),
          ),
          ...items.filter(
            (item) => !filterVendorOffer.itemIdList.includes(item.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.buyingGrade || ""} - ${
            option?.crop || ""
          }`
        }
        value={items.filter((item) =>
          filterVendorOffer.itemIdList.includes(item.id),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            itemIdList: newValue.map((item) => item.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.buyingGrade || ""} - ${
              option?.crop || ""
            }`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.itemIdList.length > 0
              ? `Items Selected (${filterVendorOffer?.itemIdList.length})`
              : "Item";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.item.inputValue}
              onChange={(e) =>
                updateComponentState("item", { inputValue: e.target.value })
              }
              onMouseDown={retrieveItems}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("item", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        id="outlined-search"
        label="Tobacco Code"
        type="search"
        value={filterVendorOffer?.tobaccoCode || ""}
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            tobaccoCode: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Sales Grade"
        value={filterVendorOffer?.salesGrade || ""}
        type="search"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            salesGrade: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Buying Grade"
        value={filterVendorOffer?.buyingGrade || ""}
        type="search"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            buyingGrade: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Customer Grade"
        value={filterVendorOffer?.customerGrade || ""}
        type="search"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            customerGrade: e.target.value,
          })
        }
      />

      <Autocomplete
        multiple
        id="outlined-select-origin"
        disableClearable
        open={componentsState.origin.dropdownOpen}
        onOpen={() => updateComponentState("origin", { dropdownOpen: true })}
        inputValue={componentsState.origin.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("origin", { inputValue: newInputValue });
          }
        }}
        options={[
          ...origin.filter((option) =>
            filterVendorOffer.originList.includes(option.value),
          ),
          ...origin.filter(
            (option) => !filterVendorOffer.originList.includes(option.value),
          ),
        ]}
        getOptionLabel={(option) => option.label}
        value={origin.filter((option) =>
          filterVendorOffer.originList.includes(option.value),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            originList: newValue.map((option) => option.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.originList.length > 0
              ? `Origins Selected (${filterVendorOffer?.originList.length})`
              : "Origin";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.origin.inputValue}
              onChange={(e) =>
                updateComponentState("origin", { inputValue: e.target.value })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("origin", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <Autocomplete
        multiple
        id="outlined-select-type"
        disableClearable
        open={componentsState.type.dropdownOpen}
        onOpen={() => updateComponentState("type", { dropdownOpen: true })}
        inputValue={componentsState.type.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("type", { inputValue: newInputValue });
          }
        }}
        options={[
          ...type.filter((option) =>
            filterVendorOffer.typeList.includes(option.value),
          ),
          ...type.filter(
            (option) => !filterVendorOffer.typeList.includes(option.value),
          ),
        ]}
        getOptionLabel={(option) => option.label}
        value={type.filter((option) =>
          filterVendorOffer.typeList.includes(option.value),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            typeList: newValue.map((option) => option.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.typeList.length > 0
              ? `Types Selected (${filterVendorOffer?.typeList.length})`
              : "Type";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.type.inputValue}
              onChange={(e) =>
                updateComponentState("type", { inputValue: e.target.value })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("type", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <Autocomplete
        multiple
        id="outlined-select-leaf-form"
        disableClearable
        open={componentsState.leafForm.dropdownOpen}
        onOpen={() => updateComponentState("leafForm", { dropdownOpen: true })}
        inputValue={componentsState.leafForm.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("leafForm", { inputValue: newInputValue });
          }
        }}
        options={[
          ...leafForm.filter((option) =>
            filterVendorOffer.leafFormList.includes(option.value),
          ),
          ...leafForm.filter(
            (option) => !filterVendorOffer.leafFormList.includes(option.value),
          ),
        ]}
        getOptionLabel={(option) => option.label}
        value={leafForm.filter((option) =>
          filterVendorOffer.leafFormList.includes(option.value),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            leafFormList: newValue.map((option) => option.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.leafFormList.length > 0
              ? `Leaf Forms Selected (${filterVendorOffer?.leafFormList.length})`
              : "Leaf Form";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.leafForm.inputValue}
              onChange={(e) =>
                updateComponentState("leafForm", { inputValue: e.target.value })
              }
              onBlur={() =>
                updateComponentState("leafForm", { dropdownOpen: false })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Color"
        type="search"
        id="outlined-select-color"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.colorList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              colorList: e.target.value,
            });
          },
        }}
      >
        {color.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="outlined-search"
        label="Crop Year"
        value={filterVendorOffer?.crop || ""}
        type="search"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            crop: e.target.value,
          })
        }
      />

      <TextField
        label="Current Location"
        type="search"
        value={filterVendorOffer?.itemStorage || ""}
        id="outlined-storage"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            itemStorage: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-number"
        label="Nicotine"
        value={filterVendorOffer?.nicotine || ""}
        type="number"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            nicotine: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-number"
        label="Sugar"
        value={filterVendorOffer?.sugar || ""}
        type="number"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            sugar: e.target.value,
          })
        }
      />

      <TextField
        label="Buying Company"
        type="search"
        id="outlined-select-company"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.companyList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              companyList: e.target.value,
            });
          },
        }}
      >
        {companies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="SG Approval Status"
        type="search"
        id="outlined-select-approval-status"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.approvalStatusList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              approvalStatusList: e.target.value,
            });
          },
        }}
      >
        {status.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Status"
        type="search"
        id="outlined-select-status"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.statusList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              statusList: e.target.value,
            });
          },
        }}
      >
        {offerStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id="date"
        type="date"
        label="Status Deadline"
        value={filterVendorOffer?.statusDeadline || ""}
        inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            statusDeadline: e.target.value,
          })
        }
      />

      <Autocomplete
        multiple
        id="outlined-select-vendor"
        disableClearable
        open={componentsState.stakeholder.dropdownOpen}
        onOpen={() =>
          updateComponentState("stakeholder", { dropdownOpen: true })
        }
        inputValue={componentsState.stakeholder.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("stakeholder", { inputValue: newInputValue });
          }
        }}
        options={[
          ...vendors.filter((option) =>
            filterVendorOffer.stakeholderIdList.includes(option.id),
          ),
          ...vendors.filter(
            (option) =>
              !filterVendorOffer.stakeholderIdList.includes(option.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.name || ""}`
        }
        value={vendors.filter((option) =>
          filterVendorOffer.stakeholderIdList.includes(option.id),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            stakeholderIdList: newValue.map((option) => option.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.name || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.stakeholderIdList.length > 0
              ? `Vendors Selected (${filterVendorOffer?.stakeholderIdList.length})`
              : "Vendor";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.stakeholder.inputValue}
              onChange={(e) =>
                updateComponentState("stakeholder", {
                  inputValue: e.target.value,
                })
              }
              onMouseDown={retrieveVendors}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("stakeholder", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        id="outlined-number"
        label="First Offer"
        value={filterVendorOffer?.firstOfferPrice || ""}
        type="number"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            firstOfferPrice: e.target.value,
          })
        }
      />

      <TextField
        label="First Offer Price Currency"
        type="search"
        id="outlined-select-first-offer-currency"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.firstPriceCurrencyList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              firstPriceCurrencyList: e.target.value,
            });
          },
        }}
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id="outlined-number"
        label="Final Offer"
        value={filterVendorOffer?.finalOfferPrice || ""}
        type="number"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            finalOfferPrice: e.target.value,
          })
        }
      />

      <TextField
        label="Final Offer Price Currency"
        type="search"
        id="outlined-select-final-offer-currency"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.finalPriceCurrencyList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              finalPriceCurrencyList: e.target.value,
            });
          },
        }}
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        id="outlined-select-first-sample"
        disableClearable
        open={componentsState.firstSample.dropdownOpen}
        onOpen={() =>
          updateComponentState("firstSample", { dropdownOpen: true })
        }
        inputValue={componentsState.firstSample.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("firstSample", { inputValue: newInputValue });
          }
        }}
        options={[
          ...samples.filter((sample) =>
            filterVendorOffer.firstSampleIdList.includes(sample.id),
          ),
          ...samples.filter(
            (sample) =>
              !filterVendorOffer.firstSampleIdList.includes(sample.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.remark || ""}`
        }
        value={samples.filter((sample) =>
          filterVendorOffer.firstSampleIdList.includes(sample.id),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            firstSampleIdList: newValue.map((sample) => sample.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.remark || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.firstSampleIdList.length > 0
              ? `First Samples Selected (${filterVendorOffer?.firstSampleIdList.length})`
              : "First Sample";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.firstSample.inputValue}
              onChange={(e) =>
                updateComponentState("firstSample", {
                  inputValue: e.target.value,
                })
              }
              onMouseDown={retrieveSamples}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("firstSample", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="First Sample Status"
        type="search"
        id="outlined-select-first-sample-status"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.firstSampleStatusList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              firstSampleStatusList: e.target.value,
            });
          },
        }}
      >
        {sampleStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        id="outlined-select-second-sample"
        disableClearable
        open={componentsState.secondSample.dropdownOpen}
        onOpen={() =>
          updateComponentState("secondSample", { dropdownOpen: true })
        }
        inputValue={componentsState.secondSample.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("secondSample", { inputValue: newInputValue });
          }
        }}
        options={[
          ...samples.filter((sample) =>
            filterVendorOffer.secondSampleIdList.includes(sample.id),
          ),
          ...samples.filter(
            (sample) =>
              !filterVendorOffer.secondSampleIdList.includes(sample.id),
          ),
        ]}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.remark || ""}`
        }
        value={samples.filter((sample) =>
          filterVendorOffer.secondSampleIdList.includes(sample.id),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            secondSampleIdList: newValue.map((sample) => sample.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.remark || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.secondSampleIdList.length > 0
              ? `Second Samples Selected (${filterVendorOffer?.secondSampleIdList.length})`
              : "Second Sample";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.secondSample.inputValue}
              onChange={(e) =>
                updateComponentState("secondSample", {
                  inputValue: e.target.value,
                })
              }
              onMouseDown={retrieveSamples}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("secondSample", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Second Sample Status"
        type="search"
        id="outlined-select-second-sample-status"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.secondSampleStatusList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              secondSampleStatusList: e.target.value,
            });
          },
        }}
      >
        {sampleStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        id="outlined-select-payment-term"
        disableClearable
        open={componentsState.paymentTerm.dropdownOpen}
        onOpen={() =>
          updateComponentState("paymentTerm", { dropdownOpen: true })
        }
        inputValue={componentsState.paymentTerm.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("paymentTerm", { inputValue: newInputValue });
          }
        }}
        options={paymentTerm}
        getOptionLabel={(option) => option.label}
        value={paymentTerm.filter((term) =>
          filterVendorOffer.paymentTermList.includes(term.value),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            paymentTermList: newValue.map((term) => term.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.paymentTermList.length > 0
              ? `Payment Terms Selected (${filterVendorOffer?.paymentTermList.length})`
              : "Payment Term";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.paymentTerm.inputValue}
              onChange={(e) =>
                updateComponentState("paymentTerm", {
                  inputValue: e.target.value,
                })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("paymentTerm", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Incoterm"
        type="search"
        id="outlined-select-incoterm"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.incotermList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              incotermList: e.target.value,
            });
          },
        }}
      >
        {incoterms.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="outlined-number"
        label="Number Of Cases"
        value={filterVendorOffer?.numberOfCases || ""}
        type="number"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            numberOfCases: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-number"
        label="Net Weight Per Case"
        value={filterVendorOffer?.netWeightPerCase || ""}
        type="number"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            netWeightPerCase: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-number"
        label="Volume"
        value={filterVendorOffer?.volume || ""}
        type="number"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            volume: e.target.value,
          })
        }
      />
      <TextField
        label="Volume Unit"
        type="search"
        id="outlined-select-volume-unit"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.volumeUnitList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              volumeUnitList: e.target.value,
            });
          },
        }}
      >
        {units.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id="outlined-number"
        label="Total Amount"
        value={filterVendorOffer?.totalAmount || ""}
        type="number"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            totalAmount: e.target.value,
          })
        }
      />
      <TextField
        id="date"
        type="date"
        label="Offer Date"
        value={filterVendorOffer?.offerDate || ""}
        inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            offerDate: e.target.value,
          })
        }
      />
      <TextField
        id="date"
        type="date"
        label="Validity Date"
        value={filterVendorOffer?.validityDate || ""}
        inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            validityDate: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-search"
        label="Remark"
        value={filterVendorOffer?.remark || ""}
        type="search"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            remark: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Comment"
        value={filterVendorOffer?.comment || ""}
        type="search"
        onChange={(e) =>
          setFilterVendorOffer({
            ...filterVendorOffer,
            comment: e.target.value,
          })
        }
      />

      <Autocomplete
        multiple
        id="outlined-select-port-of-loading"
        disableClearable
        open={componentsState.portOfLoading.dropdownOpen}
        onOpen={() =>
          updateComponentState("portOfLoading", { dropdownOpen: true })
        }
        inputValue={componentsState.portOfLoading.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("portOfLoading", {
              inputValue: newInputValue,
            });
          }
        }}
        options={portsOfLoading}
        getOptionLabel={(option) =>
          option ? `${option.value} - ${option.label}` : ""
        }
        value={portsOfLoading.filter((term) =>
          filterVendorOffer.portOfLoadingList.includes(term.value),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            portOfLoadingList: newValue.map((term) => term.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option ? `${option.value} - ${option.label}` : ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.portOfLoadingList.length > 0
              ? `Ports Of Loading Selected (${filterVendorOffer?.portOfLoadingList.length})`
              : "Port Of Loading";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.portOfLoading.inputValue}
              onChange={(e) =>
                updateComponentState("portOfLoading", {
                  inputValue: e.target.value,
                })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("portOfLoading", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

<Autocomplete
        multiple
        id="outlined-select-port-of-discharge"
        disableClearable
        open={componentsState.portOfDischarge.dropdownOpen}
        onOpen={() =>
          updateComponentState("portOfDischarge", { dropdownOpen: true })
        }
        inputValue={componentsState.portOfDischarge.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("portOfDischarge", {
              inputValue: newInputValue,
            });
          }
        }}
        options={portsOfDischarge}
        getOptionLabel={(option) =>
          option ? `${option.value} - ${option.label}` : ""
        }
        value={portsOfDischarge.filter((term) =>
          filterVendorOffer.portOfDischargeList.includes(term.value),
        )}
        onChange={(event, newValue) => {
          setFilterVendorOffer({
            ...filterVendorOffer,
            portOfDischargeList: newValue.map((term) => term.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option ? `${option.value} - ${option.label}` : ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterVendorOffer?.portOfDischargeList.length > 0
              ? `Ports Of Discharge Selected (${filterVendorOffer?.portOfDischargeList.length})`
              : "Port Of Discharge";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.portOfDischarge.inputValue}
              onChange={(e) =>
                updateComponentState("portOfDischarge", {
                  inputValue: e.target.value,
                })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("portOfDischarge", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Document Status"
        type="search"
        id="outlined-select-document-status"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.documentStatusList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              documentStatusList: e.target.value,
            });
          },
        }}
      >
        {status.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Record Status"
        type="search"
        id="outlined-select-record-status"
        select
        SelectProps={{
          multiple: true,
          value: filterVendorOffer.recordStatusList || [],
          onChange: (e) => {
            setFilterVendorOffer({
              ...filterVendorOffer,
              recordStatusList: e.target.value,
            });
          },
        }}
      >
        {recordStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default VendorOfferSearch;
