import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  LoadingOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  AppstoreAddOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
};

const accordion = {
  id: "offer-accordion",
  title: "Offer Menu",
  type: "accordion",
  children: [
   /* {
      id: "offer",
      title: "Offers",
      type: "item",
      url: "/offer-view",
      icon: icons.FileTextOutlined,
    },*/
    {
      id: "vendor-offer",
      title: "Vendor Offers",
      type: "item",
      url: "/vendor-offer-view",
      icon: icons.FileTextOutlined,
    },
    {
      id: "customer-offer",
      title: "Customer Offers",
      type: "item",
      url: "/customer-offer-view",
      icon: icons.FileTextOutlined,
    },
    {
      id: "freight-offer",
      title: "Freight Offers",
      type: "item",
      url: "/freight-offer-view",
      icon: icons.FileTextOutlined,
    },
  ],
};

export default accordion;
