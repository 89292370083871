import { useTheme } from "@mui/material/styles";
import { Toaster as HotToaster } from "react-hot-toast";

export const Toaster = () => {
  const theme = useTheme();

  return (
    <HotToaster
      position="bottom-right"
      containerClassName="react-hot-toast"
      toastOptions={{
        position: "top-center",
        style: {
          backdropFilter: "blur(6px)",
          boxShadow: theme.shadows[16],
        },
        success: {
          style: {
            background: "#ebfaf1",
            border: "1px solid #33c871",
            fontSize: 12,
            color: "#33c871",
            fontWeight: 500,
            padding: "12px 64px",
          },
        },
      }}
    />
  );
};
