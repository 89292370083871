import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  retrieveAllItemsApi,
  retrieveItemByIdApi,
} from "../../../components/api/ItemApiService";
import {
  retrieveAllOffersApi,
  retrieveOfferByIdApi,
} from "../../../components/api/OfferApiService";
import {
  approveOrderApi,
  cancelOrderApi,
  deleteOrdersApi,
  retrieveAllOrdersApi,
  retrieveFilteredOrdersApi,
  retrieveMaxOrderNumberApi,
  retrieveSelectedOrdersByIdApi,
} from "../../../components/api/OrderApiService";
import {
  retrieveAllSamplesApi,
  retrieveSampleByIdApi,
} from "../../../components/api/SampleApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import ExportExcel from "../../../utils/excelExport";
import {
  color,
  companies,
  currencies,
  incoterms,
  leafForm,
  origin,
  packagingTypes,
  paymentTerm,
  portsOfDischarge,
  portsOfLoading,
  recordStatus,
  sampleStatus,
  type,
  units,
} from "../../../utils/menu-items";
import EditCost from "../CostView/EditCost";
import EditItem from "../ItemView/EditItem";
import EditSample from "../SampleView/EditSample";
import EditVendorOffer from "../VendorOfferView/EditVendorOffer";
import AddVendorOrder from "./AddVendorOrder";
import CreateOrder from "./CreateOrder";
import CreatePurchaseOrderShipment from "./CreatePurchaseOrderShipment";
import ExportPurchaseOrder from "./Dialogs/ExportPurchaseOrder";
import EditVendorOrder from "./EditVendorOrder";
import { useNavigate } from "react-router";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "approveButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "createShipmentButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "createOrderButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "cancelOrderButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "purchaseOrderNumber",
    numeric: false,
    disablePadding: false,
    label: "SAP PO Number",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: true,
    label: "Buying Company",
  },
  {
    id: "itemId",
    numeric: true,
    disablePadding: true,
    label: "Item ID",
  },
  {
    id: "itemBuyingGrade",
    numeric: false,
    disablePadding: true,
    label: "Buying Grade",
  },
  {
    id: "itemSgiGrade",
    numeric: false,
    disablePadding: true,
    label: "Sales Grade",
  },
  {
    id: "itemCrop",
    numeric: true,
    disablePadding: true,
    label: "Crop",
  },
  {
    id: "itemNicotine",
    numeric: true,
    disablePadding: true,
    label: "Nicotine",
  },
  {
    id: "itemSugar",
    numeric: true,
    disablePadding: true,
    label: "Sugar",
  },
  {
    id: "itemMoisture",
    numeric: true,
    disablePadding: true,
    label: "Moisture",
  },
  {
    id: "itemOrigin",
    numeric: false,
    disablePadding: true,
    label: "Origin",
  },
  {
    id: "itemStorage",
    numeric: false,
    disablePadding: true,
    label: "Current Location",
  },
  {
    id: "itemType",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "warehouse",
    numeric: false,
    disablePadding: true,
    label: "Warehouse",
  },
  {
    id: "vendorCode",
    numeric: true,
    disablePadding: true,
    label: "SAP Vendor Code",
  },
  {
    id: "vendorName",
    numeric: false,
    disablePadding: true,
    label: "Vendor Name",
  },
  {
    id: "firstSampleId",
    numeric: true,
    disablePadding: true,
    label: "Redrawn Sample",
  },
  {
    id: "firstSampleDate",
    numeric: true,
    disablePadding: true,
    label: "Redrawn Sample Date",
  },
  {
    id: "firstSampleStatus",
    numeric: false,
    disablePadding: true,
    label: "Redrawn Sample Status",
  },
  {
    id: "typeOfPackage",
    numeric: true,
    disablePadding: true,
    label: "Packaging Type",
  },
  {
    id: "netWeightPerCase",
    numeric: true,
    disablePadding: true,
    label: "Net Weight Per Case",
  },
  {
    id: "numberOfCase",
    numeric: true,
    disablePadding: true,
    label: "Number Of Cases",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: true,
    label: "Quantity (KG)",
  },
  {
    id: "firstPrice",
    numeric: true,
    disablePadding: true,
    label: "First Price",
  },
  {
    id: "firstPriceUsd",
    numeric: true,
    disablePadding: true,
    label: "First Price (USD)",
  },
  {
    id: "finalPrice",
    numeric: true,
    disablePadding: false,
    label: "Final Price",
  },
  {
    id: "finalPriceUsd",
    numeric: true,
    disablePadding: false,
    label: "Final Price (USD)",
  },
  {
    id: "totalAmount",
    numeric: true,
    disablePadding: true,
    label: "Total Amount",
  },
  /* {
    id: "costId",
    numeric: true,
    disablePadding: true,
    label: "Cost Id",
  },
  {
    id: "totalCost",
    numeric: true,
    disablePadding: true,
    label: "Total Cost",
  },*/
  {
    id: "contractValueDate",
    numeric: false,
    disablePadding: true,
    label: "Contract Value Date",
  },
  {
    id: "incoterm",
    numeric: true,
    disablePadding: true,
    label: "Incoterm",
  },
  {
    id: "portOfDischarge",
    numeric: true,
    disablePadding: true,
    label: "Port Of Discharge",
  },
  {
    id: "portOfLoading",
    numeric: true,
    disablePadding: true,
    label: "Port Of Loading",
  },
  {
    id: "paymentTerm",
    numeric: false,
    disablePadding: true,
    label: "Payment Term",
  },
  {
    id: "operationDate",
    numeric: false,
    disablePadding: true,
    label: "Issue Date",
  },
  {
    id: "estimatedTimeOfDeparture",
    numeric: false,
    disablePadding: true,
    label: "ETD",
  },
  {
    id: "estimatedTimeOfArrival",
    numeric: false,
    disablePadding: true,
    label: "ETA",
  },
  {
    id: "shipmentSchedule",
    numeric: false,
    disablePadding: true,
    label: "Shipment Schedule",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: true,
    label: "Remark",
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: true,
    label: "Comment",
  },
  {
    id: "offerId",
    numeric: true,
    disablePadding: true,
    label: "Offer Id",
  },
  {
    id: "offerNumber",
    numeric: false,
    disablePadding: true,
    label: "Offer Number",
  },
  {
    id: "offerDate",
    numeric: false,
    disablePadding: true,
    label: "Offer Date",
  },
  {
    id: "province",
    numeric: false,
    disablePadding: true,
    label: "Province",
  },
  {
    id: "shippingTerm",
    numeric: false,
    disablePadding: false,
    label: "Shipping Term",
  },
  {
    id: "approvalStatus",
    numeric: false,
    disablePadding: true,
    label: "SG Approval Status",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "recordStatus",
    numeric: false,
    disablePadding: true,
    label: "Record Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={
              headCell.id === "id"
                ? {
                    position: 'sticky',
                    left: 0,
                    zIndex: 3, 
                  }
                : headCell.id === "purchaseOrderNumber"
                ? {
                    position: 'sticky',
                    left: 50,
                    zIndex: 3, 
                  }
                : headCell.id === "itemBuyingGrade"
                ? {
                    position: 'sticky',
                    left: 180,
                    zIndex: 3, 
                  }
                : null
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    showFilters,
    setShowFilters,
    rows,
    setAddView,
    selected,
    setExportPoPopup,
    selectedData,
  } = props;

  const [deletePopup, setDeletePopup] = useState(false);
  const navigate = useNavigate();

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  function createNewSapObject(rows) {
    const newObjects = [];

    rows.forEach((row, index) => {
      const newObject = {};
      newObject.itemNumber = row?.offer?.item?.tobaccoCode || "";
      newObject.itemDescription = "";
      newObject.quantity = row?.volume || "";
      const finalPrice =
        typeof row?.finalPrice === "number"
          ? row?.finalPrice.toFixed(2)
          : row?.finalPrice || "";
      newObject.unitPrice =
        (row?.finalPriceCurrency || "") + " " + finalPrice.replace(".", ",");
      newObject.discount = "";
      newObject.totalLc =
        (row?.finalPriceCurrency || "") + " " + (row?.totalAmount || "");
      newObject.uomCode = row?.volumeUnit || "";
      newObject.customerPoNumber = "";
      newObject.customerGrade = row?.offer?.item?.salesGrade || "";
      newObject.internalGrade = "";
      newObject.vendorGrade = row?.offer?.item?.buyingGrade || "";
      newObject.countryOfOrigin = row?.offer?.item?.origin || "";
      newObject.leafType = row?.offer?.item?.type || "";
      newObject.leafForm = row?.offer?.item?.leafForm || "";
      newObject.cropYear = row?.offer?.item?.crop || "";
      newObject.kgPerCartonBaleNetWeight = row?.netWeightPerCase || "";
      newObject.numberOfCartons = row?.numberOfCases || "";
      newObject.etaDate = row?.estimatedTimeOfArrival
        ? formatDate(row.estimatedTimeOfArrival)
        : "";
      newObject.etdDate = row?.estimatedTimeOfDeparture
        ? formatDate(row.estimatedTimeOfDeparture)
        : "";
      newObject.moisture = row?.offer?.item?.moisture || "";
      newObject.nicotine = row?.offer?.item?.nicotine || "";
      newObject.sugar = row?.offer?.item?.sugar || "";
      newObject.containerTruckType = "";
      newObject.cartonPerContainerTruck = "";
      //newObject.kgPerContainerTruck = "";
      //newObject.temperatureTarget = "";
      newObject.numberOfContainers = "";
      newObject.packageType = row?.packaging || "";
      newObject.cartonNumberRange = "";
      newObject.salesQutationReferenceNumber = "";
      newObject.salesQutationReferenceDetails = "";
      newObject.salesQutationReferenceDate = "";
      newObject.netWeight = row?.volume || "";
      newObject.salesOrderDocLineNum = "";
      newObject.salesOrderDocNum = "";
      newObject.salesOrderLineNum = "";

      newObjects.push(newObject);
    });

    return newObjects;
  }
  function createNewExportObject(rows) {
    const newObjects = [];
    const headerMapping = {
      "Order Group Number": (row) =>
        (row?.orderYear ? row.orderYear + "-" : "") +
        (row?.orderGroupNumber || ""),
      "Approval Status": "approvalStatus",
      "Order Status": "orderStatus",
      "Status Deadline": "statusDeadline",
      Company: "company",
      "Item ID": "offer?.item?.id",
      "Tobacco Code": "offer?.item?.tobaccoCode",
      "Buying Grade": "offer?.item?.buyingGrade",
      "Sales Grade": "offer?.item?.salesGrade",
      Crop: "offer?.item?.crop",
      Nicotine: "offer?.item?.nicotine",
      Sugar: "offer?.item?.sugar",
      Origin: "offer?.item?.origin",
      Storage: "offer?.item?.storage",
      Type: "offer?.item?.type",
      "BP Code": "offer?.stakeholder?.code",
      "BP Name": "offer?.stakeholder?.name",
      Warehouse: "offer?.item?.warehouse",
      "First Sample ID": "firstSample?.id",
      "First Sample Delivery Date": "firstSample?.deliveryDate",
      "First Sample Status": "firstSampleStatus",
      "Second Sample ID": "secondSample?.id",
      "Second Sample Delivery Date": "secondSample?.deliveryDate",
      "Second Sample Status": "secondSampleStatus",
      Volume: "volume",
      "Volume Unit": "volumeUnit",
      Packaging: "packaging",
      "Net Weight Per Case": "netWeightPerCase",
      "Number of Cases": "numberOfCases",
      "First Offer Price": "offer?.firstOfferPrice",
      "First Offer Price Currency": "offer?.firstOfferPriceCurrency",
      "USD First Offer Price": "offer?.usdFirstOfferPrice",
      "Final Price": "finalPrice",
      "Final Price Currency": "finalPriceCurrency",
      "USD Final Price": "usdFinalPrice",
      "Total Amount": "totalAmount",
      "Total Amount Currency": "totalAmountCurrency",
      //"Cost ID": "cost?.id",
      //"Total Cost": "cost?.totalCost",
      "Contract Value Date": "contractValueDate",
      Incoterm: "incoterm",
      "Payment Term": "paymentTerm",
      "Operation Date": "operationDate",
      "Estimated Time of Departure": "estimatedTimeOfDeparture",
      "Estimated Time of Arrival": "estimatedTimeOfArrival",
      Remark: "remark",
      Comment: "comment",
      "Offer ID": "offer?.id",
      "Offer Group Number": (row) =>
        (row?.offer?.offerYear ? row.offer.offerYear + "-" : "") +
        (row?.offer?.offerGroupNumber || ""),
      "Offer Date": "offerDate",
      Province: "province",
      "Shipping Term": "shippingTerm",
      "SAP Purchase Order Number": "purchaseOrderNumber",
      "Reference Sales Order ID": "referenceCustomerOrderId",
      "Record Status": "recordStatus",
      "Editing Permission": "editingPermission",
    };

    rows.forEach((row) => {
      const newObject = {};
      Object.entries(headerMapping).forEach(([header, pathOrFunction]) => {
        let value;
        if (typeof pathOrFunction === "function") {
          value = pathOrFunction(row); // Calling the function with the current row if it's a function
        } else {
          const keys = pathOrFunction.split("?.");
          value = row;
          for (const key of keys) {
            if (value == null) break;
            value = value[key];
          }
        }
        if (header.endsWith("Date") && value) {
          value = formatDate(value);
        }
        newObject[header] = value ?? ""; // Use nullish coalescing operator to default to empty string if value is null or undefined
      });
      newObjects.push(newObject);
    });

    return newObjects;
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Dialog
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selected.length} order(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteOrdersApi(selected)
                .then((response) => {
                  console.log(response?.data);
                  toast.success("Purchase order(s) deleted successfully.");
                })
                .catch((error) => {
                  console.log(error.response?.data);
                  toast.error("Purchase order(s) could not deleted.");
                });

              window.location.reload();
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button onClick={() => setDeletePopup(false)}>No</Button>
        </DialogActions>
      </Dialog>

      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="#1E2685"
        >
          Purchase Orders
        </Typography>

        {numSelected > 0 ? (
          <Typography
            sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography></Typography>
        )}       
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton onClick={() => setDeletePopup(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {numSelected > 0 && (
          <Button
            variant="outlined"
            sx={{ minWidth: "130px" }}
            style={{ whiteSpace: "nowrap", marginLeft: 20 }}
            onClick={() => {
              console.log(selected);
              retrieveSelectedOrdersByIdApi(selected, "VENDOR")
                .then((response) => {
                  setTimeout(() => {
                    setExportPoPopup({
                      data: response?.data,
                      visible: true,
                    });
                  }, 1000);
                  console.log("pdfpopup ", response?.data);
                  toast.success("Purchase orders retrieved successfully.");
                })
                .catch((error) => {
                  console.log(error.response?.data);
                  toast.error("Purchase orders could not retrieved.");
                });
            }}
          >
            Create PO Excel
          </Button>
        )}
        {numSelected > 0 && (
          <Button
            variant="outlined"
            onClick={() =>
              navigate("/customer-offer-view", {
                state: {
                  data: {
                    selectedData: selectedData,
                  },
                  prevLocation: "purchase-order-view",
                },
              })
            }
          >
            Create Multiple Customer Offers
          </Button>
        )}
      </Stack>
      <Stack direction={"row"} spacing={1} sx={{ mr: 2 }}>
        <ExportExcel
          excelData={createNewExportObject(selectedData)}
          fileName={"ExcelExport"}
          buttonLabel="Export Table"
        />
        <ExportExcel
          excelData={createNewSapObject(selectedData)}
          fileName={"ExcelExportSAP"}
          buttonLabel="SAP Export"
        />
        <Button
          disableElevation
          variant="outlined"
          size="small"
          onClick={() => setAddView(true)}
        >
          Add
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px" }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function VendorOrderView() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showFilters, setShowFilters] = useState(false);
  const [addView, setAddView] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [rows, setRows] = useState([]);
  const [items, setItems] = useState([]);
  const [samples, setSamples] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [offers, setOffers] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [maxOrderGroupNumber, setMaxOrderGroupNumber] = useState();

  const [showOrderPopup, setShowOrderPopup] = useState({
    visible: false,
    data: null,
  });
  const [exportPoPopup, setExportPoPopup] = useState({
    visible: false,
    data: null,
  });
  const [editItem, setEditItem] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const [editCost, setEditCost] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const [editSample, setEditSample] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const [editVendorOffer, setEditVendorOffer] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const [showShipmentRequestPopup, setShowShipmentRequestPopup] = useState({
    visible: false,
    data: null,
  });

  const [componentsState, setComponentsState] = useState({
    origin: {
      dropdownOpen: false,
      inputValue: "",
    },
    item: {
      dropdownOpen: false,
      inputValue: "",
    },
    vendor: {
      dropdownOpen: false,
      inputValue: "",
    },
    type: {
      dropdownOpen: false,
      inputValue: "",
    },
    leafForm: {
      dropdownOpen: false,
      inputValue: "",
    },
    storage: {
      dropdownOpen: false,
      inputValue: "",
    },
    cost: {
      dropdownOpen: false,
      inputValue: "",
    },
    firstSample: {
      dropdownOpen: false,
      inputValue: "",
    },
    secondSample: {
      dropdownOpen: false,
      inputValue: "",
    },
    paymentTerm: {
      dropdownOpen: false,
      inputValue: "",
    },
    offer: {
      dropdownOpen: false,
      inputValue: "",
    },
  });

  const [item, setItem] = useState([]);
  const [sample, setSample] = useState([]);
  const navigate = useNavigate();
  const [offer, setOffer] = useState([]);

  const updateComponentState = (componentKey, newState) => {
    setComponentsState((prevState) => ({
      ...prevState,
      [componentKey]: {
        ...prevState[componentKey],
        ...newState,
      },
    }));
  };

  const [filterVendorOrder, setFilterVendorOrder] = useState({
    id: 0,
    orderType: "VENDOR",
    stakeHolderType: "VENDOR",
    itemBuyingGrade: "",
    itemIdList: [],
    stakeholderIdList: [],
    costIdList: [],
    offerIdList: [],
    companyList: [],
    approvalStatusList: [],
    orderStatusList: [],
    vendorList: [],
    firstSampleId: "",
    secondSampleId: "",
    costId: "",
    firstSampleIdList: [],
    secondSampleIdList: [],
    firstSampleStatusList: [],
    secondSampleStatusList: [],
    paymentTerm: "",
    paymentTermList: [],
    incotermList: [],
    portOfLoadingList: [],
    portOfDischargeList: [],
    packagingList: [],
    volumeUnitList: [],
    firstPriceCurrencyList: [],
    finalPriceCurrencyList: [],
    remark: "",
    shipmentSchedule: "",
    comment: "",
    documentStatusList: [],
    tobaccoCode: "",
    salesGrade: "",
    buyingGrade: "",
    customerGrade: "",
    originList: [],
    typeList: [],
    leafFormList: [],
    colorList: [],
    storageList: [],
    itemStorage: "",
    recordStatusList: ["ACTIVE"],
  });

  useEffect(() => {
    retrieveVendorOrders();
    getMaxOrderNumber();
  }, []);

  function approveOrder(orderId) {
    approveOrderApi(orderId)
      .then((response) => {
        window.location.reload();
        toast.success("Order approved successfully.");
      })
      .catch((error) => {
        toast.error("No Authorization");
        console.log(error.response.data);
      });
  }

  function cancelOrder(order) {
    cancelOrderApi(order?.id, order)
      .then((response) => {
        window.location.reload();
        toast.success("Order cancelled successfully.");
      })
      .catch((error) => {
        console.log("Order DTO: ", order);
        window.location.reload();
        console.log(error.response.data);
      });
  }
  function retrieveVendorOrders() {
    retrieveAllOrdersApi("VENDOR")
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveItemById(id) {
    retrieveItemByIdApi(id)
      .then((response) => {
        setItem(response?.data);
        console.log(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveOffers() {
    retrieveAllOffersApi("VENDOR")
      .then((response) => {
        setOffers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveOfferById(id) {
    retrieveOfferByIdApi(id)
      .then((response) => {
        setOffer(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveVendors() {
    retrieveAllStakeholdersApi("VENDOR")
      .then((response) => {
        setVendors(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveSamples() {
    retrieveAllSamplesApi()
      .then((response) => {
        setSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function retrieveSampleById(id) {
    retrieveSampleByIdApi(id)
      .then((response) => {
        setSample(response?.data);
        console.log(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function getMaxOrderNumber() {
    retrieveMaxOrderNumberApi("VENDOR")
      .then((response) => {
        console.log(response?.data);
        setMaxOrderGroupNumber(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const newOrderGroupNumber =
    maxOrderGroupNumber + (Math.floor(Math.random() * 5) + 1);

  const [editVendorOrder, setEditVendorOrder] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }else{
      setSelected([]);
      setSelectedData([]);
    }
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editVendorOrder],
  );

  return (
    <div>
      {addView ? (
        <AddVendorOrder
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          newOrderGroupNumber={newOrderGroupNumber}
        />
      ) : editVendorOrder.visible ? (
        <EditVendorOrder
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editVendorOrder={editVendorOrder}
          setEditVendorOrder={setEditVendorOrder}
        />
      ) : showOrderPopup.visible ? (
        <CreateOrder
          setCreateOrder={setShowOrderPopup}
          createOrder={showOrderPopup}
        />
      ) : showShipmentRequestPopup.visible ? (
        <CreatePurchaseOrderShipment
          setCreatePurchaseOrderShipmentRequest={setShowShipmentRequestPopup}
          createPurchaseOrderShipmentRequest={showShipmentRequestPopup}
        />
      ) : editItem.visible ? (
        <EditItem
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editItem={editItem}
          setEditItem={setEditItem}
        />
      ) : editCost.visible ? (
        <EditCost
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editCost={editCost}
          setEditCost={setEditCost}
        />
      ) : editSample.visible ? (
        <EditSample
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editSample={editSample}
          setEditSample={setEditSample}
        />
      ) : editVendorOffer.visible ? (
        <EditVendorOffer
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editVendorOffer={editVendorOffer}
          setEditVendorOffer={setEditVendorOffer}
        />
      ) : exportPoPopup.visible ? (
        <ExportPurchaseOrder
          setExportPoPopup={setExportPoPopup}
          exportPoPopup={exportPoPopup}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
               {/*} <TextField
                  id="outlined-search"
                  label="Group Number"
                  value={filterVendorOrder?.orderGroupNumber || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      orderGroupNumber: e.target.value,
                    })
                  }
                />*/}

                <TextField
                  id="outlined-search"
                  label="SAP PO Number"
                  value={filterVendorOrder?.purchaseOrderNumber || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      purchaseOrderNumber: e.target.value,
                    })
                  }
                />

                <Autocomplete
                  multiple
                  id="outlined-select-offer"
                  disableClearable
                  open={componentsState.offer.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("offer", { dropdownOpen: true })
                  }
                  inputValue={componentsState.offer.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("offer", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...offers.filter((offer) =>
                      filterVendorOrder.offerIdList.includes(offer.id),
                    ),
                    ...offers.filter(
                      (offer) =>
                        !filterVendorOrder.offerIdList.includes(offer.id),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `ID: ${option?.id || ""} Offer Number: ${
                      option?.offerYear || ""
                    } - ${option?.offerGroupNumber || ""}`
                  }
                  value={offers.filter((offer) =>
                    filterVendorOrder.offerIdList.includes(offer.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      offerIdList: newValue
                        .map((offer) => offer.id)
                        .filter((id) => id !== null),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`ID: ${option?.id || ""} Offer Number: ${
                        option?.offerYear || ""
                      } - ${option?.offerGroupNumber || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterVendorOrder?.offerIdList.length > 0
                        ? `Offers Selected (${filterVendorOrder?.offerIdList.length})`
                        : "Offer";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.offer.inputValue}
                        onChange={(e) =>
                          updateComponentState("offer", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={() => retrieveOffers}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("offer", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />
                <TextField
                  label="SG Approval Status"
                  type="search"
                  id="outlined-select-approval-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.approvalStatusList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        approvalStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {companies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Buying Company"
                  type="search"
                  id="outlined-select-company"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.companyList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        companyList: e.target.value,
                      });
                    },
                  }}
                >
                  {companies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Autocomplete
                  multiple
                  id="outlined-select-item"
                  disableClearable
                  open={componentsState.item.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("item", { dropdownOpen: true })
                  }
                  inputValue={componentsState.item.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("item", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[{ id: null, buyingGrade: "-", crop: "" }, ...items]}
                  getOptionLabel={(option) =>
                    option.id !== null
                      ? `ID: ${option?.id || ""} - ${
                          option?.buyingGrade || ""
                        } - ${option?.crop || ""}`
                      : "-"
                  }
                  value={items.filter((item) =>
                    filterVendorOrder.itemIdList.includes(item.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      itemIdList: newValue
                        .map((item) => item.id)
                        .filter((id) => id !== null),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {option.id !== null
                        ? `ID: ${option?.id || ""} - ${
                            option?.buyingGrade || ""
                          } - ${option?.crop || ""}`
                        : "-"}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterVendorOrder?.itemIdList.length > 0
                        ? `Items Selected (${filterVendorOrder?.itemIdList.length})`
                        : "Item";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.item.inputValue}
                        onChange={(e) =>
                          updateComponentState("item", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={retrieveItems}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("item", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  id="outlined-search"
                  label="Tobacco Code"
                  value={filterVendorOrder?.tobaccoCode || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      tobaccoCode: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Sales Grade"
                  value={filterVendorOrder?.salesGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      salesGrade: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Buying Grade"
                  value={filterVendorOrder?.buyingGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      buyingGrade: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Customer Grade"
                  value={filterVendorOrder?.customerGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      customerGrade: e.target.value,
                    })
                  }
                />
                <Autocomplete
                  multiple
                  id="outlined-select-origin"
                  disableClearable
                  open={componentsState.origin.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("origin", { dropdownOpen: true })
                  }
                  inputValue={componentsState.origin.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("origin", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...origin.filter((origin) =>
                      filterVendorOrder.originList.includes(origin.value),
                    ),
                    ...origin.filter(
                      (origin) =>
                        !filterVendorOrder.originList.includes(origin.value),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    option
                      ? `${option?.value || ""} - ${option?.label || ""}`
                      : ""
                  }
                  value={origin.filter((originOption) =>
                    filterVendorOrder.originList.includes(originOption.value),
                  )}
                  onChange={(event, newValue) => {
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      originList: newValue.map(
                        (originOption) => originOption.value,
                      ),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {option ? `${option?.label || ""}` : ""}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterVendorOrder?.originList.length > 0
                        ? `Origins Selected (${filterVendorOrder?.originList.length})`
                        : "Origin";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.origin.inputValue}
                        onChange={(e) =>
                          updateComponentState("origin", {
                            inputValue: e.target.value,
                          })
                        }
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("origin", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <Autocomplete
                  multiple
                  id="outlined-select-type"
                  disableClearable
                  open={componentsState.type.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("type", { dropdownOpen: true })
                  }
                  inputValue={componentsState.type.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("type", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...type.filter((type) =>
                      filterVendorOrder.typeList.includes(type.value),
                    ),
                    ...type.filter(
                      (type) =>
                        !filterVendorOrder.typeList.includes(type.value),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `${option?.value || ""} - ${option?.label || ""}`
                  }
                  value={type.filter((typeOption) =>
                    filterVendorOrder.typeList.includes(typeOption.value),
                  )}
                  onChange={(event, newValue) => {
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      typeList: newValue.map((typeOption) => typeOption.value),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.value || ""} - ${option?.label || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterVendorOrder?.typeList.length > 0
                        ? `Types Selected (${filterVendorOrder?.typeList.length})`
                        : "Type";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.type.inputValue}
                        onChange={(e) =>
                          updateComponentState("type", {
                            inputValue: e.target.value,
                          })
                        }
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("type", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <Autocomplete
                  multiple
                  id="outlined-select-leaf-form"
                  disableClearable
                  open={componentsState.leafForm.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("leafForm", { dropdownOpen: true })
                  }
                  inputValue={componentsState.leafForm.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("leafForm", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...leafForm.filter((leafForm) =>
                      filterVendorOrder.leafFormList.includes(leafForm.value),
                    ),
                    ...leafForm.filter(
                      (leafForm) =>
                        !filterVendorOrder.leafFormList.includes(
                          leafForm.value,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    option
                      ? `${option?.value || ""} - ${option?.label || ""}`
                      : ""
                  }
                  value={leafForm.filter((leafFormOption) =>
                    filterVendorOrder.leafFormList.includes(
                      leafFormOption.value,
                    ),
                  )}
                  onChange={(event, newValue) => {
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      leafFormList: newValue.map(
                        (leafFormOption) => leafFormOption.value,
                      ),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {option
                        ? `${option?.value || ""} - ${option?.label || ""}`
                        : ""}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterVendorOrder?.leafFormList.length > 0
                        ? `Leaf Forms Selected (${filterVendorOrder?.leafFormList.length})`
                        : "Leaf Form";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.leafForm.inputValue}
                        onChange={(e) =>
                          updateComponentState("leafForm", {
                            inputValue: e.target.value,
                          })
                        }
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("leafForm", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  label="Color"
                  type="search"
                  id="outlined-select-color"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.colorList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        colorList: e.target.value,
                      });
                    },
                  }}
                >
                  {color.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-search"
                  label="Crop Year"
                  value={filterVendorOrder?.crop || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      crop: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Current Location"
                  type="search"
                  value={filterVendorOrder?.itemStorage || ""}
                  id="outlined-storage"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      itemStorage: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Nicotine"
                  value={filterVendorOrder?.nicotine || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      nicotine: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Sugar"
                  value={filterVendorOrder?.sugar || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      sugar: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Warehouse"
                  value={filterVendorOrder?.warehouse || ""}
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      warehouse: e.target.value,
                    })
                  }
                />
                <Autocomplete
                  multiple
                  id="outlined-select-vendor"
                  disableClearable
                  open={componentsState.vendor.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("vendor", { dropdownOpen: true })
                  }
                  inputValue={componentsState.vendor.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("vendor", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...vendors.filter((vendor) =>
                      filterVendorOrder.stakeholderIdList.includes(vendor.id),
                    ),
                    ...vendors.filter(
                      (vendor) =>
                        !filterVendorOrder.stakeholderIdList.includes(
                          vendor.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    option ? `${option?.id || ""} - ${option?.name || ""}` : ""
                  }
                  value={vendors.filter((vendor) =>
                    filterVendorOrder.stakeholderIdList.includes(vendor.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      stakeholderIdList: newValue.map((vendor) => vendor.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {option
                        ? `${option?.id || ""} - ${option?.name || ""}`
                        : ""}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterVendorOrder?.stakeholderIdList.length > 0
                        ? `Vendors Selected (${filterVendorOrder?.stakeholderIdList.length})`
                        : "Vendor";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.vendor.inputValue}
                        onChange={(e) =>
                          updateComponentState("vendor", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={retrieveVendors}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("vendor", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <Autocomplete
                  multiple
                  id="outlined-select-first-sample"
                  disableClearable
                  open={componentsState.firstSample.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("firstSample", { dropdownOpen: true })
                  }
                  inputValue={componentsState.firstSample.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("firstSample", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...samples.filter((sample) =>
                      filterVendorOrder.firstSampleIdList.includes(sample.id),
                    ),
                    ...samples.filter(
                      (sample) =>
                        !filterVendorOrder.firstSampleIdList.includes(
                          sample.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    option
                      ? `${option?.id || ""} - ${option?.remark || ""}`
                      : ""
                  }
                  value={samples.filter((sample) =>
                    filterVendorOrder.firstSampleIdList.includes(sample.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      firstSampleIdList: newValue.map((sample) => sample.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {option
                        ? `${option?.id || ""} - ${option?.remark || ""}`
                        : ""}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterVendorOrder?.firstSampleIdList.length > 0
                        ? `First Samples Selected (${filterVendorOrder?.firstSampleIdList.length})`
                        : "First Sample";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.firstSample.inputValue}
                        onChange={(e) =>
                          updateComponentState("firstSample", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={retrieveSamples}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("firstSample", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  label="First Sample Status"
                  type="search"
                  id="outlined-select-first-sample-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.firstSampleStatusList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        firstSampleStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {sampleStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Autocomplete
                  multiple
                  id="outlined-select-second-sample"
                  disableClearable
                  open={componentsState.secondSample.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("secondSample", { dropdownOpen: true })
                  }
                  inputValue={componentsState.secondSample.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("secondSample", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...samples.filter((sample) =>
                      filterVendorOrder.secondSampleIdList.includes(sample.id),
                    ),
                    ...samples.filter(
                      (sample) =>
                        !filterVendorOrder.secondSampleIdList.includes(
                          sample.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    option
                      ? `${option?.id || ""} - ${option?.remark || ""}`
                      : ""
                  }
                  value={samples.filter((sample) =>
                    filterVendorOrder.secondSampleIdList.includes(sample.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      secondSampleIdList: newValue.map((sample) => sample.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {option
                        ? `${option?.id || ""} - ${option?.remark || ""}`
                        : ""}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterVendorOrder?.secondSampleIdList.length > 0
                        ? `Second Samples Selected (${filterVendorOrder?.secondSampleIdList.length})`
                        : "Second Sample";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.secondSample.inputValue}
                        onChange={(e) =>
                          updateComponentState("secondSample", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={retrieveSamples}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("secondSample", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />
                <TextField
                  label="Second Sample Status"
                  type="search"
                  id="outlined-select-second-sample-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.secondSampleStatusList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        secondSampleStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {sampleStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Packaging"
                  type="search"
                  id="outlined-select-volume-unit"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.packagingList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        packagingList: e.target.value,
                      });
                    },
                  }}
                >
                  {packagingTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-number"
                  label="Net Weight Per Case"
                  value={filterVendorOrder?.netWeightPerCase || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      netWeightPerCase: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Number Of Cases"
                  value={filterVendorOrder?.numberOfCases || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      numberOfCases: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Quantity"
                  value={filterVendorOrder?.volume || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      volume: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Quantity Unit"
                  type="search"
                  id="outlined-select-volume-unit"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.volumeUnitList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        volumeUnitList: e.target.value,
                      });
                    },
                  }}
                >
                  {units.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-number"
                  label="First Offer Price"
                  value={filterVendorOrder?.firstPrice || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      firstPrice: e.target.value,
                    })
                  }
                />

                <TextField
                  label="First Offer Price Currency"
                  type="search"
                  id="outlined-select-first-offer-price-currency"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.firstPriceCurrencyList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        firstPriceCurrencyList: e.target.value,
                      });
                    },
                  }}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="outlined-number"
                  label="Final Price"
                  value={filterVendorOrder?.finalPrice || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      finalPrice: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Final Offer Price Currency"
                  type="search"
                  id="outlined-select-final-offer-price-currency"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.finalPriceCurrencyList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        finalPriceCurrencyList: e.target.value,
                      });
                    },
                  }}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="outlined-number"
                  label="Total Amount"
                  value={filterVendorOrder?.totalAmount || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      totalAmount: e.target.value,
                    })
                  }
                />

                {/*<Autocomplete
                  multiple
                  id="outlined-select-cost"
                  disableClearable
                  open={componentsState.cost.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("cost", { dropdownOpen: true })
                  }
                  inputValue={componentsState.cost.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("cost", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...costs.filter((cost) =>
                      filterVendorOrder.costIdList.includes(cost.id),
                    ),
                    ...costs.filter(
                      (cost) => !filterVendorOrder.costIdList.includes(cost.id),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    option
                      ? `${option?.id || ""} - ${option?.remark || ""} - ${
                          option?.totalCost || ""
                        }`
                      : ""
                  }
                  value={costs.filter((costOption) =>
                    filterVendorOrder.costIdList.includes(costOption.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      costIdList: newValue.map((costOption) => costOption.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {option
                        ? `${option?.id || ""} - ${option?.remark || ""} - ${
                            option?.totalCost || ""
                          }`
                        : ""}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterVendorOrder?.costIdList.length > 0
                        ? `Costs Selected (${filterVendorOrder?.costIdList.length})`
                        : "Cost";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.cost.inputValue}
                        onChange={(e) =>
                          updateComponentState("cost", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={retrieveCosts}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("cost", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />*/}

                <TextField
                  id="date"
                  type="date"
                  label="Contract Value Date"
                  value={filterVendorOrder?.contractValueDate || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      contractValueDate: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Incoterm"
                  type="search"
                  id="outlined-select-incoterm"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.incotermList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        incotermList: e.target.value,
                      });
                    },
                  }}
                >
                  {incoterms.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Port Of Loading"
                  type="search"
                  id="outlined-select-pol"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.portOfLoadingList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        portOfLoadingList: e.target.value,
                      });
                    },
                  }}
                >
                  {portsOfLoading.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Port Of Discharge"
                  type="search"
                  id="outlined-select-pod"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.portOfDischargeList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        portOfDischargeList: e.target.value,
                      });
                    },
                  }}
                >
                  {portsOfDischarge.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Autocomplete
                  multiple
                  id="outlined-select-payment-term"
                  disableClearable
                  open={componentsState.paymentTerm.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("paymentTerm", { dropdownOpen: true })
                  }
                  inputValue={componentsState.paymentTerm.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("paymentTerm", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...paymentTerm.filter((term) =>
                      filterVendorOrder.paymentTermList.includes(term.value),
                    ),
                    ...paymentTerm.filter(
                      (term) =>
                        !filterVendorOrder.paymentTermList.includes(term.value),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    option
                      ? `${option?.value || ""} - ${option?.label || ""}`
                      : ""
                  }
                  value={paymentTerm.filter((term) =>
                    filterVendorOrder.paymentTermList.includes(term.value),
                  )}
                  onChange={(event, newValue) => {
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      paymentTermList: newValue.map((term) => term.value),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {option ? `${option?.label || ""}` : ""}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterVendorOrder?.paymentTermList.length > 0
                        ? `Payment Terms Selected (${filterVendorOrder?.paymentTermList.length})`
                        : "Payment Term";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.paymentTerm.inputValue}
                        onChange={(e) =>
                          updateComponentState("paymentTerm", {
                            inputValue: e.target.value,
                          })
                        }
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("paymentTerm", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  id="date"
                  type="date"
                  label="ETD"
                  value={filterVendorOrder?.estimatedTimeOfDeparture || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      estimatedTimeOfDeparture: e.target.value,
                    })
                  }
                />
                <TextField
                  id="date"
                  type="date"
                  label="ETA"
                  value={filterVendorOrder?.estimatedTimeOfArrival || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      estimatedTimeOfArrival: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Shipment Schedule"
                  value={filterVendorOrder?.shipmentSchedule || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      shipmentSchedule: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Remark"
                  value={filterVendorOrder?.remark || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      remark: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Comment"
                  value={filterVendorOrder?.comment || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterVendorOrder({
                      ...filterVendorOrder,
                      comment: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Record Status"
                  type="search"
                  id="outlined-select-document-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterVendorOrder.recordStatusList || [],
                    onChange: (e) => {
                      setFilterVendorOrder({
                        ...filterVendorOrder,
                        recordStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    size="small"
                    onClick={() => {
                      retrieveFilteredOrdersApi(filterVendorOrder)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    size="small"
                    onClick={() => {
                      setFilterVendorOrder({
                        id: 0,
                        orderType: "VENDOR",
                        stakeHolderType: "VENDOR",
                        itemBuyingGrade: "",
                        itemIdList: [],
                        stakeholderIdList: [],
                        costIdList: [],
                        offerIdList: [],
                        companyList: [],
                        approvalStatusList: [],
                        orderStatusList: [],
                        customerList: [],
                        firstSampleId: "",
                        secondSampleId: "",
                        costId: "",
                        firstSampleStatusList: [],
                        secondSampleStatusList: [],
                        paymentTerm: "",
                        paymentTermList: [],
                        incotermList: [],
                        portOfLoadingList: [],
                        portOfDischargeList: [],
                        packagingList: [],
                        volumeUnitList: [],
                        firstPriceCurrencyList: [],
                        finalPriceCurrencyList: [],
                        remark: "",
                        shipmentSchedule: "",
                        comment: "",
                        documentStatusList: [],
                        tobaccoCode: "",
                        salesGrade: "",
                        crop: null,
                        nicotine: null,
                        sugar: null,
                        moisture: null,
                        warehouse: null,
                        volume: null,
                        netWeightPerCase: null,
                        numberOfCases: null,
                        quantity: null,
                        firstPrice: null,
                        finalPrice: null,
                        totalAmount: null,
                        statusDeadline: null,
                        contractValueDate: null,
                        estimatedTimeOfDeparture: null,
                        estimatedTimeOfArrival: null,
                        orderGroupNumber: null,
                        firstSampleIdList: [],
                        secondSampleIdList: [],
                        buyingGrade: "",
                        customerGrade: "",
                        originList: [],
                        typeList: [],
                        leafFormList: [],
                        colorList: [],
                        storageList: [],
                        itemStorage: "",
                        recordStatusList: ["ACTIVE"],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
              <br></br>
            </Box>
          )}

          <Box sx={{ width: "100%", mt: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                rows={rows}
                setAddView={setAddView}
                selected={selected}
                setExportPoPopup={setExportPoPopup}
                exportPoPopup={exportPoPopup}
                selectedData={selectedData}
              />
              <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  striped="columns"
                  style={{ width: "max-content" }}
                 sx={{
                    minWidth: 750,
                    "& .MuiTableCell-root": {
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiTableCell-head": {
                      backgroundColor: "#20315d",
                      color: "white",
                      borderRight: "1px solid rgba(49, 64, 128, 1)",
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          style={
                              isSelected(row.id) ? { background: "#d7e7fc" }
                              : row.orderStatus === "Po Created"
                              ? { background: "#c8e6d2" }
                              : index % 2
                              ? { background: "#f0f0f0" }
                              : { background: "white" }
                          }
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              onClick={(e) => {
                                setEditVendorOrder({
                                  visible: true,
                                  data: row,
                                  index: index,
                                });
                                e.preventDefault();
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              variant="outlined"
                              disabled={row?.approvalStatus === "Approved"}
                              onClick={() => {
                                approveOrder(row?.id);
                              }}
                            >
                              Approve
                            </Button>
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              style={{ whiteSpace: "nowrap" }}
                              variant="outlined"
                              disabled={row?.approvalStatus !== "Approved"}
                              onClick={() =>
                                setShowShipmentRequestPopup({
                                  data: row,
                                  visible: true,
                                })
                              }
                            >
                              Create Shipment Request
                            </Button>
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              style={{ whiteSpace: "nowrap" }}
                              variant="outlined"
                              onClick={() =>
                                navigate("/customer-offer-view", {
                                  state: {
                                    data: {
                                      item: row?.item,
                                      incoterm: row?.incoterm,
                                      buyingPrice: row?.finalPrice,
                                      buyingPriceCurrency:
                                        row?.finalPriceCurrency,
                                      netWeightPerCase: row?.netWeightPerCase || null,
                                      numberOfCases: row?.numberOfCases || null,
                                      volume: row?.volume || null,
                                    },
                                    prevLocation: "purchase-order-view",
                                  },
                                })
                              }
                            >
                              Customer Offer
                            </Button>
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              style={{ whiteSpace: "nowrap" }}
                              variant="outlined"
                              color="warning"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    `Are you sure you want to cancel PO ${
                                      row?.purchaseOrderNumber || ""
                                    } for item: ${
                                      row?.item?.buyingGrade
                                    }\nPO Quantity: ${row?.volume || "-"}  ${
                                      row?.volumeUnit || ""
                                    } `,
                                  )
                                ) {
                                  window.location.reload();
                                  cancelOrder(row);
                                }
                              }}
                            >
                              Cancel PO
                            </Button>
                          </TableCell>
                          <TableCell align="center" sx={{ position: 'sticky', left: 0, background: 'inherit', zIndex: 1 }}>{row?.id}</TableCell>
                          <TableCell align="center" sx={{ position: 'sticky', left: 50, background: 'inherit', zIndex: 1 }}>{row?.purchaseOrderNumber}</TableCell>
                          <TableCell align="center">
                            {row?.orderStatus}
                          </TableCell>
                          <TableCell align="center">
                            {row?.company}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                retrieveItemById(row?.offer?.item?.id);
                              }}
                              onDoubleClick={() => {
                                setTimeout(() => {
                                  setEditItem({
                                    visible: true,
                                    data: item,
                                    index: index,
                                  });
                                }, 1000);
                              }}
                            >
                              {row?.offer?.item?.id}
                            </Button>
                          </TableCell>
                          <TableCell align="center" sx={{ position: 'sticky', left: 180, background: 'inherit', zIndex: 1 }}>
                            {row?.offer?.item?.buyingGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.salesGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.crop}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.nicotine}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.sugar}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.moisture}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.origin}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.storage}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.type}
                          </TableCell>
                          <TableCell align="center">{row?.warehouse}</TableCell>
                          <TableCell align="center">
                            {row?.offer?.stakeholder?.code}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.stakeholder?.name}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                retrieveSampleById(row?.firstSample?.id);
                              }}
                              onDoubleClick={() => {
                                setTimeout(() => {
                                  setEditSample({
                                    visible: true,
                                    data: sample,
                                    index: index,
                                  });
                                }, 1000);
                              }}
                            >
                              {row?.firstSample?.id}
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {row?.firstSample?.deliveryDate
                              ? format(
                                  new Date(row?.firstSample?.deliveryDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.firstSampleStatus}
                          </TableCell>
                          <TableCell align="center">{row?.packaging}</TableCell>
                          <TableCell align="center">
                            {row?.netWeightPerCase}
                          </TableCell>
                          <TableCell align="center">
                            {row?.numberOfCases}
                          </TableCell>
                          <TableCell align="center">  {row?.volume
                              ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row.volume)}`
                              : ""}{" "}
                            {row?.volumeUnit}</TableCell>
                          <TableCell align="center">
                            {row?.offer?.firstOfferPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row?.offer?.firstOfferPrice)}`
                              : ""}{" "}
                            {row?.offer?.firstOfferPriceCurrency}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.usdFirstOfferPrice} USD
                          </TableCell>
                          <TableCell align="center">
                            {row?.finalPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row?.finalPrice)}`
                              : ""}{" "}
                            {row?.finalPriceCurrency}
                          </TableCell>
                          <TableCell align="center">
                            {row?.usdFinalPrice} USD
                          </TableCell>
                          <TableCell align="center">
                            {row?.totalAmount
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row.totalAmount)}`
                              : ""}{" "}
                            {row?.finalPriceCurrency}
                          </TableCell>

                          <TableCell align="center">
                            {row?.contractValueDate
                              ? format(
                                  new Date(row?.contractValueDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row?.incoterm}</TableCell>
                          <TableCell align="center">
                            {row?.portOfDischarge}
                          </TableCell>
                          <TableCell align="center">
                            {row?.portOfLoading}
                          </TableCell>
                          <TableCell align="center">
                            {row?.paymentTerm}
                          </TableCell>
                          <TableCell align="center">
                            {row?.operationDate
                              ? format(
                                  new Date(row?.operationDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.estimatedTimeOfDeparture
                              ? format(
                                  new Date(row?.estimatedTimeOfDeparture),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.estimatedTimeOfArrival
                              ? format(
                                  new Date(row?.estimatedTimeOfArrival),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="left">
                              {row?.shipmentSchedule?.length > 100 ? (
                                <span style={{
                                  display: 'inline-block',
                                  maxWidth: '350px', 
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  verticalAlign: 'top',
                                }}
                                title={row?.shipmentSchedule}>
                                  {row?.shipmentSchedule}
                                </span>
                              ) : (
                                row?.shipmentSchedule
                              )}
                            </TableCell>
                          <TableCell align="center">{row?.remark}</TableCell>
                          <TableCell align="center">{row?.comment}</TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                retrieveOfferById(row?.offer?.id);
                              }}
                              onDoubleClick={() => {
                                setTimeout(() => {
                                  setEditVendorOffer({
                                    visible: true,
                                    data: offer,
                                    index: index,
                                  });
                                }, 1000);
                              }}
                            >
                              {row?.offer?.id}
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.offerYear +
                              "-" +
                              row?.offer?.offerGroupNumber}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.offerDate
                              ? format(
                                  new Date(row?.offer?.offerDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row?.province}</TableCell>
                          <TableCell align="center">
                            {row?.shippingTerm}
                          </TableCell>
                          <TableCell align="center">
                            {row?.approvalStatus}
                          </TableCell>
                          <TableCell align="center">
                            {row?.timestamp
                              ? format(
                                  new Date(row.timestamp),
                                  "dd/MM/yyyy HH:mm",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">
                            {row?.recordStatus}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <div style={{ marginTop: '20px', marginLeft: '20px' }}>
  <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center' }}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <span
        style={{
          background: "#c8e6c9",
          padding: "4px 8px",
          marginRight: "5px",
          borderRadius: '8px', 
          display: 'inline-block',
        }}
      >
        Created
      </span>
      <span>- PO file created</span>
    </div>
  </div>
</div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
        </>
      )}
    </div>
  );
}

