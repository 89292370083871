import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  LoadingOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  DatabaseOutlined,
  FileTextOutlined,
  AppstoreAddOutlined,
  UserOutlined,
  DollarOutlined,
  FormOutlined,
  SendOutlined,
  FileDoneOutlined,
};

const order = {
  id: "order-accordion",
  title: "Order Menu",
  type: "accordion",
  children: [
    {
      id: "san-order",
      title: "Purchase Orders",
      type: "item",
      url: "/purchase-order-view",
      icon: icons.FileDoneOutlined,
    },
   /* {
      id: "purchase-order",
      title: "Purchase Orders",
      type: "item",
      url: "/purchase-order-view",
      icon: icons.FormOutlined,
    },
    {
      id: "sales-order",
      title: "Sales Orders",
      type: "item",
      url: "/sales-order-view",
      icon: icons.FormOutlined,
    },*/
    {
      id: "sales-order",
      title: "Sales Orders",
      type: "item",
      url: "/sales-order",
      icon: icons.FormOutlined,
    },
  ],
};

export default order;
