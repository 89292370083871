import { apiClient } from "./ApiClient";

export const retrieveAllTradeRecordsApi = () =>
  apiClient.get(`/trade-view/records`);

export const createRecordApi = (trade) =>
  apiClient.post(`/trade-view/add`, trade);

export const updateRecordApi = (tradeId, trade) =>
  apiClient.put(`/trade-view/edit/${tradeId}`, trade);

export const retrieveFilteredRecordsApi = (trade) =>
  apiClient.post(`/trade-view/filter`, trade);
