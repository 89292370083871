import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import { updateStakeholderApi } from "../../../components/api/StakeholderApiService";
import { recordStatus } from "../../../utils/menu-items";

const EditVendor = (props) => {
  const { editVendor, setEditVendor } = props;
  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Vendor
        </Typography>
        <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Vendor Code</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendor?.data?.code}
              onChange={(e) =>
                setEditVendor({
                  ...editVendor,
                  data: {
                    ...editVendor.data,
                    code: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Vendor Name</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendor?.data?.name || ""}
              onChange={(e) =>
                setEditVendor({
                  ...editVendor,
                  data: {
                    ...editVendor.data,
                    name: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Contact Person</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editVendor?.data?.contactPerson || ""}
              onChange={(e) =>
                setEditVendor({
                  ...editVendor,
                  data: {
                    ...editVendor.data,
                    contactPerson: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editVendor?.data?.status}
              onChange={(e) =>
                setEditVendor({
                  ...editVendor,
                  data: {
                    ...editVendor.data,
                    status: e.target.value,
                  },
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setEditVendor({ visible: false, data: null, index: null })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              updateStakeholderApi(editVendor.data.id, editVendor.data)
                .then((response) => {
                  toast.success("Vendor edited successfully.");
                  window.location.reload();

                  setEditVendor({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              editVendor.data.status = "PASSIVE";
              updateStakeholderApi(editVendor.data.id, editVendor.data)
                .then((response) => {
                  toast.success("Vendor deleted successfully.");
                  window.location.reload();
                  setEditVendor({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default EditVendor;
