import {
  Autocomplete,
    Box,
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import { format, parseISO } from "date-fns";
  import React, { useState } from "react";
  import { retrieveMaxShippingInstructionNumberApi, updateShippingInstructionApi } from "../../../components/api/ShippingInstructionApiService";
  import { currencies, incoterms, status, units, recordStatus, freightOfferRemarks, shippedBy, shippingInstructionTypes, shippingInstructionStatus, warehouses } from "../../../utils/menu-items";
  import toast from "react-hot-toast";
import { retrieveAllShipmentRequestsApi, retrieveFilteredShipmentRequestsApi } from "../../../components/api/ShipmentRequestApiService";
import { retrieveAllFreightOffersApi, retrieveFilteredFreightOffersApi } from "../../../components/api/FreightOfferApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
  
  const EditShippingInstruction = (props) => {
    const { editShippingInstruction, setEditShippingInstruction } = props;
    const [selectedShipmentRequest, setSelectedShipmentRequest] = useState(editShippingInstruction?.data?.shipmentRequest || null);
    const [shipmentRequests, setShipmentRequests] = useState([]);

    const [selectedFreightOffer, setSelectedFreightOffer] = useState(editShippingInstruction?.data?.freightOffer || null);
    const [freightOffers, setFreightOffers] = useState([]);

    const [selectedShipper, setSelectedShipper] = useState(editShippingInstruction?.data?.shipper || null);
    const [selectedConsignee, setSelectedConsignee] = useState(editShippingInstruction?.data?.consignee || null);
    const [selectedNotify, setSelectedNotify] = useState(editShippingInstruction?.data?.notify || null);
    const [notifies, setNotifies] = useState([]);
    const [shippers, setShippers] = useState([]);
    const [consignees, setConsignees] = useState([]);
    const [sameAsCustomerConsignee, setSameAsCustomerConsignee] = useState(false);
    const [sameAsCustomerNotify, setSameAsCustomerNotify] = useState(false);

    const [siNumber, setSiNumber] = useState("");
    const [isSiNumberRetrieved, setIsSiNumberRetrieved] = useState(true);

    const [showSecondEtdUpdate, setShowSecondEtdUpdate] = useState(editShippingInstruction?.data?.secondEstimatedTimeOfDeparture ? true:false);
    const [showThirdEtdUpdate, setShowThirdEtdUpdate] = useState(editShippingInstruction?.data?.thirdEstimatedTimeOfDeparture ? true:false);
  
    const [showSecondEtaUpdate, setShowSecondEtaUpdate] = useState(editShippingInstruction?.data?.secondEstimatedTimeOfArrival ? true:false);
    const [showThirdEtaUpdate, setShowThirdEtaUpdate] = useState(editShippingInstruction?.data?.thirdEstimatedTimeOfArrival ? true:false);

    const handleUpdateTime = (type) => {
      if(type==="ETD"){
        if (editShippingInstruction?.data?.estimatedTimeOfDeparture && !showSecondEtdUpdate) {
          setShowSecondEtdUpdate(true);
        } else if (editShippingInstruction?.data?.secondEstimatedTimeOfDeparture && !showThirdEtdUpdate) {
          setShowThirdEtdUpdate(true);
        }else if(showThirdEtdUpdate){
          toast.error("All ETD update fields are already in use.");
        }else{
          toast.error("Please fill in the previous ETD values before proceeding");
        }
      }else if(type==="ETA"){
        if (editShippingInstruction?.data?.estimatedTimeOfArrival && !showSecondEtaUpdate) {
          setShowSecondEtaUpdate(true);
        } else if (editShippingInstruction?.data?.secondEstimatedTimeOfArrival && !showThirdEtaUpdate) {
          setShowThirdEtaUpdate(true);
        }else if(showThirdEtaUpdate){
          toast.error("All ETA update fields are already in use.");
        }else{
          toast.error("Please fill in the previous ETA values before proceeding");
        }
  
      }
    };

    const getDigit = (type) => {
      if (type === "HT" || type === "HD") {
        return 100;
      } else if (type === "ST" || type === "SD" || type === "B") {
        return 1000;
      } else {
        return 1000;
      }
    };

    function handleRetrieveSiNumber() {
      retrieveMaxShippingInstructionNumberApi(
        editShippingInstruction?.data?.shippingInstructionType,
      )
        .then((response) => {
          const siNumberFromApi = response?.data || 0;
          const currentYear = new Date().getFullYear();
          const siType = editShippingInstruction?.data?.shippingInstructionType || '';
          const calculatedNumber = ((currentYear % 10) * getDigit(siType)) + (siNumberFromApi + 1);
          const finalShippingInstructionNumber = `${siType}${calculatedNumber}`;
          const newSiNumber = siNumberFromApi + 1;
          setSiNumber(newSiNumber);
          setIsSiNumberRetrieved(true);
          setEditShippingInstruction({
            ...editShippingInstruction,
            data: {
              ...editShippingInstruction.data,
              shippingInstructionNumber: siNumberFromApi + 1,
            finalShippingInstructionNumber: finalShippingInstructionNumber,
            },
          });
        })
        .catch((error) => {
          console.log(error.response?.data);
          toast.error("Error retrieving SI number.");
        });
    }
    const handleConsigneeCheckboxChange = (event) => {
      const isChecked = event.target.checked;
      setSameAsCustomerConsignee(isChecked);
  
      if (isChecked && selectedShipmentRequest?.salesOrder?.offer?.stakeholder) {
          setSelectedConsignee(selectedShipmentRequest?.salesOrder?.offer?.stakeholder);
          setEditShippingInstruction({
            ...editShippingInstruction,
            data:{
             ...editShippingInstruction.data,
             consignee: selectedShipmentRequest?.salesOrder?.offer?.stakeholder || {}
            }
          });
      } else {
        setSelectedConsignee(null);
        setEditShippingInstruction({
          ...editShippingInstruction,
          data:{
           ...editShippingInstruction.data,
           consignee: null,
          }
        });
      }
    };

    const handleNotifyCheckboxChange = (event) => {
      const isChecked = event.target.checked;
      setSameAsCustomerNotify(isChecked);
  
      if (isChecked && selectedShipmentRequest?.salesOrder?.offer?.stakeholder) {
          setSelectedNotify(selectedShipmentRequest?.salesOrder?.offer?.stakeholder);
          setEditShippingInstruction({
            ...editShippingInstruction,
            data:{
             ...editShippingInstruction.data,
             notify: selectedShipmentRequest?.salesOrder?.offer?.stakeholder || {}
            }
          });
      } else {
        setSelectedNotify(null);
        setEditShippingInstruction({
          ...editShippingInstruction,
          data:{
           ...editShippingInstruction.data,
           notify: null,
          }
        });
      }
    };

    const filterShipmentRequests = { 
      approvalStatusList:["Approved"],
      recordStatusList:["ACTIVE"],
    };
  
    function retrieveShipmentRequests() {
      retrieveFilteredShipmentRequestsApi(filterShipmentRequests)
        .then((response) => {
          setShipmentRequests(response?.data);
        })
        .catch((error) => console.log(error.response?.data));
    }

    const today = new Date().toISOString().split("T")[0];
    const filterFreightOffers = { 
      validTo:today,
      recordStatusList:["ACTIVE"],
    };
  
    function retrieveFreightOffers() {
      retrieveFilteredFreightOffersApi(filterFreightOffers)
        .then((response) => {
          setFreightOffers(response?.data);
        })
        .catch((error) => console.log(error.response?.data));
    }

    function retrieveStakeholders(type) {
      retrieveAllStakeholdersApi(type)
        .then((response) => {
          if(type==="SHIPPER"){
            setShippers(response?.data);
          }else if(type==="CONSIGNEE"){
            setConsignees(response?.data);
          }else if(type==="NOTIFY"){
            setNotifies(response?.data);
          }

        })
        .catch((error) => console.log(error.response?.data));
    }
    
    const handleDateChange = (e) => {
      const selectedDate = e.target.value;
      const parsedDate = parseISO(selectedDate);
  
      if (!isNaN(parsedDate)) {
        setEditShippingInstruction({
          ...editShippingInstruction,
                       data:{
                        ...editShippingInstruction.data,
          dateOfLoading: selectedDate,
          dateOfLoadingRemark: format(parsedDate, "dd/MMM/yyyy"),
        }
      });
      } else {
        console.error("Invalid date:", selectedDate);
      }
    };

    return (
      <>
        <Stack direcion="column">
          <Typography variant="body1" mb={2}>
            Edit Shipping Instruction
          </Typography>
          <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
                 <Typography variant="body2">Shipment Request</Typography>
                 <Autocomplete
                   options={shipmentRequests || []}
                   getOptionLabel={(option) => {
                    const values = [
                      option?.id,
                      option?.purchaseOrder?.purchaseOrderNumber ? `SAP PO Number: ${option.purchaseOrder?.purchaseOrderNumber}` : null,
                      option?.purchaseOrder?.offer?.stakeholder?.name ? `Supplier: ${option.purchaseOrder?.offer?.stakeholder?.name}` : null,
                      option?.purchaseOrder?.offer?.item?.origin ? `Origin: ${option.purchaseOrder?.offer?.item?.origin}` : null,
                      option?.purchaseOrder?.offer?.item?.buyingGrade ? `Buying Grade: ${option.purchaseOrder?.offer?.item?.buyingGrade}` : null,
                      option?.status ? `${option.status}` : null,
                    ].filter(value => value); 
          
                    return values.join(" - ");
                  }}
                   value={selectedShipmentRequest}
                   onChange={(event, newValue) => {
                    setSelectedShipmentRequest(newValue);
                    setEditShippingInstruction((prevState) => ({
                      ...prevState,
                      data: {
                        ...prevState.data,
                        shipmentRequest: newValue || {},
                        destination: newValue ? newValue.destination : "",
                        pointOfLoading: newValue ? newValue.portOfLoading : "",
                        pointOfUnloading: newValue ? newValue.portOfDischarge : "",
                      },
                    }));
                  
                    if (sameAsCustomerConsignee && newValue) {
                      const stakeholder = newValue?.salesOrder?.offer?.stakeholder;
                      if (stakeholder) {
                        setSelectedConsignee(stakeholder);
                        setEditShippingInstruction((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            consignee: stakeholder || null,
                          },
                        }));
                      } else {
                        setSelectedConsignee(null);
                        setEditShippingInstruction((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            consignee: null,
                          },
                        }));
                      }
                    }
                  
                    if (sameAsCustomerNotify && newValue) {
                      const stakeholder = newValue?.salesOrder?.offer?.stakeholder;
                      if (stakeholder) {
                        setSelectedNotify(stakeholder);
                        setEditShippingInstruction((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            notify: stakeholder || null,
                          },
                        }));
                      } else {
                        setSelectedNotify(null);
                        setEditShippingInstruction((prevState) => ({
                          ...prevState,
                          data: {
                            ...prevState.data,
                            notify: null,
                          },
                        }));
                      }
                    }
                  }}
                   onOpen={retrieveShipmentRequests}
                   renderInput={(params) => (
                     <TextField
                       {...params}
                       variant="outlined"
                       size="small"
                       fullWidth
                       label={`${selectedShipmentRequest?.id || ""}`}
                     />
                   )}
                   renderTags={() => null}
                 />
                 {selectedShipmentRequest && (
                   <Box mt={2}>
                     <Chip
                       label={`ID: ${selectedShipmentRequest?.id || ""}`}
                       onDelete={() => {
                        setSelectedShipmentRequest(null);
                        setEditShippingInstruction({
                         ...editShippingInstruction,
                         data: {
                           ...editShippingInstruction.data,
                           shipmentRequest: {},
                           destination:"",
                           pointOfLoading: "",
                           pointOfUnloading: "",
                         },
                       });
                      }}
                       style={{ marginBottom: '5px' }}
                     /> <br></br>
                       <Chip
                  label={`Type: ${
                    selectedShipmentRequest?.shipmentRequestType || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                  label={`Destination: ${
                    selectedShipmentRequest?.destination || ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                     <Chip
                     label={`SAP PO Number: ${selectedShipmentRequest?.purchaseOrder?.purchaseOrderNumber || ""}`}
                     style={{ marginBottom: "5px" }}
                    /><br></br>
                     <Chip
                     label={`Supplier: ${selectedShipmentRequest?.purchaseOrder?.offer?.stakeholder?.name || ""}`}
                     style={{ marginBottom: "5px" }}
                    /><br></br>
                    <Chip
                     label={`SAP SO Number: ${selectedShipmentRequest?.salesOrder?.salesOrderNumber || ""}`}
                     style={{ marginBottom: "5px" }}
                    /><br></br>
                    <Chip
                     label={`Customer: ${selectedShipmentRequest?.salesOrder?.offer?.stakeholder?.name || ""}`}
                     style={{ marginBottom: "5px" }}
                    /><br></br>
                    <Chip
                     label={`Origin: ${selectedShipmentRequest?.purchaseOrder?.offer?.item?.origin || ""}`}
                     style={{ marginBottom: "5px" }}
                    /><br></br>
                    <Chip
                     label={`Buying Grade: ${selectedShipmentRequest?.purchaseOrder?.offer?.item?.buyingGrade || ""}`}
                     style={{ marginBottom: "5px" }}
                    /><br></br>
                     <Chip
                     label={`Status: ${selectedShipmentRequest?.status || ""}`}
                     style={{ marginBottom: "5px" }}
                    /><br></br>
                    <Chip
                     label={`Assigned To: ${selectedShipmentRequest?.assignedUser?.name || ""}`}
                     style={{ marginBottom: "5px" }}
                    /><br></br>
                   </Box>
                 )}
               </Grid>

               <Grid item xs={12} sm={1}>
              <Typography variant="body2">SI Type</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-shipping-instruction-type"
                select
                value={editShippingInstruction?.data?.shippingInstructionType}
                onChange={(e) => {
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      shippingInstructionType: e.target.value,
                    },
                  });
                  setIsSiNumberRetrieved(false);
                }}
              >
                {shippingInstructionTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

               <Grid item xs={12} sm={1}>
              <Typography variant="body2">SI Number</Typography>
              {isSiNumberRetrieved ? (
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                value={editShippingInstruction?.data?.shippingInstructionNumber}
                onChange={(e) => {
                  const siNumberValue = parseInt(e.target.value, 10);
                  setSiNumber(siNumberValue);
                  const currentYear = new Date().getFullYear();
                  const siType = editShippingInstruction?.data?.shippingInstructionType || '';
                  const calculatedNumber = ((currentYear % 10) * getDigit(siType)) + (siNumberValue);
                  const finalShippingInstructionNumber = `${siType}${calculatedNumber}`;
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      shippingInstructionNumber: siNumberValue,
                    finalShippingInstructionNumber: finalShippingInstructionNumber,
                    },
                  })
                }}
              />  ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleRetrieveSiNumber}
                >
                  Get Number
                </Button>
              )}
            </Grid>

            <Grid item xs={12} sm={2}>
            <Typography variant="body2">SI Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editShippingInstruction?.data?.shippingInstructionDate
                  ? format(
                      parseISO(editShippingInstruction.data.shippingInstructionDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) => {
                setEditShippingInstruction({
                  ...editShippingInstruction,
                  data: {
                    ...editShippingInstruction.data,
                    shippingInstructionDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
              <Typography variant="body2">Status</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                value={editShippingInstruction?.data?.status}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      status: e.target.value,
                    },
                  })
                }
              >
                {shippingInstructionStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Date Of Loading</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editShippingInstruction?.data?.dateOfLoading
                  ? format(
                      parseISO(editShippingInstruction.data.dateOfLoading),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
              <Typography variant="body2">Date Of Loading Remark</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.dateOfLoadingRemark}
                onChange={(e) => {
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      dateOfLoadingRemark: e.target.value,
                    },
                  })
                }}
              />
            </Grid>
            {((selectedShipmentRequest?.shipmentRequestType==="FVTW") || (selectedShipmentRequest?.shipmentRequestType==="FVTPS") || (selectedShipmentRequest?.shipmentRequestType==="FVTPNS") ||
          (selectedShipmentRequest?.shipmentRequestType==="FWTW")||(selectedShipmentRequest?.shipmentRequestType==="FWTPS")||(selectedShipmentRequest?.shipmentRequestType==="FWTPNS")) && (
            <Grid item xs={12} sm={2}>
            <Typography variant="body2">Destination</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-destination"
              select
              value={editShippingInstruction?.data?.destination || ""}
              label={editShippingInstruction?.data?.destination || ""}
              onChange={(e) => {
                setEditShippingInstruction({
                  ...editShippingInstruction,
                  data: {
                    ...editShippingInstruction.data,
                    destination: e.target.value,
                  },
                })
              }}
            >
              {warehouses.map((option) => (
                <MenuItem key={option.value} value={option.label}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          )}
          
            {((selectedShipmentRequest?.shipmentRequestType==="FVTC")||(selectedShipmentRequest?.shipmentRequestType==="FWTC")) && (
                <Grid item xs={12} sm={2}>
                <Typography variant="body2">Destination</Typography>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={editShippingInstruction?.data?.destination || ""}
                  onChange={(e) => {
                    setEditShippingInstruction({
                      ...editShippingInstruction,
                      data: {
                        ...editShippingInstruction.data,
                        destination: e.target.value,
                      },
                    })
                  }}
                />
               </Grid>
            )}
          <Grid item xs={12} sm={2}>
                 <Typography variant="body2">Freight Offer</Typography>
                 <Autocomplete
                   options={freightOffers || []}
                   getOptionLabel={(option) => {
                    const values = [
                      option?.id,
                      option?.forwarder,
                      option?.pointOfLoading,
                      option?.pointOfDischarge,
                      option?.fortyFtRate ? `40Ft: ${option.fortyFtRate} ${option?.fortyFtCurrency || ""}` : null,
                      option?.twentyFtRate ? `20Ft: ${option.twentyFtRate} ${option?.twentyFtCurrency || ""}` : null, 
                      option?.transitTime ? `Transit: ${option.transitTime}` : null,
                      option?.validTo ? `Valid To: ${option.validTo ? format(new Date(option?.validTo), "dd/MM/yyyy")
                        : ""}` : null,
                        option?.remark,
                    ].filter(value => value); 
          
                    return values.join(" - ");
                  }}
                   value={selectedFreightOffer}
                   onChange={(event, newValue) => {
                    setSelectedFreightOffer(newValue);
                    setEditShippingInstruction({
                       ...editShippingInstruction,
                       data:{
                        ...editShippingInstruction.data,
                        freightOffer: newValue || {},
                        transportationContact: newValue ? (newValue.forwarder || "") : "",
                       }
                     });
                   }}
                   onOpen={retrieveFreightOffers}
                   renderInput={(params) => (
                     <TextField
                       {...params}
                       variant="outlined"
                       size="small"
                       fullWidth
                       label={`${selectedFreightOffer?.id || ""}`}
                     />
                   )}
                   renderTags={() => null}
                 />
                 {selectedFreightOffer && (
                   <Box mt={2}>
                     <Chip
                       label={`ID: ${selectedFreightOffer?.id || ""}`}
                       onDelete={() => {
                        setSelectedFreightOffer(null);
                        setEditShippingInstruction({
                         ...editShippingInstruction,
                         data: {
                           ...editShippingInstruction.data,
                           freightOffer: {},
                         },
                       });
                      }}
                       style={{ marginBottom: '5px' }}
                     /> <br></br>
                     <Chip
                       label={`Forwarder: ${selectedFreightOffer?.forwarder || ""}`}
                       style={{ marginBottom: "5px" }}
                     /><br></br>
                     <Chip
                       label={`Point Of Loading: ${selectedFreightOffer?.pointOfLoading || ""}`}
                       style={{ marginBottom: "5px" }}
                     /><br></br>
                     <Chip
                       label={`Point Of Discharge: ${selectedFreightOffer?.pointOfDischarge || ""}`}
                       style={{ marginBottom: "5px" }}
                     /><br></br>
                     <Chip
                       label={`40 Ft Rate: ${selectedFreightOffer?.fortyFtRate || ""} ${selectedFreightOffer?.fortyFtCurrency || ""}`}
                       style={{ marginBottom: "5px" }}
                     /><br></br>
                     <Chip
                       label={`20 Ft Rate: ${selectedFreightOffer?.twentyFtRate || ""} ${selectedFreightOffer?.twentyFtCurrency || ""}`}
                       style={{ marginBottom: "5px" }}
                     /><br></br>
                     <Chip
                       label={`Transit Time: ${selectedFreightOffer?.transitTime || ""}`}
                       style={{ marginBottom: "5px" }}
                     /><br></br>
                                     <Chip
                  label={`Valid To: ${
                    selectedFreightOffer?.validTo ? format(new Date(selectedFreightOffer?.validTo), "dd/MM/yyyy")
                    : ""
                  }`}
                  style={{ marginBottom: "5px" }}
                />
                <br></br>
                <Chip
                       label={`Remark: ${selectedFreightOffer?.remark || ""}`}
                       style={{ marginBottom: "5px" }}
                     /><br></br>
                   </Box>
                 )}
               </Grid>

               <Grid item xs={12} sm={2}>
              <Typography variant="body2">Contact For Transportation</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.transportationContact}
                onChange={(e) => {
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      transportationContact: e.target.value,
                    },
                  })
                }}
              />
            </Grid>
            
               <Grid item xs={12} sm={2}>
              <Typography variant="body2">Freight Offer Remark</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-freight-offer-remark"
                select
                value={editShippingInstruction?.data?.freightRemark}
                onChange={(e) => {
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      freightRemark: e.target.value,
                    },
                  })
                }}
              >
                {freightOfferRemarks.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            </Grid>
          </Stack>
          <Stack direcion="column">
          <Grid container xs={12} spacing="8px">

                         <Grid item xs={12} sm={2}>
              <Typography variant="body2">Carton Range</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.cartonNumber}
                onChange={(e) => {
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      cartonNumber: e.target.value,
                    },
                  })
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
            <Typography variant="body2">Number Of Cartons</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={selectedShipmentRequest?.numberOfCases || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Net Weight Per Carton</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={selectedShipmentRequest?.netWeightPerCase || ""}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipment Quantity</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={selectedShipmentRequest?.shipmentQuantity
                ? `${new Intl.NumberFormat("de-DE", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                  }).format(selectedShipmentRequest.shipmentQuantity)} ${
                    selectedShipmentRequest?.shipmentQuantityUnit
                  }`
                : ""}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={2}>
              <Typography variant="body2">Gross Weight</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                type="number"
                value={editShippingInstruction?.data?.grossWeight}
                onChange={(e) => {
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      grossWeight: e.target.value,
                    },
                  })
                }}
              />
            </Grid>

          </Grid>
          </Stack>
          <Stack direcion="column">
          <Grid container xs={12} spacing="8px">

            </Grid>
            </Stack>
            <Stack direcion="column" style={{marginBottom:"10px", marginTop:"10px"}}>
          <Grid container item xs={12} spacing="8px">
            <Grid item xs={12} sm={2}>
            <Typography variant="body2">To Be Shipped By</Typography>
            <TextField
              id="outlined-select-shipped-by"
              select
              variant="outlined"
              size="small"
              value={editShippingInstruction?.data?.shippedBy}
              fullWidth
              onChange={(e) => {
                setEditShippingInstruction({
                  ...editShippingInstruction,
                  shippedBy: e.target.value,
                })
              }}
            >
              {shippedBy.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={2}>
                 <Typography variant="body2">Shipper</Typography>
                 <Autocomplete
                   options={shippers || []}
                   getOptionLabel={(option) =>
                     `${option?.name || ""}`
                   }
                   value={selectedShipper}
                   onChange={(event, newValue) => {
                    setSelectedShipper(newValue);
                    setEditShippingInstruction({
                       ...editShippingInstruction,
                       data:{
                        ...editShippingInstruction.data,
                        shipper: newValue || {}
                       }
                     });
                   }}
                   onOpen={() => retrieveStakeholders("SHIPPER")}
                   renderInput={(params) => (
                     <TextField
                       {...params}
                       variant="outlined"
                       size="small"
                       fullWidth
                       label={`${selectedShipper?.id || ""} ${selectedShipper?.name || ""}`}
                     />
                   )}
                   renderTags={() => null}
                 />
                 {selectedShipper && (
                   <Box mt={2}>
                     <Chip
                       label={`ID: ${selectedShipper?.id || ""}`}
                       onDelete={() => {
                        setSelectedShipper(null);
                        setEditShippingInstruction({
                         ...editShippingInstruction,
                         data: {
                           ...editShippingInstruction.data,
                           shipper: {},
                         },
                       });
                      }}
                       style={{ marginBottom: '5px' }}
                     /> <br></br>
                     <Chip
                       label={`Shipper Name: ${selectedShipper.name || ""}`}
                       style={{ marginBottom: '5px' }}
                     />
                     <Chip
                       label={`SAP Code: ${selectedShipper.code || ""}`}
                       style={{ marginBottom: '5px' }}
                     />
                   </Box>
                 )}
               </Grid>

               <Grid item xs={12} sm={1}>
              <Typography variant="body2">20"</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.toBeShippedBy20}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      toBeShippedBy20: e.target.value,
                    },
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography variant="body2">40"</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.toBeShippedBy40}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      toBeShippedBy40: e.target.value,
                    },
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography variant="body2">40HC"</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.toBeShippedBy40HC}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      toBeShippedBy40HC: e.target.value,
                    },
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={1}>
              <Typography variant="body2">FTL</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.toBeShippedByFtl}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      toBeShippedByFtl: e.target.value,
                    },
                  })
                }
              />
            </Grid>

            </Grid>
            </Stack>
            <Stack direcion="column">
          <Grid container item xs={12} spacing="8px">
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Bill Of Lading Number</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.billOfLadingNumber}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      billOfLadingNumber: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
            <Typography variant="body2">Bill Of Lading Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editShippingInstruction?.data?.billOfLadingDate
                  ? format(
                      parseISO(editShippingInstruction.data.billOfLadingDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditShippingInstruction({
                  ...editShippingInstruction,
                  data: {
                    ...editShippingInstruction.data,
                    billOfLadingDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
              <Typography variant="body2">CMR Number</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.cmrNumber}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      cmrNumber: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
            <Typography variant="body2">CMR Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editShippingInstruction?.data?.cmrDate
                  ? format(
                      parseISO(editShippingInstruction.data.cmrDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditShippingInstruction({
                  ...editShippingInstruction,
                  data: {
                    ...editShippingInstruction.data,
                    cmrDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          </Grid>
            </Stack>
            <Stack direcion="column" style={{marginBottom:"10px", marginTop:"10px"}}>
          <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETD</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editShippingInstruction?.data?.estimatedTimeOfDeparture
                  ? format(
                      parseISO(editShippingInstruction.data.estimatedTimeOfDeparture),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditShippingInstruction({
                  ...editShippingInstruction,
                  data: {
                    ...editShippingInstruction.data,
                    estimatedTimeOfDeparture: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          {showSecondEtdUpdate && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">ETD 2nd Update</Typography>
              <TextField
                id="etd-2nd-update"
                type="date"
                inputProps={{ min: '1999-01-01', max: '2999-01-01' }}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={(e) => e.preventDefault()}
                variant="outlined"
                size="small"
                fullWidth
                value={
                  editShippingInstruction?.data?.secondEstimatedTimeOfDeparture
                    ? format(parseISO(editShippingInstruction.data.secondEstimatedTimeOfDeparture), 'yyyy-MM-dd')
                    : ''
                }
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      secondEstimatedTimeOfDeparture: e.target.value ? e.target.value + 'T00:00:00.000+00:00' : null,
                    },
                  })
                }
              />
            </Grid>
          )}

{showThirdEtdUpdate && (
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">ETD 3rd Update</Typography>
              <TextField
                id="etd-3rd-update"
                type="date"
                inputProps={{ min: '1999-01-01', max: '2999-01-01' }}
                InputLabelProps={{
                  shrink: true,
                }}
                onKeyDown={(e) => e.preventDefault()}
                variant="outlined"
                size="small"
                fullWidth
                value={
                  editShippingInstruction?.data?.thirdEstimatedTimeOfDeparture
                    ? format(parseISO(editShippingInstruction.data.thirdEstimatedTimeOfDeparture), 'yyyy-MM-dd')
                    : ''
                }
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      thirdEstimatedTimeOfDeparture: e.target.value ? e.target.value + 'T00:00:00.000+00:00' : null,
                    },
                  })
                }
              />
            </Grid>
          )}

          <Grid item xs={12} sm={2}>
        <Button variant="contained" onClick={() =>handleUpdateTime("ETD")} style={{ marginTop: '20px' }}>
          Update ETD
        </Button>
        </Grid>
          </Grid>
            </Stack>
            <Stack direcion="column">
          <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETA</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editShippingInstruction?.data?.estimatedTimeOfArrival
                  ? format(
                      parseISO(editShippingInstruction.data.estimatedTimeOfArrival),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditShippingInstruction({
                  ...editShippingInstruction,
                  data: {
                    ...editShippingInstruction.data,
                    estimatedTimeOfArrival: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          {showSecondEtaUpdate && (
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETA 2nd Update</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editShippingInstruction?.data?.secondEstimatedTimeOfArrival
                  ? format(
                      parseISO(editShippingInstruction.data.secondEstimatedTimeOfArrival),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditShippingInstruction({
                  ...editShippingInstruction,
                  data: {
                    ...editShippingInstruction.data,
                    secondEstimatedTimeOfArrival: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          )}

      {showThirdEtaUpdate && (
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ETA 3rd Update</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editShippingInstruction?.data?.thirdEstimatedTimeOfArrival
                  ? format(
                      parseISO(editShippingInstruction.data.thirdEstimatedTimeOfArrival),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditShippingInstruction({
                  ...editShippingInstruction,
                  data: {
                    ...editShippingInstruction.data,
                    thirdEstimatedTimeOfArrival: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
      )}
         <Grid item xs={12} sm={2}>
        <Button variant="contained" onClick={() =>handleUpdateTime("ETA")} style={{ marginTop: '20px' }}>
          Update ETA
        </Button>
        </Grid>

         </Grid>
            </Stack>
            <Stack direcion="column">
          <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">ATA</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              disabled={editShippingInstruction?.data?.actualTimeOfArrival===null}
              value={
                editShippingInstruction?.data?.actualTimeOfArrival
                  ? format(
                      parseISO(editShippingInstruction.data.actualTimeOfArrival),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditShippingInstruction({
                  ...editShippingInstruction,
                  data: {
                    ...editShippingInstruction.data,
                    actualTimeOfArrival: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          </Grid>
            </Stack>
            <Stack direcion="column">
          <Grid container item xs={12} spacing="8px">
            
          <Grid item xs={12} sm={2}>
                 <Typography variant="body2">Consignee</Typography>
                 <Autocomplete
                   options={consignees || []}
                   getOptionLabel={(option) =>
                     `${option?.name || ""}`
                   }
                   value={selectedConsignee}
                   onChange={(event, newValue) => {
                    setSelectedConsignee(newValue);
                    setEditShippingInstruction({
                       ...editShippingInstruction,
                       data:{
                        ...editShippingInstruction.data,
                        consignee: newValue || {}
                       }
                     });
                   }}
                   onOpen={() => retrieveStakeholders("CONSIGNEE")}
                   renderInput={(params) => (
                     <TextField
                       {...params}
                       variant="outlined"
                       size="small"
                       fullWidth
                       label={`${selectedConsignee?.id || ""} ${selectedConsignee?.name || ""}`}
                     />
                   )}
                   renderTags={() => null}
                 />
                 {selectedConsignee && (
                   <Box mt={2}>
                     <Chip
                       label={`ID: ${selectedConsignee?.id || ""}`}
                       onDelete={() => {
                        setSelectedConsignee(null);
                        setEditShippingInstruction({
                         ...editShippingInstruction,
                         data: {
                           ...editShippingInstruction.data,
                           consignee: {},
                         },
                       });
                      }}
                       style={{ marginBottom: '5px' }}
                     /> <br></br>
                     <Chip
                       label={`SAP Code: ${selectedConsignee.code || ""}`}
                       style={{ marginBottom: '5px' }}
                     /><br></br>
                     <Chip
                       label={`Reference Customer: ${selectedConsignee?.reference || ""}`}
                       style={{ marginBottom: "5px" }}
                     />
                     <br></br>
                   </Box>
                 )}
                 <FormControlLabel
        control={
          <Checkbox
            checked={sameAsCustomerConsignee}
            onChange={handleConsigneeCheckboxChange}
            color="primary"
          />
        }
        label="Same As Customer"
      />
               </Grid>

               <Grid item xs={12} sm={2}>
                 <Typography variant="body2">Notify Party(ies)</Typography>
                 <Autocomplete
                   options={notifies || []}
                   getOptionLabel={(option) =>
                     `${option?.name || ""}`
                   }
                   value={selectedNotify}
                   onChange={(event, newValue) => {
                    setSelectedNotify(newValue);
                    setEditShippingInstruction({
                       ...editShippingInstruction,
                       data:{
                        ...editShippingInstruction.data,
                        notify: newValue || {}
                       }
                     });
                   }}
                   onOpen={() => retrieveStakeholders("NOTIFY")}
                   renderInput={(params) => (
                     <TextField
                       {...params}
                       variant="outlined"
                       size="small"
                       fullWidth
                       label={`${selectedNotify?.id || ""} ${selectedNotify?.name || ""}`}
                     />
                   )}
                   renderTags={() => null}
                 />
                 {selectedNotify && (
                   <Box mt={2}>
                     <Chip
                       label={`ID: ${selectedNotify?.id || ""}`}
                       onDelete={() => {
                        setSelectedNotify(null);
                        setEditShippingInstruction({
                         ...editShippingInstruction,
                         data: {
                           ...editShippingInstruction.data,
                           notify: {},
                         },
                       });
                      }}
                       style={{ marginBottom: '5px' }}
                     /> <br></br>
                     <Chip
                       label={`Notify Party Name: ${selectedNotify.name || ""}`}
                       style={{ marginBottom: '5px' }}
                     /> <br></br>
                     <Chip
                       label={`SAP Code: ${selectedNotify.code || ""}`}
                       style={{ marginBottom: '5px' }}
                     />
                   </Box>
                 )}
                 <FormControlLabel
        control={
          <Checkbox
            checked={sameAsCustomerNotify}
            onChange={handleNotifyCheckboxChange}
            color="primary"
          />
        }
        label="Same As Customer"
      />
               </Grid>

               <Grid item xs={12} sm={2}>
              <Typography variant="body2">Point Of Loading</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.pointOfLoading}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      pointOfLoading: e.target.value,
                    },
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Point Of Unloading</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.pointOfUnloading}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      pointOfUnloading: e.target.value,
                    },
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Description</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.description}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      description: e.target.value,
                    },
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Revision</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.revision}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      revision: e.target.value,
                    },
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Remark</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.remark}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      remark: e.target.value,
                    },
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">SI Link</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editShippingInstruction?.data?.link}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      link: e.target.value,
                    },
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Record Status</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                value={editShippingInstruction?.data?.recordStatus}
                onChange={(e) =>
                  setEditShippingInstruction({
                    ...editShippingInstruction,
                    data: {
                      ...editShippingInstruction.data,
                      recordStatus: e.target.value,
                    },
                  })
                }
              >
                {recordStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1} mt={1}>
            <Button
              variant="outlined"
              onClick={() =>
                setEditShippingInstruction({ visible: false, data: null, index: null })
              }
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                updateShippingInstructionApi(editShippingInstruction.data.id, editShippingInstruction.data)
                  .then((response) => {
                    toast.success("ShippingInstruction edited successfully.");
                    window.location.reload();
  
                    setEditShippingInstruction({ visible: false, data: null, index: null });
                  })
                  .catch((error) => {
                    toast.error("Something went wrong.");
                    console.log(error.response.data);
                  });
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                editShippingInstruction.data.recordStatus = "PASSIVE";
                updateShippingInstructionApi(editShippingInstruction.data.id, editShippingInstruction.data)
                  .then((response) => {
                    toast.success("ShippingInstruction deleted successfully.");
                    window.location.reload();
                    setEditShippingInstruction({ visible: false, data: null, index: null });
                  })
                  .catch((error) => {
                    toast.error("Something went wrong.");
                    console.log(error.response.data);
                  });
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </>
    );
  };
  
  export default EditShippingInstruction;