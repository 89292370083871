import { Box } from "@mui/material";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { activeItem } from "../../../../../store/reducers/menu";
import NavItem from "./NavItem";

const NavAccordion = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { drawerOpen } = useSelector((state) => state.menu);

  useEffect(() => {
    if (pathname.includes(item.url)) {
      dispatch(activeItem({ openItem: [item.id] }));
    }
    // eslint-disable-next-line
  }, [pathname]);

  const iconSelectedColor = "primary.main";

  return (
    <Box
      sx={{
        zIndex: 1201,
        pl: 1,
        ...(drawerOpen && {
          "&.Mui-selected": {
            bgcolor: "primary.lighter",
            borderRight: `2px solid ${theme.palette.primary.main}`,
            color: iconSelectedColor,
            "&:hover": {
              color: iconSelectedColor,
              bgcolor: "primary.lighter",
            },
          },
        }),
      }}
    >
      <Accordion
        sx={{
          boxShadow: "none !important",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ margin: 0, minHeight: "32px !important" }}
        >
          <Typography
            fontSize="0.75rem"
            fontWeight={"500"}
            lineHeight={1.66}
            color={"#8c8c8c"}
          >
            {item.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {item.children.map((i) => {
            switch (i.type) {
              case "item":
                return <NavItem key={i.id} item={i} level={1} />;

              default:
                return (
                  <Typography
                    key={i.id}
                    variant="h6"
                    color="error"
                    align="center"
                  >
                    Fix - Group Collapse or Items
                  </Typography>
                );
            }
          })}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default NavAccordion;
