import { apiClient } from "./ApiClient";

export const retrieveAllInventoryApi = () =>
  apiClient.get(`/inventory-view/inventory`);

export const createInventoryApi = (inventory) =>
  apiClient.post(`/inventory-view/add`, inventory);

export const updateInventoryApi = (inventoryId, inventory) =>
  apiClient.put(`/inventory-view/edit/${inventoryId}`, inventory);

export const retrieveFilteredInventoryApi = (inventory) =>
  apiClient.post(`/inventory-view/filter`, inventory);
