import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
import { updateRecordApi } from "../../../components/api/TradeApiService";
import { recordStatus } from "../../../utils/menu-items";

const EditTrade = (props) => {
  const { editTrade, setEditTrade } = props;
  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Trade Record: {editTrade?.data?.id}
        </Typography>
        <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Operation Type</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editTrade?.data?.operationType}
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    operationType: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Order</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editTrade?.data?.order?.id || ""}
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    order: {
                      ...(editTrade.data.order || {}),
                      id:
                        e.target.value !== ""
                          ? parseInt(e.target.value, 10)
                          : null,
                    },
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Invoice Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editTrade?.data?.invoiceDate
                  ? format(parseISO(editTrade.data.invoiceDate), "yyyy-MM-dd")
                  : ""
              }
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    invoiceDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SI Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editTrade?.data?.shippingInstructionId}
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    shippingInstructionId: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">BL Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editTrade?.data?.billOfLadingNumber}
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    billOfLadingNumber: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">BL Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editTrade?.data?.billOfLadingDate
                  ? format(
                      parseISO(editTrade.data.billOfLadingDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    billOfLadingDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipment Net Kg</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editTrade?.data?.shipmentNetKg}
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    shipmentNetKg: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Destination</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editTrade?.data?.shipmentDestination}
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    shipmentDestination: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipment Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editTrade?.data?.shipmentDate
                  ? format(parseISO(editTrade.data.shipmentDate), "yyyy-MM-dd")
                  : ""
              }
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    shipmentDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Quantity Remaining (Case)</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editTrade?.data?.quantityRemainingInNetCase}
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    quantityRemainingInNetCase: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Quantity Remaining (Kg)</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editTrade?.data?.quantityRemainingInNetKg}
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    quantityRemainingInNetKg: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editTrade?.data?.remark}
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    remark: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editTrade?.data?.recordStatus}
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    recordStatus: e.target.value,
                  },
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Edit Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editTrade?.data?.editingPermission}
              onChange={(e) =>
                setEditTrade({
                  ...editTrade,
                  data: {
                    ...editTrade.data,
                    editingPermission: e.target.value,
                  },
                })
              }
            />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setEditTrade({ visible: false, data: null, index: null })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              updateRecordApi(editTrade.data.id, editTrade.data)
                .then((response) => {
                  window.location.reload();

                  setEditTrade({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  console.log(error.response.data);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              editTrade.data.recordStatus = "PASSIVE";
              updateRecordApi(editTrade.data.id, editTrade.data)
                .then((response) => {
                  window.location.reload();
                  setEditTrade({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  console.log(error.response.data);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default EditTrade;
