import { apiClient } from "./ApiClient";

export const createShipmentRequestApi = (shipmentRequest) =>
  apiClient.post(`/shipment-view/shipment-request/add`, shipmentRequest);

export const retrieveAllShipmentRequestsApi = () =>
  apiClient.get(`/shipment-view/shipment-requests`);
  
export const retrieveFilteredShipmentRequestsApi = (shipmentRequest) =>
  apiClient.post(`/shipment-view/shipment-requests/filter`, shipmentRequest);

export const approveShipmentRequestApi = (shipmentRequestId, shipmentRequest) =>
  apiClient.put(`/shipment-view/shipment-request/approve/${shipmentRequestId}`, shipmentRequest);

export const updateShipmentRequestApi = (shipmentRequestId, shipmentRequest) =>
  apiClient.put(`/shipment-view/shipment-request/edit/${shipmentRequestId}`, shipmentRequest);

export const retrieveShipmentRequestByIdApi = (shipmentRequestId) => apiClient.get(`/shipment-view/shipment-requests/get/${shipmentRequestId}`);
