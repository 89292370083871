import {
    Autocomplete,
    Box,
    Button,
    Chip,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import { format, parseISO } from "date-fns";
  import React, { useState } from "react";
  import { eurUsdExchangeApi, updateAddressApi } from "../../../components/api/AddressApiService";
  import { currencies, incoterms, status, units, recordStatus, addressTypes, countries } from "../../../utils/menu-items";
  import toast from "react-hot-toast";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
  
  const EditAddress = (props) => {
    const { editAddress, setEditAddress } = props;
    const [stakeholders, setStakeholders] = useState([]);
    const initialAddress = countries.find(
        (term) => term.label === editAddress?.data?.country,
      );
    const [selectedCountry, setSelectedCountry] = useState(
        initialAddress || null,
      );
    const [selectedStakeholder, setSelectedStakeholder] = useState(
        editAddress?.data?.stakeholder || "",
      );
      function retrieveStakeholders() {
        Promise.all([
          retrieveAllStakeholdersApi("VENDOR"),
          retrieveAllStakeholdersApi("CUSTOMER"),
          retrieveAllStakeholdersApi("SHIPPER"),
          retrieveAllStakeholdersApi("CONSIGNEE"),
        ])
          .then(([vendorResponse, customerResponse, shipperResponse, consigneeResponse]) => {
            setStakeholders([
              ...vendorResponse?.data,
              ...customerResponse?.data,
              ...shipperResponse?.data,
              ...consigneeResponse?.data,
            ]);
          })
          .catch((error) => console.log(error.response?.data));
      }
    
    return (
      <>
        <Stack direcion="column">
          <Typography variant="body1" mb={2}>
            Edit Address
          </Typography>
          <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
  <Typography variant="body2">Select Business Partner</Typography>
      <Autocomplete
        options={stakeholders}
        getOptionLabel={(option) =>
          `${option.id || ""} - ${option.type || ""} - ${option.name || ""}`
        }
        value={selectedStakeholder}
        onChange={(event, newValue) => {
          setSelectedStakeholder(newValue);
          setEditAddress({
            ...editAddress,
            data: {
              ...editAddress.data,
              stakeholder: {
                ...(editAddress.data.stakeholder || {}),
                id: newValue ? newValue.id : null,
              },
            },
          })
        }}
        onOpen={() => retrieveStakeholders()}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
        renderTags={() => null}
      />
      {selectedStakeholder && (
        <Box mt={2}>
          <Chip
            label={`ID: ${selectedStakeholder?.id || ""}`}
            style={{ marginBottom: '5px' }}
            onDelete={() => setSelectedStakeholder(null)}
          /><br />
          <Chip
            label={`Type: ${selectedStakeholder?.type || ""}`}
            style={{ marginBottom: '5px' }}
          /><br />
            <Chip
            label={`SAP Code: ${selectedStakeholder?.code || ""}`}
            style={{ marginBottom: '5px' }}
          /><br />
          <Chip
            label={`Name: ${selectedStakeholder?.name || ""}`}
            style={{ marginBottom: '5px' }}
          /><br />
        </Box>
      )}
</Grid>

            <Grid item xs={12} sm={1}>
              <Typography variant="body2">Address Type</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-address-type"
                select
                value={editAddress?.data?.type || ""}
                onChange={(e) =>
                  setEditAddress({
                    ...editAddress,
                    data: {
                      ...editAddress.data,
                      type: e.target.value,
                    },
                  })
                }
              >
                {addressTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={2}>
            <Typography variant="body2">Street</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editAddress?.data?.street}
              onChange={(e) =>
                setEditAddress({
                  ...editAddress,
                  data: {
                    ...editAddress.data,
                    street: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Zip Code</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editAddress?.data?.zipCode}
              onChange={(e) =>
                setEditAddress({
                  ...editAddress,
                  data: {
                    ...editAddress.data,
                    zipCode: e.target.value,
                  },
                })
              }
            />
          </Grid>
  
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">City</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editAddress?.data?.city}
              onChange={(e) =>
                setEditAddress({
                  ...editAddress,
                  data: {
                    ...editAddress.data,
                    city: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">County</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editAddress?.data?.county}
              onChange={(e) =>
                setEditAddress({
                  ...editAddress,
                  data: {
                    ...editAddress.data,
                    county: e.target.value,
                  },
                })
              }
            />
          </Grid> 

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Country</Typography>
            <Autocomplete
              options={countries}
              getOptionLabel={(option) => option?.label || ""}
              value={selectedCountry}
              onChange={(event, newValue) => {
                setSelectedCountry(newValue);
                setEditAddress({
                  ...editAddress,
                  data: {
                    ...editAddress.data,
                    country: newValue?.label || "",
                  },
                });
              }}
              isOptionEqualToValue={(option, value) =>
                option?.label === value?.label
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              )}
              renderTags={() => null}
            />
            {selectedCountry && (
              <Box mt={2}>
                <Chip
                  label={selectedCountry?.label}
                  onDelete={() => {
                    setSelectedCountry(null);
                    setEditAddress({
                      ...editAddress,
                      data: {
                        ...editAddress.data,
                        country: "",
                      },
                    });
                  }}
                />
              </Box>
            )}
          </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Remark</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editAddress?.data?.remark}
                onChange={(e) =>
                  setEditAddress({
                    ...editAddress,
                    data: {
                      ...editAddress.data,
                      remark: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Status</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-record-status"
                select
                value={editAddress?.data?.recordStatus}
                onChange={(e) =>
                  setEditAddress({
                    ...editAddress,
                    data: {
                      ...editAddress.data,
                      recordStatus: e.target.value,
                    },
                  })
                }
              >
                {recordStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1} mt={1}>
            <Button
              variant="outlined"
              onClick={() =>
                setEditAddress({ visible: false, data: null, index: null })
              }
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                updateAddressApi(editAddress.data.id, editAddress.data)
                  .then((response) => {
                    toast.success("Address edited successfully.");
                    window.location.reload();
  
                    setEditAddress({ visible: false, data: null, index: null });
                  })
                  .catch((error) => {
                    toast.error("Something went wrong.");
                    console.log(error.response.data);
                  });
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                editAddress.data.status = "PASSIVE";
                updateAddressApi(editAddress.data.id, editAddress.data)
                  .then((response) => {
                    toast.success("Address deleted successfully.");
                    window.location.reload();
                    setEditAddress({ visible: false, data: null, index: null });
                  })
                  .catch((error) => {
                    toast.error("Something went wrong.");
                    console.log(error.response.data);
                  });
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </>
    );
  };
  
  export default EditAddress;
  