import { Autocomplete, Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { format, parseISO } from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import eurLogo from "../../../../assets/images/logo/sanEurLogoJpeg.jpeg";
import intlLogo from "../../../../assets/images/logo/sanIntlLogoJpeg.jpeg";
import { color, companies, titles } from "../../../../utils/menu-items";
import { updateMultipleOrdersApi } from "../../../../components/api/OrderApiService";
import { retrieveFilteredAddressesApi } from "../../../../components/api/AddressApiService";

const convertImageToBase64 = (url, callback) => {
  const xhr = new XMLHttpRequest();
  xhr.onload = function () {
    const reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

const createExcelWithLogoAndData = async (orderDetails) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("My Sheet");
  worksheet.pageSetup.orientation = "landscape";
  const len = orderDetails?.items.length;
  const buyingCompanyHeader =
    orderDetails?.buyingCompany === "SGE"
      ? "SanGroup Europe Kft."
      : "SANGROUP INTERNATIONAL PTE.LTD.";
  const buyingAddressOne =
    orderDetails?.buyingCompany === "SGE"
      ? "Stefania ut 101-103"
      : "11 Irving Place #09-02";
  const buyingAddressTwo =
    orderDetails?.buyingCompany === "SGE"
      ? "H-1143 Budapest, Hungary"
      : "Tai Seng Point Singapore 369551";
  const buyingAddressThree =
    orderDetails?.buyingCompany === "SGE"
      ? "Registration no: 01-09-400244 VAT no: Hu27842588"
      : "Registration no: 201717395N";

  const totalNumberOfCases = orderDetails?.items.reduce((total, item) => {
    return total + (item?.numberOfCases || 0);
  }, 0);

  const totalNetWeight = orderDetails?.items.reduce((total, item) => {
    return total + (item?.volume || 0);
  }, 0);

  const totalTotalAmount = orderDetails?.items.reduce((total, item) => {
    return total + (item?.totalAmount || 0);
  }, 0);

  var logo;
  // Convert and add the intlLogo
  if (orderDetails.buyingCompany === "SGI") {
    logo = intlLogo;
  } else if (orderDetails.buyingCompany === "SGE") {
    logo = eurLogo;
  } else {
    logo = intlLogo;
  }
  convertImageToBase64(logo, async (base64Image) => {
    const imageId = workbook.addImage({
      base64: base64Image,
      extension: "jpeg",
    });

    worksheet.addImage(imageId, "A1:E4");

    worksheet.pageSetup.orientation = 'landscape';
    worksheet.pageSetup.printArea = `A1:P${27+len}`;

    worksheet.pageSetup.fitToPage = true;
    worksheet.pageSetup.fitToWidth = 1;
    worksheet.pageSetup.fitToHeight = 0;

    const startRow = 3;
    const headerRowNumber = startRow + 12; // Assuming header is the 7th row in dataForExcel

    // Styling for headers
    const headerFill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF4F81BD" }, // Change color as needed
    };

    // Data for the document (your structured data goes here)
    const dataForExcel = [
      [, , , , , , , , , , "PURCHASE ORDER"],
      [],
      [, buyingCompanyHeader, , , , , , , , , "No :", orderDetails?.poNumber],
      [, buyingAddressOne, , , , , , , , , "Date :", orderDetails?.poDate
      ? format(
          parseISO(orderDetails.poDate),
          "dd/MMM/yyyy",
        )
      : "",],
      [, buyingAddressTwo],
      [, buyingAddressThree],
      [],
      ["To: ", orderDetails?.customerName ,,,,,,,"Payment Terms:",,, orderDetails?.paymentTerm,,],
      [,orderDetails?.companyAddressOne,,,,,,,,,,],
      [,orderDetails?.companyAddressTwo ,,,,,,,,,,],
      ["Attn:" ,orderDetails?.attn ,,,,,,, "Incoterms:",,,orderDetails?.incoterm, orderDetails?.portOfLoading,,],
      ["Please find below the details:"],
      [
        "#",
        "Origin",
        "Crop",
        "Grade",
        "Type",
        "Leaf Form",
        "ETD",
        "Nic %",
        "Sugar %",
        "Moisture %",
        "KG/Car",
        "# of Car",
        "Net Weight (KG)",
        "Price ("+orderDetails?.currency+")",
        "Total ("+orderDetails?.currency+")",
      ],
      ...orderDetails?.items.map((item, index) => [
        index + 1,
        item?.origin, // Assuming 'year' is a number, convert to string
        item?.crop,
        item?.buyingGrade,
        item?.type,
        item?.leafForm,
        item?.estimatedTimeOfDeparture, // Convert numbers to strings if necessary
        item?.nicotine,
        item?.sugar,
        item?.moisture,
        item?.netWeightPerCase,
        item?.numberOfCases,
        item?.volume ? new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(item.volume)
        : '',
        item?.finalPrice,
        item?.totalAmount  ? new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(item.totalAmount)
        : '',
      ]),
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "TOTALS:",
        totalNumberOfCases,
        totalNetWeight? new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(totalNetWeight)
        : '',
        "",
        totalTotalAmount? new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(totalTotalAmount)
        : '',
      ],
      [],
      ["Terms and Conditions:", , , orderDetails?.termsAndConoditionsOne],
      [, , , orderDetails?.termsAndConditionsTwo],
      [],
      ["Shipment Schedule:", , , orderDetails?.shipment],
      [],
      [],
      [, , , ,"Buyer", , , , , , , "Seller"],
      [, ,buyingCompanyHeader, , , , , , , , orderDetails?.customerName],
      [,
        "Date          : " +
        (orderDetails?.poDate
        ? format(
            parseISO(orderDetails.poDate),
            "dd/MMM/yyyy",
          )
        : ""),
        ,
        ,
        ,,
        ,
        ,
        "Date                   : " +
        (orderDetails?.poDate
        ? format(
            parseISO(orderDetails.poDate),
            "dd/MMM/yyyy",
          )
        : ""),
      ],
      [,"Stamp & Signature:", , , , , , , "Stamp & Signature:"],
      [],
      [],
      [],
      [],
      [],
    ];

    // Example: Inserting a row of data
    dataForExcel.forEach((row, index) => {
      const rowIndex = startRow + index;
      const excelRow = worksheet.getRow(rowIndex);
      row.forEach((cell, cellIndex) => {
        const cellAddress = excelRow.getCell(cellIndex + 1);
        cellAddress.value = cell;

        if (rowIndex === headerRowNumber) {
          // Apply header styles
          cellAddress.fill = headerFill;
          cellAddress.font = { bold: true, color: { argb: "FFFFFFFF" }, size: 9, };
          cellAddress.alignment = {
            wrapText: "true",
            vertical: "middle",
            horizontal: "center",
          };
          cellAddress.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        } else if (rowIndex === 3) {
          cellAddress.font = {
            bold: true,
            underline: true,
            size: 16,
            color: { argb: "34009A" },
          };
        } else if(
          rowIndex === 5 ||
          rowIndex === 6 ||
          rowIndex === 7 ||
          rowIndex === 8
        ) {
          cellAddress.font = {
            size: 9,
          };
          if (cellIndex === 10) {
            cellAddress.font = {
              size: 12,
              bold: true,
            };
          }
        } else if (rowIndex === 10) {
          excelRow.border = {
            top: { style: "medium", color: "black" },
          };
          if (cellIndex === 0) {
            cellAddress.font = {
              bold: true,
            };
          }else if(cellIndex === 8){

          }else{
            cellAddress.font = {
              size: 9,
            };
          }
        } else if (rowIndex === 13) {
          excelRow.border = {
            bottom: { style: "medium", color: "black" },
          };
          if (cellIndex === 0) {
            cellAddress.font = {
                  size: 9,
                };}
                else if (cellIndex === 8) {
                  }else{
                      cellAddress.font = {
                        size: 9,
                      };
                  }
          }else if (rowIndex > 15 && rowIndex <= 15 + len) {
            if (cellIndex === 0) {
                worksheet.getColumn(cellIndex + 1).width = 4;
              } else if (cellIndex === 6) {
                worksheet.getColumn(cellIndex + 1).width = 10;
              }else if (cellIndex === 2 || cellIndex === 7 || cellIndex === 8) {
                worksheet.getColumn(cellIndex + 1).width = 6;
              }else if (cellIndex === 9) {
                worksheet.getColumn(cellIndex + 1).width = 7;
              }else if (cellIndex === 10) {
                worksheet.getColumn(cellIndex + 1).width = 8;
              }else if (cellIndex === 11) {
                worksheet.getColumn(cellIndex + 1).width = 7;
              }else if (cellIndex === 12) {
                worksheet.getColumn(cellIndex + 1).width = 11;
              }else if (cellIndex === 13) {
                worksheet.getColumn(cellIndex + 1).width = 7;
              }else if (cellIndex === 14) {
                worksheet.getColumn(cellIndex + 1).width = 12;
              }

          cellAddress.border = {
            top: { style: "thin", color: { argb: "6e6e6e" } },
            left: { style: "thin", color: { argb: "6e6e6e" } },
            bottom: { style: "thin", color: { argb: "6e6e6e" } },
            right: { style: "thin", color: { argb: "6e6e6e" } },
          };
          cellAddress.alignment = {
            wrapText: "true",
            vertical: "middle",
            horizontal: "center",
          };
          cellAddress.font = {
            size: 9,
          };
        } else if (rowIndex === 16 + len) {
          if (cellIndex === 11 || cellIndex === 12 || cellIndex === 14) {
            cellAddress.border = {
              top: { style: "thin", color: { argb: "6e6e6e" } },
              left: { style: "thin", color: { argb: "6e6e6e" } },
              bottom: { style: "thin", color: { argb: "6e6e6e" } },
              right: { style: "thin", color: { argb: "6e6e6e" } },
            };
          }

          cellAddress.alignment = {
            wrapText: "true",
            vertical: "middle",
            horizontal: "center",
          };

          cellAddress.font = {
            bold: true,
          };
        } else if (rowIndex === 18 + len) {
          if (cellIndex === 0) {
            cellAddress.font = {
              bold: true,
            };
          }else{
            cellAddress.font = {
              size: 8,
            };
          }
        } else if (rowIndex === 21 + len) {
          if (cellIndex === 0) {
            cellAddress.font = {
              bold: true,
            };
          }else{
            cellAddress.font = {
              size: 8,
            };
          }
        } else if (rowIndex === 24 + len) {
          cellAddress.font = {
            bold: true,
            underline: true,
          };
        } else if (rowIndex === 26 + len || rowIndex === 27 + len) {
          if (cellIndex === 1 || cellIndex === 8) {
            cellAddress.font = {
              italic: true,
            };
          }
        }
      });
      excelRow.commit();
    });

    // Adjust column widths
    /*worksheet.columns.forEach((column, i) => {
        column.width = 10; // Adjust based on your content
      });*/

    // Generate and save the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "OfferWithLogo.xlsx");
  });
};


const ExportPurchaseOrder = (props) => {
  const { setExportPoPopup, exportPoPopup } = props;
  const[filteredAddresses, setFilteredAddresses] = useState([]);
  const[selectedAddress, setSelectedAddress] = useState(null);
  const [items, setItems] = useState(
    exportPoPopup && exportPoPopup.data
      ? exportPoPopup.data.map((order) => ({
          id: order?.offer?.item?.id || "",
          origin: order?.offer?.item?.origin || "",
          crop: order?.offer?.item?.crop || "",
          type: order?.offer?.item?.type || "",
          buyingGrade: order?.offer?.item?.buyingGrade || "",
          leafForm: order?.offer?.item?.leafForm || "",
          nicotine: order?.offer?.item?.nicotine || "",
          sugar: order?.offer?.item?.sugar || "",
          moisture: order?.offer?.item?.moisture || "",
          volume: order?.volume || "",
          price: order?.finalPrice || "",
          incoterm: order?.incoterm || "",
          estimatedTimeOfDeparture: order?.estimatedTimeOfDeparture
            ? new Date(order?.estimatedTimeOfDeparture).toLocaleDateString(
                "en-GB",
              )
            : "",
          numberOfCases: order?.numberOfCases || "",
          netWeightPerCase: order?.netWeightPerCase || "",
          finalPrice: order?.finalPrice || "",
          totalAmount: order?.totalAmount || "",
        }))
      : [],
  );
  
  const filterAddress = ({
    id: 0,
    stakeholderId: exportPoPopup?.data[0]?.offer?.stakeholder?.id,
    stakeholderIdList: [exportPoPopup?.data[0]?.offer?.stakeholder?.id],
    type: "",
    typeList: ["Pay To", "Bill To", "Ship To"],
    street: "",
    streetList: [],
    zipCode: "",
    zipCodeList: [],
    city: "",
    cityList: [],
    county: "",
    countyList: [],
    country: "",
    countryList: [],
    remark: "",
    recordStatusList: ["ACTIVE"],
  });
  
  function retrieveStakeholderAddresses(filterAddress) {
    retrieveFilteredAddressesApi(filterAddress)
      .then((response) => {
        setFilteredAddresses(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  useEffect(() => {
    retrieveStakeholderAddresses(filterAddress)
    setExportPoPopup(prevState => ({
      ...prevState,
      data: prevState.data.map(item => ({
        ...item,
        orderStatus: 'Po Created', 
      })),
    }));
  }, []);

  const updateStatusForAll = () => {
    console.log(exportPoPopup?.data)
    updateMultipleOrdersApi(exportPoPopup?.data)
                .then((response) => {
                  console.log(response?.data);
                  toast.success("Purchase order(s) updated successfully.");
                })
                .catch((error) => {
                  console.log(error.response?.data);
                  toast.error("Purchase order(s) could not be deleted.");
                });
  };
  const [orderDetails, setOrderDetails] = useState({
    items: items,
    attn: exportPoPopup?.data[0]?.offer?.stakeholder?.contactPerson || "",
    customerTitle: "",
    customerName: exportPoPopup?.data[0]?.offer?.stakeholder?.name || "",
    paymentTerm: exportPoPopup?.data[0]?.paymentTerm || "",
    remark: exportPoPopup?.data[0]?.remark || "",
    incoterm: exportPoPopup?.data[0]?.incoterm || "",
    portOfLoading: exportPoPopup?.data[0]?.offer?.portOfLoading || "",
    buyingCompany: exportPoPopup?.data[0]?.company || "",
    poNumber: exportPoPopup?.data[0]?.purchaseOrderNumber || "",
    companyAddressOne: "",
    companyAddressTwo: "",
    commentsOne: "",
    commentsTwo: "",
    termsOfDelivery: "",
    shipment: exportPoPopup?.data[0]?.shipmentSchedule || "",
    poDate: exportPoPopup?.data[0]?.operationDate || "",
    termsAndConoditionsOne: "",
    termsAndConditionsTwo: "",
    termsAndConditionsThree: "",
    currency:exportPoPopup?.data[0]?.finalPriceCurrency || "",
  });
  return (
    // Added return statement here
    <>
      <Stack direction="column">
        <Typography variant="body1" mb={2}>
          Selected Items
        </Typography>
        {exportPoPopup &&
          exportPoPopup.data &&
          exportPoPopup.data.map((order, index) => (
            <Grid container key={order.id} spacing={2}>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2"> Item ID </Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.offer?.item?.id || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Origin</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.offer?.item?.origin || ""}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">Crop Year</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.offer?.item?.crop || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Type</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.offer?.item?.type || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Buying Grade</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.offer?.item?.buyingGrade || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Sales Grade</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.offer?.item?.salesGrade || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Leaf Form</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.offer?.item?.leafForm || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">ETD</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={
                    order?.estimatedTimeOfDeparture
                      ? format(
                          parseISO(order?.estimatedTimeOfDeparture),
                          "yyyy-MM-dd",
                        )
                      : "" || ""
                  }
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">Nicotine</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.offer?.item?.nicotine || ""}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">Sugar</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.offer?.item?.sugar || ""}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2">Moisture</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.offer?.item?.moisture || ""}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2"> KG / Carton</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.netWeightPerCase || ""}
                />
              </Grid>
              <Grid item xs={12} sm={0.5}>
                <Typography variant="body2"> # Of Cartons</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.numberOfCases || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2"> Net Weight (KG)</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.volume || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Price ({order?.finalPriceCurrency})</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.finalPrice || ""}
                />
              </Grid>
              <Grid item xs={12} sm={1}>
                <Typography variant="body2">Total ({order?.finalPriceCurrency})</Typography>
                <TextField
                  disabled
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={order?.totalAmount || ""}
                />
              </Grid>
            </Grid>
          ))}
        <Typography variant="body1" mb={2} marginTop={5}>
          PO Details
        </Typography>
        <Grid container spacing={2} marginBottom={5}>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">SAP PO Number</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={orderDetails?.poNumber}
              onChange={(e) => {
                setOrderDetails({
                  ...orderDetails,
                  poNumber: e.target.value,
                });
                setExportPoPopup(prevState => ({
                  ...prevState,
                  data: prevState.data.map(item => ({
                    ...item,
                    purchaseOrderNumber: e.target.value, 
                  })),
                }));
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">PO Date</Typography>
            <TextField
              size="small"
              fullWidth
              variant="outlined"
              id="date"
              type="date"
              value={
                orderDetails?.poDate
                  ? format(
                      parseISO(orderDetails.poDate),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => {
                let value = e.target.value;
                let isoDate = "";
            
                // Check if the value is a valid date before trying to parse it.
                if (value && !isNaN(Date.parse(value))) {
                  isoDate = new Date(value).toISOString().split('T')[0];
                }
            
                setOrderDetails({
                  ...orderDetails,
                  poDate: isoDate,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sm={8}></Grid>
          <Grid item xs={12} sm={1}>
            <Typography variant="body2">Buying Company</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-title"
              select
              value={orderDetails?.buyingCompany}
              onChange={(e) =>
                setOrderDetails({
                  ...orderDetails,
                  buyingCompany: e.target.value,
                })
              }
            >
              {companies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={10}></Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Attn</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={orderDetails?.attn}
              onChange={(e) => {
                setOrderDetails({
                  ...orderDetails,
                  attn: e.target.value,
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Seller Company (Vendor)</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={exportPoPopup?.data[0]?.offer?.stakeholder?.name}
            />
          </Grid>
          <Grid item xs={12} sm={9}></Grid>
          <Grid item xs={12} sm={5.5}>
      <Typography variant="body2">Select Address Line 1</Typography>
      <Autocomplete
        options={filteredAddresses}
        getOptionLabel={(option) => {
          if (!option) return '';
          const type = option?.type || "";
          const street = option?.street || "";
          const city = option?.city || "";
      
          const addressPart1 = [street, city].filter(Boolean).join(", ");
          return `${type}: ${addressPart1}`;
        }}
        value={selectedAddress}
        onChange={(event, newValue) => {
          const newTerm = newValue ? newValue.value : "";
          setSelectedAddress(newValue);

    const street = newValue?.street || "";
    const city = newValue?.city || "";
    const county = newValue?.county || "";
    const zipCode = newValue?.zipCode || "";
    const country = newValue?.country || "";

    const companyAddressOne = [street, city].filter(Boolean).join(", ");
    const companyAddressTwo = [county, zipCode, country].filter(Boolean).join(", ");

    setOrderDetails((prevDetails) => ({
      ...prevDetails,
      companyAddressOne: companyAddressOne,
      companyAddressTwo: companyAddressTwo,
    }));
  }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
        renderTags={() => null}
      />
    </Grid>

          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">
              Seller Company Address Line 2
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              disabled
              value={(selectedAddress?.country||"") + "  " + (selectedAddress?.zipCode||"")}
            />
          </Grid>
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Address Line One</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={orderDetails?.companyAddressOne}
              onChange={(e) => {
                setOrderDetails({
                  ...orderDetails,
                  companyAddressOne: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
            {selectedAddress && (<Typography variant="body2" style={{ color: "red" }}>(Please use this field to make changes to the address as it will appear on the PDF. Note that your changes will not affect the actual address information.)</Typography>)}
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Address Line Two</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={orderDetails?.companyAddressTwo}
              onChange={(e) => {
                setOrderDetails({
                  ...orderDetails,
                  companyAddressTwo: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (100) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
            {selectedAddress && (<Typography variant="body2" style={{ color: "red" }}>(Please use this field to make changes to the address as it will appear on the PDF. Note that your changes will not affect the actual address information.)</Typography>)}
          </Grid>
          <Grid item xs={12} sm={1}></Grid>
          <Grid item xs={12} sm={3.75}>
            <Typography variant="body2">Payment Term</Typography>
            <TextField
              disabled
              variant="outlined"
              size="small"
              fullWidth
              value={exportPoPopup?.data[0]?.paymentTerm}
            />
          </Grid>

          <Grid item xs={12} sm={1.75}>
            <Typography variant="body2">Incoterm</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={orderDetails?.incoterm}
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Port Of Loading</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={orderDetails?.portOfLoading}
              disabled
            />
          </Grid>
          {/*<Grid item xs={12} sm={2.67}>
            <Typography variant="body2">Delivery Term</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={orderDetails?.termsOfDelivery}
              onChange={(e) => {
                setOrderDetails({
                  ...orderDetails,
                  termsOfDelivery: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (120) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
            </Grid>*/}

          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Shipment Schedule</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={exportPoPopup?.data[0]?.shipmentSchedule}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6.5}></Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Terms And Conditions Line 1</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={orderDetails?.termsAndConoditionsOne}
              onChange={(e) => {
                setOrderDetails({
                  ...orderDetails,
                  termsAndConoditionsOne: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (120) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Typography variant="body2">Terms And Conditions Line 2</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={orderDetails?.termsAndConditionsTwo}
              onChange={(e) => {
                setOrderDetails({
                  ...orderDetails,
                  termsAndConditionsTwo: e.target.value,
                });
                if (e.target.value.length === 100) {
                  toast.error("Character limit (120) exceeded");
                }
              }}
              inputProps={{
                maxLength: 100,
              }}
            />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>{
              setExportPoPopup({
                data: null,
                visible: false,
              })
              window.location.reload();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{ whiteSpace: "nowrap" }}
            onClick={() => {createExcelWithLogoAndData(orderDetails)
            updateStatusForAll()}}
          >
            Create Purchase Order File
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default ExportPurchaseOrder;
