import { apiClient } from "./ApiClient";

export const retrieveAllOrdersApi = (orderType) =>
  apiClient.get(`/order-view/orders/${orderType}`);

export const createOrderApi = (order) =>
  apiClient.post(`/order-view/add`, order);

export const cancelOrderApi = (orderId, order) =>
  apiClient.put(`/order-view/cancel-order/${orderId}`, order);

export const createMultipleOrdersApi = (orderList) =>
  apiClient.post(`/order-view/add-multiple`, orderList);

export const updateOrderApi = (orderId, order) =>
  apiClient.put(`/order-view/edit/${orderId}`, order);

  export const updateMultipleOrdersApi = (orderList) =>
  apiClient.put(`/order-view/edit/order-list`, orderList);

  export const approveOrderApi = (orderId) =>
    apiClient.put(`order-view/approve/${orderId}`);

export const retrieveFilteredOrdersApi = (order) =>
  apiClient.post(`/order-view/filter`, order);

export const retrieveMaxOrderNumberApi = (orderType) =>
  apiClient.get(`/order-view/max-order-number/${orderType}`);

export const retrieveOrderByIdApi = (orderId) => 
  apiClient.get(`/order-view/orders/get/${orderId}`);

export const deleteOrdersApi = (orderIdList) =>
  apiClient.put(`/order-view/delete-list`, orderIdList);

export const retrieveSelectedOrdersByIdApi = (orderIdList, orderType) =>
  apiClient.post(`/order-view/orders/get-selected/${orderType}`, orderIdList);
