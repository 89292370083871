import { apiClient } from "./ApiClient";

export const createCostApi = (cost) =>
  apiClient.post(`/cost-view/costs/add`, cost);

export const retrieveAllCostsApi = () => apiClient.get(`/cost-view/costs`);

export const updateCostApi = (costId, cost) =>
  apiClient.put(`/cost-view/costs/edit/${costId}`, cost);

export const retrieveFilteredCostsApi = (cost) =>
  apiClient.post(`/cost-view/costs/filter`, cost);

export const retrieveCostByIdApi = (costId) => apiClient.get(`/cost-view/costs/get/${costId}`);

export const eurUsdExchangeApi = (type, cost) => apiClient.post(`/cost-view/exchange/${type}/${cost}`);
