import { apiClient } from "./ApiClient";

export const retrieveAllFreightOffersApi = () =>
  apiClient.get(`/freight-offer-view/freight-offers`);

export const createFreightOfferApi = (freightOffer) =>
  apiClient.post(`/freight-offer-view/add`, freightOffer);

export const createMultipleFreightOffersApi = (freightOfferList) =>
  apiClient.post(`/freight-offer-view/add-multiple`, freightOfferList);

export const updateFreightOfferApi = (freightOfferId, freightOffer) =>
  apiClient.put(`/freight-offer-view/edit/${freightOfferId}`, freightOffer);

export const retrieveFilteredFreightOffersApi = (freightOffer) =>
  apiClient.post(`/freight-offer-view/filter`, freightOffer);

  export const retrieveFreightOfferByIdApi = (freightOfferId) => apiClient.get(`/freight-offer-view/freight-offers/get/${freightOfferId}`);
