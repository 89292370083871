import {
  Autocomplete,
    Box,
    Button,
    Chip,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useState } from "react";
  import toast from "react-hot-toast";
  import { retrieveAllStakeholdersApi, updateStakeholderApi } from "../../../components/api/StakeholderApiService";
  import { recordStatus } from "../../../utils/menu-items";
  
  const EditConsignee = (props) => {
    const { editConsignee, setEditConsignee } = props;
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const reference = editConsignee?.data?.reference || null;

    function retrieveCustomers() {
        retrieveAllStakeholdersApi("CUSTOMER")
          .then((response) => {
            setCustomers(response?.data);
          })
          .catch((error) => console.log(error.response?.data));
      }
    return (
      <>
        <Stack direcion="column">
          <Typography variant="body1" mb={2}>
            Edit Consignee
          </Typography>
          <Grid container item xs={12} spacing="8px">
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Consignee Code</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editConsignee?.data?.code}
                onChange={(e) =>
                  setEditConsignee({
                    ...editConsignee,
                    data: {
                      ...editConsignee.data,
                      code: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Consignee Name</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editConsignee?.data?.name}
                onChange={(e) =>
                  setEditConsignee({
                    ...editConsignee,
                    data: {
                      ...editConsignee.data,
                      name: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Contact Person</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                value={editConsignee?.data?.contactPerson || ""}
                onChange={(e) =>
                  setEditConsignee({
                    ...editConsignee,
                    data: {
                      ...editConsignee.data,
                      contactPerson: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={2}>
            <Typography variant="body2">Reference Customer</Typography>
            <Autocomplete
              options={customers}
              getOptionLabel={(option) =>
                `${option?.id || ""} - ${option?.name || ""}`
              }
              value={selectedCustomer}
              onChange={(event, newValue) => {
                setSelectedCustomer(newValue);
                setEditConsignee({
                  ...editConsignee,
                  data: {
                    ...editConsignee.data,
                    reference: newValue?.name || "",
                  },
                });
              }}
              onOpen={retrieveCustomers}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={`${reference || ""}`}
                />
              )}
              renderTags={() => null}
            />
            {selectedCustomer && (
              <Box mt={2}>
                <Chip
                  label={`${selectedCustomer?.id || ""} - ${
                    selectedCustomer?.name || ""
                  }`}
                  onDelete={() => {
                    setSelectedCustomer(null);
                    setEditConsignee({
                      ...editConsignee,
                      data: {
                        ...editConsignee.data,
                        reference: "",
                      },
                    });
                  }}
                />
              </Box>
            )}
          </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="body2">Status</Typography>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                id="outlined-select-status"
                select
                value={editConsignee?.data?.status}
                onChange={(e) =>
                  setEditConsignee({
                    ...editConsignee,
                    data: {
                      ...editConsignee.data,
                      status: e.target.value,
                    },
                  })
                }
              >
                {recordStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Stack direction="row" spacing={1} mt={1}>
            <Button
              variant="outlined"
              onClick={() =>
                setEditConsignee({ visible: false, data: null, index: null })
              }
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                updateStakeholderApi(editConsignee.data.id, editConsignee.data)
                  .then((response) => {
                    toast.success("Consignee edited successfully.");
                    window.location.reload();
  
                    setEditConsignee({ visible: false, data: null, index: null });
                  })
                  .catch((error) => {
                    toast.error("Something went wrong.");
                    console.log(error.response.data);
                  });
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                editConsignee.data.status = "PASSIVE";
                updateStakeholderApi(editConsignee.data.id, editConsignee.data)
                  .then((response) => {
                    toast.success("Consignee deleted successfully.");
                    window.location.reload();
  
                    setEditConsignee({ visible: false, data: null, index: null });
                  })
                  .catch((error) => {
                    toast.error("Something went wrong.");
                    console.log(error.response.data);
                  });
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
      </>
    );
  };
  
  export default EditConsignee;
  