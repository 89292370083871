import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { createStakeholderApi } from "../../../components/api/StakeholderApiService";
import { recordStatus } from "../../../utils/menu-items";

const AddVendor = (props) => {
  const navigate = useNavigate();
  const { setAddViewStates, addViewStates, rows, setRows, setAddView } = props;

  useEffect(
    () =>
      setAddViewStates({
        ...addViewStates,
        type: "VENDOR",
      }),
    [],
  );
  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Create New Vendor
        </Typography>
        <Grid container xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Vendor Code</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  code: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Vendor Name</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  name: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Contact Person</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  contactPerson: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              label={"ACTIVE"}
              id="outlined-select-status"
              select
              onChange={(e) =>
                setAddViewStates({
                  ...addViewStates,
                  status: e.target.value,
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setAddView(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              const newId = Math.floor(Math.random() * 100000);
              setAddViewStates({ ...addViewStates, id: newId });
              setRows([addViewStates, ...rows]);
              setAddView(false);

              createStakeholderApi(addViewStates)
                .then((response) => {
                  toast.success("Vendor added successfully.");
                  window.location.reload();
                  navigate("/vendor-view");
                })
                .catch((error) => {
                  toast.error("Something went wrong.");
                  console.log(error.response.data);
                });
            }}
          >
            Add
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default AddVendor;
