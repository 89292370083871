import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  MenuItem,
  Stack,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import toast from "react-hot-toast";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  retrieveAllCostsApi,
  retrieveCostByIdApi,
} from "../../../components/api/CostApiService";
import {
  retrieveAllSamplesApi,
  retrieveSampleByIdApi,
} from "../../../components/api/SampleApiService";
import EditItem from "../ItemView/EditItem";
import EditCost from "../CostView/EditCost";
import EditSample from "../SampleView/EditSample";
import EditCustomerOffer from "../CustomerOfferView/EditCustomerOffer";
import {
  retrieveAllItemsApi,
  retrieveItemByIdApi,
} from "../../../components/api/ItemApiService";
import {
  retrieveAllOffersApi,
  retrieveOfferByIdApi,
} from "../../../components/api/OfferApiService";
import {
  approveOrderApi,
  cancelOrderApi,
  deleteOrdersApi,
  retrieveAllOrdersApi,
  retrieveFilteredOrdersApi,
  retrieveMaxOrderNumberApi,
  retrieveSelectedOrdersByIdApi,
} from "../../../components/api/OrderApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import ExportExcel from "../../../utils/excelExport";
import {
  color,
  companies,
  currencies,
  incoterms,
  leafForm,
  origin,
  packagingTypes,
  paymentTerm,
  portsOfDischarge,
  portsOfLoading,
  recordStatus,
  sampleStatus,
  status,
  type,
  units,
} from "../../../utils/menu-items";
import AddCustomerOrder from "./AddCustomerOrder";
import CreateOrder from "./CreateOrder";
import CreateShipment from "./CreateShipment";
import EditCustomerOrder from "./EditCustomerOrder";
import ExportSalesOrder from "./Dialogs/ExportSalesOrder";
import ExportProformaDetails from "./Dialogs/ExportProformaDetails";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "approveButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "createShipmentButton",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "cancelOrderButton",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "salesOrderNumber",
    numeric: false,
    disablePadding: false,
    label: "SAP SO Number",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: true,
    label: "Company",
  },
  {
    id: "itemId",
    numeric: true,
    disablePadding: true,
    label: "Item ID",
  },
  {
    id: "itemTobaccoCode",
    numeric: false,
    disablePadding: true,
    label: "Item Tobacco Code",
  },
  {
    id: "itemBuyingGrade",
    numeric: false,
    disablePadding: true,
    label: "Buying Grade",
  },
  {
    id: "itemSgiGrade",
    numeric: false,
    disablePadding: true,
    label: "Sales Grade",
  },
  {
    id: "itemCrop",
    numeric: true,
    disablePadding: true,
    label: "Crop",
  },
  {
    id: "itemNicotine",
    numeric: true,
    disablePadding: true,
    label: "Nicotine",
  },
  {
    id: "itemSugar",
    numeric: true,
    disablePadding: true,
    label: "Sugar",
  },
  {
    id: "itemMoisture",
    numeric: true,
    disablePadding: true,
    label: "Moisture",
  },
  {
    id: "itemOrigin",
    numeric: false,
    disablePadding: true,
    label: "Origin",
  },
  {
    id: "itemStorage",
    numeric: false,
    disablePadding: true,
    label: "Current Location",
  },
  {
    id: "itemType",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "warehouse",
    numeric: false,
    disablePadding: true,
    label: "Warehouse",
  },
  {
    id: "customerCode",
    numeric: true,
    disablePadding: true,
    label: "SAP Customer Code",
  },
  {
    id: "customerName",
    numeric: false,
    disablePadding: true,
    label: "Customer Name",
  },
  {
    id: "firstSampleId",
    numeric: true,
    disablePadding: true,
    label: "Redrawn Sample",
  },
  {
    id: "firstSampleDate",
    numeric: true,
    disablePadding: true,
    label: "Redrawn Sample Date",
  },
  {
    id: "firstSampleStatus",
    numeric: false,
    disablePadding: true,
    label: "Redrawn Sample Status",
  },
  {
    id: "typeOfPackage",
    numeric: true,
    disablePadding: true,
    label: "Packaging Type",
  },
  {
    id: "netWeightPerCase",
    numeric: true,
    disablePadding: true,
    label: "Net Weight Per Case",
  },
  {
    id: "numberOfCase",
    numeric: true,
    disablePadding: true,
    label: "Number Of Cases",
  },
  {
    id: "quantity",
    numeric: true,
    disablePadding: true,
    label: "Quantity (KG)",
  },
  {
    id: "firstPrice",
    numeric: true,
    disablePadding: true,
    label: "First Price",
  },
  {
    id: "firstPriceUsd",
    numeric: true,
    disablePadding: true,
    label: "First Price (USD)",
  },
  {
    id: "finalPrice",
    numeric: true,
    disablePadding: false,
    label: "Final Price",
  },
  {
    id: "finalPriceUsd",
    numeric: true,
    disablePadding: false,
    label: "Final Price (USD)",
  },
  {
    id: "totalAmount",
    numeric: true,
    disablePadding: true,
    label: "Total Amount",
  },
  {
    id: "costId",
    numeric: true,
    disablePadding: true,
    label: "Cost Id",
  },
  {
    id: "totalCost",
    numeric: true,
    disablePadding: true,
    label: "Total Cost",
  },
  {
    id: "contractValueDate",
    numeric: false,
    disablePadding: true,
    label: "Contract Value Date",
  },
  {
    id: "incoterm",
    numeric: true,
    disablePadding: true,
    label: "Incoterm",
  },
  {
    id: "portOfDischarge",
    numeric: true,
    disablePadding: true,
    label: "Port Of Discharge",
  },
  {
    id: "portOfLoading",
    numeric: true,
    disablePadding: true,
    label: "Port Of Loading",
  },
  {
    id: "paymentTerm",
    numeric: false,
    disablePadding: true,
    label: "Payment Term",
  },
  {
    id: "operationDate",
    numeric: false,
    disablePadding: true,
    label: "Issue Date",
  },
  {
    id: "estimatedTimeOfDeparture",
    numeric: false,
    disablePadding: true,
    label: "ETD",
  },
  {
    id: "estimatedTimeOfArrival",
    numeric: false,
    disablePadding: true,
    label: "ETA",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: true,
    label: "Description",
  },
  {
    id: "shipmentSchedule",
    numeric: false,
    disablePadding: true,
    label: "Shipment Schedule",
  },
  {
    id: "customerReference",
    numeric: false,
    disablePadding: true,
    label: "Customer Reference",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: true,
    label: "Remark",
  },
  {
    id: "comment",
    numeric: false,
    disablePadding: true,
    label: "Comment",
  },
  {
    id: "offerId",
    numeric: true,
    disablePadding: true,
    label: "Offer Id",
  },
  {
    id: "offerNumber",
    numeric: false,
    disablePadding: true,
    label: "Offer Number",
  },
  {
    id: "offerDate",
    numeric: false,
    disablePadding: true,
    label: "Offer Date",
  },
  {
    id: "province",
    numeric: false,
    disablePadding: true,
    label: "Province",
  },
  {
    id: "shippingTerm",
    numeric: false,
    disablePadding: false,
    label: "Shipping Term",
  },
  {
    id: "approvalStatus",
    numeric: false,
    disablePadding: true,
    label: "SG Approval Status",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "recordStatus",
    numeric: false,
    disablePadding: true,
    label: "Record Status",
  },
  {
    id: "editingPermission",
    numeric: false,
    disablePadding: true,
    label: "Edit Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={
              headCell.id === "id"
                ? {
                    position: 'sticky',
                    left: 0,
                    zIndex: 3, 
                  }
                : headCell.id === "salesOrderNumber"
                ? {
                    position: 'sticky',
                    left: 50,
                    zIndex: 3, 
                  }
                : headCell.id === "itemBuyingGrade"
                ? {
                    position: 'sticky',
                    left: 180,
                    zIndex: 3, 
                  }
                : null
            }
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const {
    numSelected,
    showFilters,
    setShowFilters,
    rows,
    setAddView,
    selected,
    setExportSoPopup,
    setExportProformaDetailsPopup,
    selectedData,
  } = props;

  const [deletePopup, setDeletePopup] = useState(false);

  function formatDate(isoDateString) {
    const date = new Date(isoDateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  function createNewSapObject(rows) {
    const newObjects = [];

    rows.forEach((row, index) => {
      const newObject = {};
      newObject.itemNumber = row?.offer?.item?.tobaccoCode || "";
      newObject.itemDescription = "";
      newObject.quantity = row?.volume || "";
      const finalPrice =
        typeof row?.finalPrice === "number"
          ? row?.finalPrice.toFixed(2)
          : row?.finalPrice || "";
      newObject.unitPrice =
        (row?.finalPriceCurrency || "") + " " + finalPrice.replace(".", ",");
      newObject.discount = "";
      newObject.taxCode = "";
      newObject.totalLc =
        (row?.finalPriceCurrency || "") + " " + (row?.totalAmount || "");
      newObject.WHS = "";
      newObject.uomCode = row?.volumeUnit || "";
      newObject.cogsDepartments = "SGI_SALS";
      newObject.cogsTypeOfProduct = "LEAFTRDE";
      newObject.cogsStaff = "BSANDAL";
      newObject.blanketAgreementNumber = "";
      newObject.allowProcmntDoc = "";
      newObject.customerPoNumber = "";
      newObject.customer = row?.offer?.stakeholder?.name || "";
      newObject.customerGrade = row?.offer?.item?.salesGrade || "";
      newObject.internalGrade = "";
      newObject.vendorGrade = row?.offer?.item?.buyingGrade || "";
      newObject.countryOfOrigin = row?.offer?.item?.origin || "";
      newObject.leafType = row?.offer?.item?.type || "";
      newObject.leafForm = row?.offer?.item?.leafForm || "";
      newObject.cropYear = row?.offer?.item?.crop || "";
      newObject.kgPerCartonBaleNetWeight = row?.netWeightPerCase || "";
      newObject.numberOfCartons = row?.numberOfCases || "";
      newObject.etaDate = row?.estimatedTimeOfArrival
        ? formatDate(row.estimatedTimeOfArrival)
        : "";
      newObject.etdDate = row?.estimatedTimeOfDeparture
        ? formatDate(row.estimatedTimeOfDeparture)
        : "";
      newObject.moisture = "";
      newObject.nicotine = row?.offer?.item?.nicotine || "";
      newObject.sugar = row?.offer?.item?.sugar || "";
      newObject.color = row?.offer?.item?.color || "";
      newObject.cutWidth = "";
      newObject.fillingPower = "";
      newObject.containerTruckType = "";
      newObject.cartonPerContainerTruck = "";
      newObject.kgPerContainerTruck = "";
      newObject.temperatureTarget = "";
      newObject.numberOfContainers = "";
      newObject.packageType = row?.packaging || "";
      newObject.cartonNumberRange = "";
      newObject.containerNumber = "";
      newObject.salesQutationReferenceNumber = "";
      newObject.salesQutationReferenceDetails = "";
      newObject.salesQutationReferenceDate = "";
      newObject.netWeight = row?.volume || "";
      newObject.grossWeight = "";
      newObject.reasonForDc = "";
      newObject.description = "";
      newObject.ctcTobaccoAndPgPrice = "";
      newObject.ctcPackagingPrice = "";
      newObject.ctcProcessingPrice = "";
      newObject.salesOrderDocLineNum = "";
      newObject.salesOrderDocNum = "";
      newObject.salesOrderLineNum = "";

      newObjects.push(newObject);
    });

    return newObjects;
  }
  function createNewExportObject(rows) {
    const newObjects = [];
    const headerMapping = {
      "Order Group Number": (row) =>
        (row?.orderYear ? row.orderYear + "-" : "") +
        (row?.orderGroupNumber || ""),
      "Approval Status": "approvalStatus",
      "Order Status": "orderStatus",
      "Status Deadline": (row) =>
        row?.statusDeadline ? formatDate(row.statusDeadline) : "",
      Company: "company",
      "Item ID": "offer?.item?.id",
      "Tobacco Code": "offer?.item?.tobaccoCode",
      "Buying Grade": "offer?.item?.buyingGrade",
      "Sales Grade": "offer?.item?.salesGrade",
      Crop: "offer?.item?.crop",
      Nicotine: "offer?.item?.nicotine",
      Sugar: "offer?.item?.sugar",
      Origin: "offer?.item?.origin",
      Storage: "offer?.item?.storage",
      Type: "offer?.item?.type",
      "BP Code": "offer?.stakeholder?.code",
      "BP Name": "offer?.stakeholder?.name",
      Warehouse: "offer?.item?.warehouse",
      "First Sample ID": "firstSample?.id",
      "First Sample Delivery Date": (row) =>
        row?.firstSample?.deliveryDate
          ? formatDate(row.firstSample.deliveryDate)
          : "",
      "First Sample Status": "firstSampleStatus",
      "Second Sample ID": "secondSample?.id",
      "Second Sample Delivery Date": (row) =>
        row?.secondSample?.deliveryDate
          ? formatDate(row.secondSample.deliveryDate)
          : "",
      "Second Sample Status": "secondSampleStatus",
      Volume: "volume",
      "Volume Unit": "volumeUnit",
      Packaging: "packaging",
      "Net Weight Per Case": "netWeightPerCase",
      "Number of Cases": "numberOfCases",
      "First Offer Price": "offer?.firstOfferPrice",
      "First Offer Price Currency": "offer?.firstOfferPriceCurrency",
      "USD First Offer Price": "offer?.usdFirstOfferPrice",
      "Final Price": "finalPrice",
      "Final Price Currency": "finalPriceCurrency",
      "USD Final Price": "usdFinalPrice",
      "Total Amount": "totalAmount",
      "Total Amount Currency": "totalAmountCurrency",
      "Cost ID": "cost?.id",
      "Total Cost": "cost?.totalCost",
      "Contract Value Date": (row) =>
        row?.contractValueDate ? formatDate(row.contractValueDate) : "",
      Incoterm: "incoterm",
      "Payment Term": "paymentTerm",
      "Operation Date": (row) =>
        row?.operationDate ? formatDate(row.operationDate) : "",
      "Estimated Time of Departure": (row) =>
        row?.estimatedTimeOfDeparture
          ? formatDate(row.estimatedTimeOfDeparture)
          : "",
      "Estimated Time of Arrival": (row) =>
        row?.estimatedTimeOfArrival
          ? formatDate(row.estimatedTimeOfArrival)
          : "",
      Remark: "remark",
      Comment: "comment",
      "Offer ID": "offer?.id",
      "Offer Group Number": (row) =>
        (row?.offer?.offerYear ? row.offer.offerYear + "-" : "") +
        (row?.offer?.offerGroupNumber || ""),
      "Offer Date": (row) =>
        row?.offer?.offerDate ? formatDate(row.offer.offerDate) : "",
      Province: "province",
      "Shipping Term": "shippingTerm",
      "SAP Purchase Order Number": "purchaseOrderNumber",
      "Reference Sales Order ID": "referenceCustomerOrderId",
      "Record Status": "recordStatus",
      "Editing Permission": "editingPermission",
    };

    rows.forEach((row) => {
      const newObject = {};
      Object.entries(headerMapping).forEach(([header, pathOrFunction]) => {
        let value;
        if (typeof pathOrFunction === "function") {
          // Handling functions to format or concatenate fields
          value = pathOrFunction(row);
        } else {
          // Handling direct paths and nested properties
          const keys = pathOrFunction.split("?.");
          value = row;
          for (const key of keys) {
            if (value == null) break;
            value = value[key];
          }
        }
        newObject[header] = value ?? ""; // Defaulting to empty string if null or undefined
      });
      newObjects.push(newObject);
    });

    return newObjects;
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Dialog
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selected.length} order(s)?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              deleteOrdersApi(selected)
                .then((response) => {
                  console.log(response?.data);
                  toast.success("Sales order(s) deleted successfully.");
                })
                .catch((error) => {
                  console.log(error.response?.data);
                  toast.error("Sales order(s) could not be deleted.");
                });

              window.location.reload();
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button onClick={() => setDeletePopup(false)}>No</Button>
        </DialogActions>
      </Dialog>

      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="#1E2685"
        >
          Sales Orders
        </Typography>

        <Typography></Typography>
        {numSelected > 0 ? (
          <Typography
            sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography></Typography>
        )}

        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton onClick={() => setDeletePopup(true)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
        {numSelected > 0 && (
          <Button
            variant="outlined"
            sx={{ minWidth: "130px" }}
            style={{ whiteSpace: "nowrap", marginLeft: 20 }}
            onClick={() => {
              console.log(selected);
              retrieveSelectedOrdersByIdApi(selected, "CUSTOMER")
                .then((response) => {
                  setTimeout(() => {
                    setExportSoPopup({
                      data: response?.data,
                      visible: true,
                    });
                  }, 1000);
                  console.log("pdfpopup ", response?.data);
                  toast.success("Sales orders retrieved successfully.");
                })
                .catch((error) => {
                  console.log(error.response?.data);
                  toast.error("Sales orders could not be retrieved.");
                });
            }}
          >
            Create SO Excel
          </Button>
        )}
          {numSelected > 0 && (
          <Button
            variant="outlined"
            sx={{ minWidth: "130px" }}
            style={{ whiteSpace: "nowrap", marginLeft: 20 }}
            onClick={() => {
              console.log(selected);
              retrieveSelectedOrdersByIdApi(selected, "CUSTOMER")
                .then((response) => {
                  setTimeout(() => {
                    setExportProformaDetailsPopup({
                      data: response?.data,
                      visible: true,
                    });
                  }, 1000);
                  toast.success("Sales orders retrieved successfully.");
                })
                .catch((error) => {
                  toast.error("Sales orders could not be retrieved.");
                });
            }}
          >
            Create Proforma Invoice
          </Button>
        )}
      </Stack>
      <Stack direction={"row"} spacing={1} sx={{ mr: 2 }}>
        <ExportExcel
          excelData={createNewExportObject(selectedData)}
          fileName={"ExcelExport"}
          buttonLabel="Export Table"
        />
        <ExportExcel
          excelData={createNewSapObject(selectedData)}
          fileName={"ExcelExportSAP"}
          buttonLabel="SAP Export"
        />
        <Button
          disableElevation
          variant="outlined"
          size="small"
          onClick={() => setAddView(true)}
        >
          Add
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px" }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function CustomerOrder() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [addView, setAddView] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [rows, setRows] = useState([]);
  const [items, setItems] = useState([]);
  const [samples, setSamples] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [costs, setCosts] = useState([]);
  const [showFilters, setShowFilters] = useState();
  const [offers, setOffers] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [maxOrderGroupNumber, setMaxOrderGroupNumber] = useState();

  const [editItem, setEditItem] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const [editCost, setEditCost] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const [editSample, setEditSample] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const [editCustomerOffer, setEditCustomerOffer] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const [exportSoPopup, setExportSoPopup] = useState({
    visible: false,
    data: null,
  });
  const [exportProformaDetailsPopup, setExportProformaDetailsPopup] = useState({
    visible: false,
    data: null,
  });
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [componentsState, setComponentsState] = useState({
    origin: {
      dropdownOpen: false,
      inputValue: "",
    },
    item: {
      dropdownOpen: false,
      inputValue: "",
    },
    customer: {
      dropdownOpen: false,
      inputValue: "",
    },
    type: {
      dropdownOpen: false,
      inputValue: "",
    },
    leafForm: {
      dropdownOpen: false,
      inputValue: "",
    },
    storage: {
      dropdownOpen: false,
      inputValue: "",
    },
    cost: {
      dropdownOpen: false,
      inputValue: "",
    },
    firstSample: {
      dropdownOpen: false,
      inputValue: "",
    },
    secondSample: {
      dropdownOpen: false,
      inputValue: "",
    },
    paymentTerm: {
      dropdownOpen: false,
      inputValue: "",
    },
    offer: {
      dropdownOpen: false,
      inputValue: "",
    },
  });

  const [item, setItem] = useState([]);
  const [cost, setCost] = useState([]);
  const [sample, setSample] = useState([]);
  const [offer, setOffer] = useState([]);

  const updateComponentState = (componentKey, newState) => {
    setComponentsState((prevState) => ({
      ...prevState,
      [componentKey]: {
        ...prevState[componentKey],
        ...newState,
      },
    }));
  };

  const [filterCustomerOrder, setFilterCustomerOrder] = useState({
    id: 0,
    orderType: "CUSTOMER",
    stakeHolderType: "CUSTOMER",
    itemBuyingGrade: "",
    itemIdList: [],
    stakeholderIdList: [],
    costIdList: [],
    offerIdList: [],
    companyList: [],
    approvalStatusList: [],
    orderStatusList: [],
    customerList: [],
    firstSampleId: "",
    secondSampleId: "",
    costId: "",
    firstSampleIdList: [],
    secondSampleIdList: [],
    firstSampleStatusList: [],
    secondSampleStatusList: [],
    paymentTerm: "",
    paymentTermList: [],
    incotermList: [],
    portOfLoadingList: [],
    portOfDischargeList: [],
    packagingList: [],
    volumeUnitList: [],
    firstPriceCurrencyList: [],
    finalPriceCurrencyList: [],
    remark: "",
    shipmentSchedule: "",
    comment: "",
    documentStatusList: [],
    tobaccoCode: "",
    salesGrade: "",
    buyingGrade: "",
    customerGrade: "",
    originList: [],
    typeList: [],
    leafFormList: [],
    colorList: [],
    storageList: [],
    itemStorage: "",
    recordStatusList: ["ACTIVE"],
  });

  useEffect(() => {
    retrieveCustomerOrders();
    getMaxOrderNumber();
  }, []);

  function approveOrder(orderId) {
    approveOrderApi(orderId)
      .then((response) => {
        window.location.reload();
        toast.success("Order approved successfully.");
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }

  function cancelOrder(order) {
    cancelOrderApi(order)
      .then((response) => {
        window.location.reload();
        toast.success("Order cancelled successfully.");
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }

  function cancelOrder(order) {
    cancelOrderApi(order?.id, order)
      .then((response) => {
        window.location.reload();
        toast.success("Order cancelled successfully.");
      })
      .catch((error) => {
        window.location.reload();
        console.log(error.response.data);
      });
  }

  function retrieveCustomerOrders() {
    retrieveAllOrdersApi("CUSTOMER")
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItemById(id) {
    retrieveItemByIdApi(id)
      .then((response) => {
        setItem(response?.data);
        console.log(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveSamples() {
    retrieveAllSamplesApi()
      .then((response) => {
        setSamples(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveSampleById(id) {
    retrieveSampleByIdApi(id)
      .then((response) => {
        setSample(response?.data);
        console.log(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveCustomers() {
    retrieveAllStakeholdersApi("CUSTOMER")
      .then((response) => {
        setCustomers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveCosts() {
    retrieveAllCostsApi()
      .then((response) => {
        setCosts(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveCostById(id) {
    retrieveCostByIdApi(id)
      .then((response) => {
        setCost(response?.data);
        console.log(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveOffers() {
    retrieveAllOffersApi("CUSTOMER")
      .then((response) => {
        setOffers(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveOfferById(id) {
    retrieveOfferByIdApi(id)
      .then((response) => {
        setOffer(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }
  function getMaxOrderNumber() {
    retrieveMaxOrderNumberApi("CUSTOMER")
      .then((response) => {
        console.log(response?.data);
        setMaxOrderGroupNumber(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const newOrderGroupNumber =
    maxOrderGroupNumber + (Math.floor(Math.random() * 5) + 1);

  const [editCustomerOrder, setEditCustomerOrder] = useState({
    visible: false,
    data: null,
    index: null,
  });
  const [showOrderPopup, setShowOrderPopup] = useState({
    visible: false,
    data: null,
  });
  const [showShipmentRequestPopup, setShowShipmentRequestPopup] = useState({
    visible: false,
    data: null,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }else{
      setSelected([]);
      setSelectedData([]);
    }
  };
  
  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editCustomerOrder],
  );

  return (
    <div>
      {addView ? (
        <AddCustomerOrder
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          newOrderGroupNumber={newOrderGroupNumber}
        />
      ) : editCustomerOrder.visible ? (
        <EditCustomerOrder
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editCustomerOrder={editCustomerOrder}
          setEditCustomerOrder={setEditCustomerOrder}
        />
      ) : showOrderPopup.visible ? (
        <CreateOrder
          setCreateOrder={setShowOrderPopup}
          createOrder={showOrderPopup}
        />
      ) : showShipmentRequestPopup.visible ? (
        <CreateShipment
          setCreateShipmentRequest={setShowShipmentRequestPopup}
          createShipmentRequest={showShipmentRequestPopup}
        />
      ) : editItem.visible ? (
        <EditItem
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editItem={editItem}
          setEditItem={setEditItem}
        />
      ) : editCost.visible ? (
        <EditCost
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editCost={editCost}
          setEditCost={setEditCost}
        />
      ) : editSample.visible ? (
        <EditSample
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editSample={editSample}
          setEditSample={setEditSample}
        />
      ) : editCustomerOffer.visible ? (
        <EditCustomerOffer
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editCustomerOffer={editCustomerOffer}
          setEditCustomerOffer={setEditCustomerOffer}
        />
      ) : exportSoPopup.visible ? (
        <ExportSalesOrder
          setExportSoPopup={setExportSoPopup}
          exportSoPopup={exportSoPopup}
        /> ) : exportProformaDetailsPopup.visible ? (
          <ExportProformaDetails
          setExportProformaDetailsPopup={setExportProformaDetailsPopup}
          exportProformaDetailsPopup={exportProformaDetailsPopup}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                {/*<TextField
                  id="outlined-search"
                  label="Group Number"
                  value={filterCustomerOrder?.orderGroupNumber || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      orderGroupNumber: e.target.value,
                    })
                  }
                />*/}

                <TextField
                  id="outlined-search"
                  label="SAP SO Number"
                  value={filterCustomerOrder?.salesOrderNumber || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      salesOrderNumber: e.target.value,
                    })
                  }
                />

                <Autocomplete
                  multiple
                  id="outlined-select-offer"
                  disableClearable
                  open={componentsState.offer.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("offer", { dropdownOpen: true })
                  }
                  inputValue={componentsState.offer.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("offer", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...offers.filter((offer) =>
                      filterCustomerOrder.offerIdList.includes(offer.id),
                    ),
                    ...offers.filter(
                      (offer) =>
                        !filterCustomerOrder.offerIdList.includes(offer.id),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `ID: ${option?.id || ""} Offer Number: ${
                      option?.offerYear || ""
                    } - ${option?.offerGroupNumber || ""}`
                  }
                  value={offers.filter((offer) =>
                    filterCustomerOrder.offerIdList.includes(offer.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      offerIdList: newValue.map((offer) => offer.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`ID: ${option?.id || ""} Offer Number: ${
                        option?.offerYear || ""
                      } - ${option?.offerGroupNumber || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterCustomerOrder?.offerIdList.length > 0
                        ? `Offers Selected (${filterCustomerOrder?.offerIdList.length})`
                        : "Offer";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.offer.inputValue}
                        onChange={(e) =>
                          updateComponentState("offer", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={retrieveOffers}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("offer", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  label="SG Approval Status"
                  type="search"
                  id="outlined-select-approval-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.approvalStatusList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        approvalStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {companies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                {/* <TextField
                  label="Status"
                  type="search"
                  id="outlined-select-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.orderStatusList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        orderStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="date"
                  type="date"
                  label="Status Deadline"
                  value={filterCustomerOrder?.statusDeadline || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      statusDeadline: e.target.value,
                    })
                  }
                />
                */}
                <TextField
                  label="Company"
                  type="search"
                  id="outlined-select-company"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.companyList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        companyList: e.target.value,
                      });
                    },
                  }}
                >
                  {companies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Autocomplete
                  multiple
                  id="outlined-select-item"
                  disableClearable
                  open={componentsState.item.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("item", { dropdownOpen: true })
                  }
                  inputValue={componentsState.item.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("item", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...items.filter((item) =>
                      filterCustomerOrder.itemIdList.includes(item.id),
                    ),
                    ...items.filter(
                      (item) =>
                        !filterCustomerOrder.itemIdList.includes(item.id),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.salesGrade || ""} - ${
                      option?.crop || ""
                    }`
                  }
                  value={items.filter((item) =>
                    filterCustomerOrder.itemIdList.includes(item.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      itemIdList: newValue.map((item) => item.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.id || ""} - ${option?.salesGrade || ""} - ${
                        option?.crop || ""
                      }`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterCustomerOrder?.itemIdList.length > 0
                        ? `Items Selected (${filterCustomerOrder?.itemIdList.length})`
                        : "Item";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.item.inputValue}
                        onChange={(e) =>
                          updateComponentState("item", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={retrieveItems}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("item", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  id="outlined-search"
                  label="Tobacco Code"
                  value={filterCustomerOrder?.tobaccoCode || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      tobaccoCode: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Sales Grade"
                  value={filterCustomerOrder?.salesGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      salesGrade: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Buying Grade"
                  value={filterCustomerOrder?.buyingGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      buyingGrade: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Customer Grade"
                  value={filterCustomerOrder?.customerGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      customerGrade: e.target.value,
                    })
                  }
                />
                <Autocomplete
                  multiple
                  id="outlined-select-origin"
                  disableClearable
                  open={componentsState.origin.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("origin", { dropdownOpen: true })
                  }
                  inputValue={componentsState.origin.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("origin", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...origin.filter((o) =>
                      filterCustomerOrder.originList.includes(o.value),
                    ),
                    ...origin.filter(
                      (o) => !filterCustomerOrder.originList.includes(o.value),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `${option?.value || ""} - ${option?.label || ""}`
                  }
                  value={origin.filter((o) =>
                    filterCustomerOrder.originList.includes(o.value),
                  )}
                  onChange={(event, newValue) => {
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      originList: newValue.map((o) => o.value),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.value || ""} - ${option?.label || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterCustomerOrder?.originList.length > 0
                        ? `Origins Selected (${filterCustomerOrder?.originList.length})`
                        : "Origin";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.origin.inputValue}
                        onChange={(e) =>
                          updateComponentState("origin", {
                            inputValue: e.target.value,
                          })
                        }
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("origin", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <Autocomplete
                  multiple
                  id="outlined-select-type"
                  disableClearable
                  open={componentsState.type.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("type", { dropdownOpen: true })
                  }
                  inputValue={componentsState.type.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("type", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...type.filter((t) =>
                      filterCustomerOrder.typeList.includes(t.value),
                    ),
                    ...type.filter(
                      (t) => !filterCustomerOrder.typeList.includes(t.value),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `${option?.value || ""} - ${option?.label || ""}`
                  }
                  value={type.filter((t) =>
                    filterCustomerOrder.typeList.includes(t.value),
                  )}
                  onChange={(event, newValue) => {
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      typeList: newValue.map((t) => t.value),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.value || ""} - ${option?.label || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterCustomerOrder?.typeList.length > 0
                        ? `Types Selected (${filterCustomerOrder?.typeList.length})`
                        : "Type";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.type.inputValue}
                        onChange={(e) =>
                          updateComponentState("type", {
                            inputValue: e.target.value,
                          })
                        }
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("type", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <Autocomplete
                  multiple
                  id="outlined-select-leaf-form"
                  disableClearable
                  open={componentsState.leafForm.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("leafForm", { dropdownOpen: true })
                  }
                  inputValue={componentsState.leafForm.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("leafForm", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...leafForm.filter((lf) =>
                      filterCustomerOrder.leafFormList.includes(lf.value),
                    ),
                    ...leafForm.filter(
                      (lf) =>
                        !filterCustomerOrder.leafFormList.includes(lf.value),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `${option?.value || ""} - ${option?.label || ""}`
                  }
                  value={leafForm.filter((lf) =>
                    filterCustomerOrder.leafFormList.includes(lf.value),
                  )}
                  onChange={(event, newValue) => {
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      leafFormList: newValue.map((lf) => lf.value),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.value || ""} - ${option?.label || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterCustomerOrder?.leafFormList.length > 0
                        ? `Leaf Forms Selected (${filterCustomerOrder?.leafFormList.length})`
                        : "Leaf Form";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.leafForm.inputValue}
                        onChange={(e) =>
                          updateComponentState("leafForm", {
                            inputValue: e.target.value,
                          })
                        }
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("leafForm", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  label="Color"
                  type="search"
                  id="outlined-select-color"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.colorList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        colorList: e.target.value,
                      });
                    },
                  }}
                >
                  {color.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-search"
                  label="Crop Year"
                  value={filterCustomerOrder?.crop || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      crop: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Current Location"
                  type="search"
                  value={filterCustomerOrder?.itemStorage || ""}
                  id="outlined-storage"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      itemStorage: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Nicotine"
                  value={filterCustomerOrder?.nicotine || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      nicotine: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Sugar"
                  value={filterCustomerOrder?.sugar || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      sugar: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Warehouse"
                  value={filterCustomerOrder?.warehouse || ""}
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      warehouse: e.target.value,
                    })
                  }
                />
                <Autocomplete
                  multiple
                  id="outlined-select-customer"
                  disableClearable
                  open={componentsState.customer.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("customer", { dropdownOpen: true })
                  }
                  inputValue={componentsState.customer.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("customer", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...customers.filter((customer) =>
                      filterCustomerOrder.stakeholderIdList.includes(
                        customer.id,
                      ),
                    ),
                    ...customers.filter(
                      (customer) =>
                        !filterCustomerOrder.stakeholderIdList.includes(
                          customer.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.name || ""}`
                  }
                  value={customers.filter((customer) =>
                    filterCustomerOrder.stakeholderIdList.includes(customer.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      stakeholderIdList: newValue.map(
                        (customer) => customer.id,
                      ),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.id || ""} - ${option?.name || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterCustomerOrder?.stakeholderIdList.length > 0
                        ? `Customers Selected (${filterCustomerOrder?.stakeholderIdList.length})`
                        : "Customer";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.customer.inputValue}
                        onChange={(e) =>
                          updateComponentState("customer", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={retrieveCustomers}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("customer", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <Autocomplete
                  multiple
                  id="outlined-select-first-sample"
                  disableClearable
                  open={componentsState.firstSample.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("firstSample", { dropdownOpen: true })
                  }
                  inputValue={componentsState.firstSample.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("firstSample", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...samples.filter((sample) =>
                      filterCustomerOrder.firstSampleIdList.includes(sample.id),
                    ),
                    ...samples.filter(
                      (sample) =>
                        !filterCustomerOrder.firstSampleIdList.includes(
                          sample.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.remark || ""}`
                  }
                  value={samples.filter((sample) =>
                    filterCustomerOrder.firstSampleIdList.includes(sample.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      firstSampleIdList: newValue.map((sample) => sample.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.id || ""} - ${option?.remark || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterCustomerOrder?.firstSampleIdList.length > 0
                        ? `First Samples Selected (${filterCustomerOrder?.firstSampleIdList.length})`
                        : "First Sample";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.firstSample.inputValue}
                        onChange={(e) =>
                          updateComponentState("firstSample", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={retrieveSamples}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("firstSample", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  label="First Sample Status"
                  type="search"
                  id="outlined-select-first-sample-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.firstSampleStatusList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        firstSampleStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {sampleStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Autocomplete
                  multiple
                  id="outlined-select-second-sample"
                  disableClearable
                  open={componentsState.secondSample.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("secondSample", { dropdownOpen: true })
                  }
                  inputValue={componentsState.secondSample.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("secondSample", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...samples.filter((sample) =>
                      filterCustomerOrder.secondSampleIdList.includes(
                        sample.id,
                      ),
                    ),
                    ...samples.filter(
                      (sample) =>
                        !filterCustomerOrder.secondSampleIdList.includes(
                          sample.id,
                        ),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.remark || ""}`
                  }
                  value={samples.filter((sample) =>
                    filterCustomerOrder.secondSampleIdList.includes(sample.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      secondSampleIdList: newValue.map((sample) => sample.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.id || ""} - ${option?.remark || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterCustomerOrder?.secondSampleIdList.length > 0
                        ? `Second Samples Selected (${filterCustomerOrder?.secondSampleIdList.length})`
                        : "Second Sample";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.secondSample.inputValue}
                        onChange={(e) =>
                          updateComponentState("secondSample", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={retrieveSamples}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("secondSample", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  label="Second Sample Status"
                  type="search"
                  id="outlined-select-second-sample-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.secondSampleStatusList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        secondSampleStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {sampleStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Packaging"
                  type="search"
                  id="outlined-select-packaging-list"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.packagingList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        packagingList: e.target.value,
                      });
                    },
                  }}
                >
                  {packagingTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-number"
                  label="Net Weight Per Case"
                  value={filterCustomerOrder?.netWeightPerCase || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      netWeightPerCase: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Number Of Cases"
                  value={filterCustomerOrder?.numberOfCases || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      numberOfCases: e.target.value,
                    })
                  }
                />
                            <TextField
                  id="outlined-number"
                  label="Quantity"
                  value={filterCustomerOrder?.volume || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      volume: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Quantity Unit"
                  type="search"
                  id="outlined-select-volume-unit"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.volumeUnitList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        volumeUnitList: e.target.value,
                      });
                    },
                  }}
                >
                  {units.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-number"
                  label="First Offer Price"
                  value={filterCustomerOrder?.firstPrice || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      firstPrice: e.target.value,
                    })
                  }
                />

                <TextField
                  label="First Offer Price Currency"
                  type="search"
                  id="outlined-select-first-offer-price-currency"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.firstPriceCurrencyList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        firstPriceCurrencyList: e.target.value,
                      });
                    },
                  }}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="outlined-number"
                  label="Final Price"
                  value={filterCustomerOrder?.finalPrice || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      finalPrice: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Final Offer Price Currency"
                  type="search"
                  id="outlined-select-final-offer-price-currency"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.finalPriceCurrencyList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        finalPriceCurrencyList: e.target.value,
                      });
                    },
                  }}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="outlined-number"
                  label="Total Amount"
                  value={filterCustomerOrder?.totalAmount || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      totalAmount: e.target.value,
                    })
                  }
                />
                <Autocomplete
                  multiple
                  id="outlined-select-cost"
                  disableClearable
                  open={componentsState.cost.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("cost", { dropdownOpen: true })
                  }
                  inputValue={componentsState.cost.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("cost", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...costs.filter((cost) =>
                      filterCustomerOrder.costIdList.includes(cost.id),
                    ),
                    ...costs.filter(
                      (cost) =>
                        !filterCustomerOrder.costIdList.includes(cost.id),
                    ),
                  ]}
                  getOptionLabel={(option) =>
                    `${option?.id || ""} - ${option?.remark || ""} - ${
                      option?.totalCost || ""
                    }`
                  }
                  value={costs.filter((cost) =>
                    filterCustomerOrder.costIdList.includes(cost.id),
                  )}
                  onChange={(event, newValue) => {
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      costIdList: newValue.map((cost) => cost.id),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.id || ""} - ${option?.remark || ""} - ${
                        option?.totalCost || ""
                      }`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterCustomerOrder?.costIdList.length > 0
                        ? `Costs Selected (${filterCustomerOrder?.costIdList.length})`
                        : "Cost";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.cost.inputValue}
                        onChange={(e) =>
                          updateComponentState("cost", {
                            inputValue: e.target.value,
                          })
                        }
                        onMouseDown={retrieveCosts}
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("cost", { dropdownOpen: false })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  id="date"
                  type="date"
                  label="Contract Value Date"
                  value={filterCustomerOrder?.contractValueDate || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      contractValueDate: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Incoterm"
                  type="search"
                  id="outlined-select-incoterm"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.incotermList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        incotermList: e.target.value,
                      });
                    },
                  }}
                >
                  {incoterms.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Port Of Loading"
                  type="search"
                  id="outlined-select-pol"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.portOfLoadingList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        portOfLoadingList: e.target.value,
                      });
                    },
                  }}
                >
                  {portsOfLoading.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Port Of Discharge"
                  type="search"
                  id="outlined-select-pod"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.portOfDischargeList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        portOfDischargeList: e.target.value,
                      });
                    },
                  }}
                >
                  {portsOfDischarge.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <Autocomplete
                  multiple
                  id="outlined-select-payment-term"
                  disableClearable
                  open={componentsState.paymentTerm.dropdownOpen}
                  onOpen={() =>
                    updateComponentState("paymentTerm", { dropdownOpen: true })
                  }
                  inputValue={componentsState.paymentTerm.inputValue}
                  onInputChange={(event, newInputValue, reason) => {
                    if (reason === "input") {
                      updateComponentState("paymentTerm", {
                        inputValue: newInputValue,
                      });
                    }
                  }}
                  options={[
                    ...paymentTerm.filter((pt) =>
                      filterCustomerOrder.paymentTermList.includes(pt.value),
                    ),
                    ...paymentTerm.filter(
                      (pt) =>
                        !filterCustomerOrder.paymentTermList.includes(pt.value),
                    ),
                  ]}
                  getOptionLabel={(option) => `${option?.label || ""}`}
                  value={paymentTerm.filter((pt) =>
                    filterCustomerOrder.paymentTermList.includes(pt.value),
                  )}
                  onChange={(event, newValue) => {
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      paymentTermList: newValue.map((pt) => pt.value),
                    });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li
                      {...props}
                      style={{
                        ...props.style,
                        backgroundColor: selected ? "lightblue" : undefined,
                        fontWeight: selected ? 700 : 400,
                      }}
                    >
                      {`${option?.label || ""}`}
                    </li>
                  )}
                  renderInput={(params) => {
                    const label =
                      filterCustomerOrder?.paymentTermList.length > 0
                        ? `Payment Terms Selected (${filterCustomerOrder?.paymentTermList.length})`
                        : "Payment Term";

                    return (
                      <TextField
                        {...params}
                        label={label}
                        type="search"
                        value={componentsState.paymentTerm.inputValue}
                        onChange={(e) =>
                          updateComponentState("paymentTerm", {
                            inputValue: e.target.value,
                          })
                        }
                        InputProps={{
                          ...params.InputProps,
                          style: { height: 42 },
                        }}
                        onBlur={() =>
                          updateComponentState("paymentTerm", {
                            dropdownOpen: false,
                          })
                        }
                      />
                    );
                  }}
                  renderTags={() => null}
                />

                <TextField
                  id="date"
                  type="date"
                  label="ETD"
                  value={filterCustomerOrder?.estimatedTimeOfDeparture || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      estimatedTimeOfDeparture: e.target.value,
                    })
                  }
                />
                <TextField
                  id="date"
                  type="date"
                  label="ETA"
                  value={filterCustomerOrder?.estimatedTimeOfArrival || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      estimatedTimeOfArrival: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-search"
                  label="Shipment Schedule"
                  value={filterCustomerOrder?.shipmentSchedule || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      shipmentSchedule: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-search"
                  label="Remark"
                  value={filterCustomerOrder?.remark || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      remark: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-search"
                  label="Comment"
                  value={filterCustomerOrder?.comment || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterCustomerOrder({
                      ...filterCustomerOrder,
                      comment: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Record Status"
                  type="search"
                  id="outlined-select-record-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterCustomerOrder.recordStatusList || [],
                    onChange: (e) => {
                      setFilterCustomerOrder({
                        ...filterCustomerOrder,
                        recordStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    size="small"
                    onClick={() => {
                      retrieveFilteredOrdersApi(filterCustomerOrder)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    size="small"
                    onClick={() => {
                      setFilterCustomerOrder({
                        id: 0,
                        orderType: "CUSTOMER",
                        stakeHolderType: "CUSTOMER",
                        itemBuyingGrade: "",
                        itemIdList: [],
                        stakeholderIdList: [],
                        costIdList: [],
                        offerIdList: [],
                        companyList: [],
                        approvalStatusList: [],
                        orderStatusList: [],
                        customerList: [],
                        firstSampleId: "",
                        secondSampleId: "",
                        costId: "",
                        firstSampleStatusList: [],
                        secondSampleStatusList: [],
                        paymentTerm: "",
                        paymentTermList: [],
                        incotermList: [],
                        portOfLoadingList: [],
                        portOfDischargeList: [],
                        packagingList: [],
                        volumeUnitList: [],
                        firstPriceCurrencyList: [],
                        finalPriceCurrencyList: [],
                        remark: "",
                        shipmentSchedule: "",
                        comment: "",
                        documentStatusList: [],
                        tobaccoCode: "",
                        salesGrade: "",
                        crop: null,
                        nicotine: null,
                        sugar: null,
                        moisture: null,
                        warehouse: null,
                        volume: null,
                        netWeightPerCase: null,
                        numberOfCases: null,
                        quantity: null,
                        firstPrice: null,
                        finalPrice: null,
                        totalAmount: null,
                        statusDeadline: null,
                        contractValueDate: null,
                        estimatedTimeOfDeparture: null,
                        estimatedTimeOfArrival: null,
                        orderGroupNumber: null,
                        firstSampleIdList: [],
                        secondSampleIdList: [],
                        buyingGrade: "",
                        customerGrade: "",
                        originList: [],
                        typeList: [],
                        leafFormList: [],
                        colorList: [],
                        storageList: [],
                        itemStorage: "",
                        recordStatusList: ["ACTIVE"],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
              </Grid>
              <br></br>
            </Box>
          )}

          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                rows={rows}
                setAddView={setAddView}
                selected={selected}
                setExportSoPopup={setExportSoPopup}
                setExportProformaDetailsPopup={setExportProformaDetailsPopup}
                exportSoPopup={exportSoPopup}
                selectedData={selectedData}
              />
              <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  striped="columns"
                  style={{ width: "max-content" }}
                  sx={{
                    minWidth: 750,
                    "& .MuiTableCell-root": {
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    },
                    "& .MuiTableCell-head": {
                      backgroundColor: "#20315d",
                      color: "white",
                      borderRight: "1px solid rgba(49, 64, 128, 1)",
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          style={
                            isSelected(row.id) ? { background: "#d7e7fc" }
                            : index % 2
                              ? { background: "#f0f0f0" }
                              : { background: "white" }
                          }
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              onClick={(e) => {
                                setEditCustomerOrder({
                                  visible: true,
                                  data: row,
                                  index: index,
                                });
                                e.preventDefault();
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              variant="outlined"
                              disabled={row?.approvalStatus === "Approved"}
                              onClick={() => {
                                approveOrder(row?.id);
                              }}
                            >
                              Approve
                            </Button>
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              style={{ whiteSpace: "nowrap" }}
                              variant="outlined"
                              disabled={row?.approvalStatus !== "Approved"}
                              onClick={() =>
                                setShowShipmentRequestPopup({
                                  data: row,
                                  visible: true,
                                })
                              }
                            >
                              Create Shipment Request
                            </Button>
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              style={{ whiteSpace: "nowrap" }}
                              variant="outlined"
                              color="warning"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    `Are you sure you want to cancel SO ${
                                      row?.salesOrderNumber || ""
                                    } for item: ${
                                      row?.item?.buyingGrade
                                    }\nSO Quantity: ${row?.volume || "-"}  ${
                                      row?.volumeUnit || ""
                                    } `,
                                  )
                                ) {
                                  window.location.reload();
                                  cancelOrder(row);
                                }
                              }}
                            >
                              Cancel SO
                            </Button>
                          </TableCell>
                          <TableCell align="center" sx={{ position: 'sticky', left: 0, background: 'inherit', zIndex: 1 }}>{row?.id}</TableCell>
                          <TableCell align="center" sx={{ position: 'sticky', left: 50, background: 'inherit', zIndex: 1 }}>
                            {row?.salesOrderNumber}
                          </TableCell>
                          <TableCell align="center">
                            {row?.orderStatus}
                          </TableCell>
                          <TableCell align="center">
                            {row?.company}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                retrieveItemById(row?.offer?.item?.id);
                              }}
                              onDoubleClick={() => {
                                setTimeout(() => {
                                  setEditItem({
                                    visible: true,
                                    data: item,
                                    index: index,
                                  });
                                }, 1000); // Delay for one second (1000 milliseconds)
                              }}
                            >
                              {row?.offer?.item?.id}
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.tobaccoCode}
                          </TableCell>
                          <TableCell align="center" sx={{ position: 'sticky', left: 180, background: 'inherit', zIndex: 1 }}>
                            {row?.offer?.item?.buyingGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.salesGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.crop}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.nicotine}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.sugar}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.moisture}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.origin}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.storage}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.item?.type}
                          </TableCell>
                          <TableCell align="center">{row?.warehouse}</TableCell>
                          <TableCell align="center">
                            {row?.offer?.stakeholder?.code}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.stakeholder?.name}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                retrieveSampleById(row?.firstSample?.id);
                              }}
                              onDoubleClick={() => {
                                setTimeout(() => {
                                  setEditSample({
                                    visible: true,
                                    data: sample,
                                    index: index,
                                  });
                                }, 1000);
                              }}
                            >
                              {row?.firstSample?.id}
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {row?.firstSample?.deliveryDate
                              ? format(
                                  new Date(row?.firstSample?.deliveryDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.firstSampleStatus}
                          </TableCell>
                          <TableCell align="center">{row?.packaging}</TableCell>
                          <TableCell align="center">
                            {row?.netWeightPerCase}
                          </TableCell>
                          <TableCell align="center">
                            {row?.numberOfCases}
                          </TableCell>
                          <TableCell align="center">  {row?.volume
                              ? `${new Intl.NumberFormat("de-DE", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row.volume)}`
                              : ""}{" "}
                            {row?.volumeUnit} </TableCell>
                          <TableCell align="center">
                            {row?.offer?.firstOfferPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row?.offer?.firstOfferPrice)}`
                              : ""}{" "}
                            {row?.offer?.firstOfferPriceCurrency}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.usdFirstOfferPrice} USD
                          </TableCell>
                          <TableCell align="center">
                            {row?.finalPrice
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row?.finalPrice)}`
                              : ""}{" "}
                            {row?.finalPriceCurrency}
                          </TableCell>
                          <TableCell align="center">
                            {row?.usdFinalPrice} USD
                          </TableCell>
                          <TableCell align="center">
                            {row?.totalAmount
                              ? `${new Intl.NumberFormat("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                }).format(row.totalAmount)}`
                              : ""}{" "}
                            {row?.finalPriceCurrency}
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                retrieveCostById(row?.cost?.id);
                              }}
                              onDoubleClick={() => {
                                setTimeout(() => {
                                  setEditCost({
                                    visible: true,
                                    data: cost,
                                    index: index,
                                  });
                                }, 1000);
                              }}
                            >
                              {row?.cost?.id}
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {row?.cost?.totalCost}
                          </TableCell>
                          <TableCell align="center">
                            {row?.contractValueDate
                              ? format(
                                  new Date(row?.contractValueDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row?.incoterm}</TableCell>
                          <TableCell align="center">
                            {row?.portOfDischarge}
                          </TableCell>
                          <TableCell align="center">
                            {row?.portOfLoading}
                          </TableCell>
                          <TableCell align="center">
                            {row?.paymentTerm}
                          </TableCell>
                          <TableCell align="center">
                            {row?.operationDate
                              ? format(
                                  new Date(row?.operationDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.estimatedTimeOfDeparture
                              ? format(
                                  new Date(row?.estimatedTimeOfDeparture),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.estimatedTimeOfArrival
                              ? format(
                                  new Date(row?.estimatedTimeOfArrival),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="left">
                              {row?.descriptionOfGoods?.length > 50 ? (
                                <span style={{
                                  display: 'inline-block',
                                  maxWidth: '350px', 
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  verticalAlign: 'top',
                                }}
                                title={row?.descriptionOfGoods}>
                                  {row?.descriptionOfGoods}
                                </span>
                              ) : (
                                row?.descriptionOfGoods
                              )}
                            </TableCell>
                          <TableCell align="left">
                              {row?.shipmentSchedule?.length > 100 ? (
                                <span style={{
                                  display: 'inline-block',
                                  maxWidth: '350px', 
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  verticalAlign: 'top',
                                }}
                                title={row?.shipmentSchedule}>
                                  {row?.shipmentSchedule}
                                </span>
                              ) : (
                                row?.shipmentSchedule
                              )}
                            </TableCell>
                          <TableCell align="center">{row?.customerReference}</TableCell>
                          <TableCell align="center">{row?.remark}</TableCell>
                          <TableCell align="center">{row?.comment}</TableCell>
                          <TableCell align="center">
                            <Button
                              onClick={() => {
                                retrieveOfferById(row?.offer?.id);
                              }}
                              onDoubleClick={() => {
                                setTimeout(() => {
                                  setEditCustomerOffer({
                                    visible: true,
                                    data: offer,
                                    index: index,
                                  });
                                }, 1000);
                              }}
                            >
                              {row?.offer?.id}
                            </Button>
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.offerYear +
                              "-" +
                              row?.offer?.offerGroupNumber}
                          </TableCell>
                          <TableCell align="center">
                            {row?.offer?.offerDate
                              ? format(
                                  new Date(row?.offer?.offerDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row?.province}</TableCell>
                          <TableCell align="center">
                            {row?.shippingTerm}
                          </TableCell>
                          <TableCell align="center">
                            {row?.approvalStatus}
                          </TableCell>
                          <TableCell align="center">
                            {row?.timestamp
                              ? format(
                                  new Date(row.timestamp),
                                  "dd/MM/yyyy HH:mm",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">
                            {row?.recordStatus}
                          </TableCell>
                          <TableCell align="center">
                            {row?.editingPermission}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
        </>
      )}
    </div>
  );
}
