import { apiClient } from "./ApiClient";

export const createAddressApi = (address) =>
  apiClient.post(`/stakeholder-address-view/addresses/add`, address);

export const retrieveAllAddressesApi = () => apiClient.get(`/stakeholder-address-view/addresses`);

export const updateAddressApi = (addressId, address) =>
  apiClient.put(`/stakeholder-address-view/addresses/edit/${addressId}`, address);

export const retrieveFilteredAddressesApi = (address) =>
  apiClient.post(`/stakeholder-address-view/addresses/filter`, address);

export const retrieveAddressByIdApi = (addressId) => apiClient.get(`/stakeholder-address-view/addresses/get/${addressId}`);

export const eurUsdExchangeApi = (type, address) => apiClient.post(`/stakeholder-address-view/exchange/${type}/${address}`);
