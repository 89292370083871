import DeleteIcon from "@mui/icons-material/Delete";
import { MenuItem, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { retrieveAllItemsApi } from "../../../components/api/ItemApiService";
import { retrieveAllStakeholdersApi } from "../../../components/api/StakeholderApiService";
import {
  retrieveAllTradeRecordsApi,
  retrieveFilteredRecordsApi,
} from "../../../components/api/TradeApiService";
import ExportExcel from "../../../utils/excelExport";
import {
  color,
  companies,
  incoterms,
  leafForm,
  operationType,
  orderTypes,
  origin,
  recordStatus,
  status,
  type,
  units,
} from "../../../utils/menu-items";
import AddTrade from "./AddTrade";
import EditTrade from "./EditTrade";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "operationType",
    numeric: false,
    disablePadding: true,
    label: "Operation Type",
  },
  {
    id: "operationDate",
    numeric: false,
    disablePadding: true,
    label: "Issue Date",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "orderId",
    numeric: true,
    disablePadding: false,
    label: "Order Id",
  },
  {
    id: "orderType",
    numeric: false,
    disablePadding: false,
    label: "Order Type",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: true,
    label: "Company",
  },
  {
    id: "itemId",
    numeric: true,
    disablePadding: true,
    label: "Item ID",
  },
  {
    id: "itemBuyingGrade",
    numeric: false,
    disablePadding: true,
    label: "Buying Grade",
  },
  {
    id: "itemSgiGrade",
    numeric: false,
    disablePadding: true,
    label: "Sales Grade",
  },
  {
    id: "itemCustomerGrade",
    numeric: false,
    disablePadding: true,
    label: "Customer Grade",
  },
  {
    id: "itemNicotine",
    numeric: true,
    disablePadding: true,
    label: "Nicotine",
  },
  {
    id: "itemSugar",
    numeric: true,
    disablePadding: true,
    label: "Sugar",
  },
  {
    id: "itemMoisture",
    numeric: true,
    disablePadding: true,
    label: "Moisture",
  },
  {
    id: "itemOrigin",
    numeric: false,
    disablePadding: true,
    label: "Origin",
  },
  {
    id: "itemType",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "stakeholderType",
    numeric: true,
    disablePadding: true,
    label: "Business Partner Type",
  },
  {
    id: "sapStakeholderCode",
    numeric: true,
    disablePadding: true,
    label: "SAP Business Partner Code",
  },
  {
    id: "stakeholderName",
    numeric: true,
    disablePadding: true,
    label: "Business Partner Name",
  },
  {
    id: "warehouse",
    numeric: false,
    disablePadding: false,
    label: "Warehouse",
  },
  {
    id: "storage",
    numeric: false,
    disablePadding: false,
    label: "Current Location",
  },
  {
    id: "volume",
    numeric: true,
    disablePadding: false,
    label: "Volume",
  },
  {
    id: "numberOfCases",
    numeric: true,
    disablePadding: false,
    label: "Number Of Cases",
  },
  {
    id: "caseNetWeight",
    numeric: true,
    disablePadding: false,
    label: "Net Weight Per Case",
  },
  {
    id: "incoterm",
    numeric: false,
    disablePadding: false,
    label: "Incoterm",
  },
  {
    id: "firstPrice",
    numeric: true,
    disablePadding: false,
    label: "First Price",
  },
  {
    id: "finalPrice",
    numeric: true,
    disablePadding: false,
    label: "Final Price",
  },
  {
    id: "totalAmount",
    numeric: true,
    disablePadding: true,
    label: "Total Amount",
  },
  {
    id: "invoiceDate",
    numeric: false,
    disablePadding: false,
    label: "Invoice Date",
  },
  {
    id: "purchaseOrderNumber",
    numeric: true,
    disablePadding: false,
    label: "SAP Purchase Order Number",
  },
  {
    id: "salesOrderNumber",
    numeric: true,
    disablePadding: false,
    label: "SAP Sales Order ID",
  },
  {
    id: "shippingInstructionId",
    numeric: true,
    disablePadding: false,
    label: "Shipping Instruction ID",
    width: 150,
  },
  {
    id: "billOfLadingNumber",
    numeric: false,
    disablePadding: false,
    label: "BL Number",
  },
  {
    id: "billOfLadingDate",
    numeric: false,
    disablePadding: false,
    label: "BL Date",
  },
  {
    id: "shipmentNetKg",
    numeric: true,
    disablePadding: false,
    label: "Shipment Net Kg",
  },
  {
    id: "destination",
    numeric: false,
    disablePadding: false,
    label: "Destination",
  },
  {
    id: "shipmentDate",
    numeric: false,
    disablePadding: false,
    label: "Shipment Date",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: true,
    label: "Remark",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "recordStatus",
    numeric: false,
    disablePadding: true,
    label: "Record Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, showFilters, setShowFilters, selected,
    selectedData } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" spacing={1} alignItems={"center"}>
        <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
          variant="h6"
          id="tableTitle"
          component="div"
          color="#1E2685"
        >
          Trade History
        </Typography>

         <Typography></Typography>
          {numSelected > 0 ? (
          <Typography
          sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (<Typography></Typography>)}
          

      {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      </Stack>
      <Stack direction={"row"} spacing={1} sx={{ mr: 2 }}>
      <Button
        variant="outlined"
        onClick={() => setShowFilters(!showFilters)}
        sx={{ minWidth: "130px" }}
        size="small"
      >
        {showFilters ? "Hide Filters" : "Show Filters"}
      </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function TradeView() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [addView, setAddView] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [rows, setRows] = useState([]);
  const [items, setItems] = useState([]);
  const [stakeholders, setStakeholders] = useState([]);
  const [showFilters, setShowFilters] = useState();
  const [result, setResult] = useState(0);
  const [resultCase, setResultCase] = useState(0);

  useEffect(() => {
    function subtract(data) {
      const purchaseSum = data
        .filter((item) => item.operationType === "PURCHASE")
        .map((item) => item?.order?.volume)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      const saleSum = data
        .filter((item) => item.operationType === "SALE")
        .map((item) => item?.order?.volume)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      return purchaseSum - saleSum;
    }
    function subtractCase(data) {
      const purchaseSum = data
        .filter((item) => item.operationType === "PURCHASE")
        .map((item) => item?.order?.numberOfCases)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      const saleSum = data
        .filter((item) => item.operationType === "SALE")
        .map((item) => item?.order?.numberOfCases)
        .reduce((acc, currentValue) => acc + currentValue, 0);

      return purchaseSum - saleSum;
    }
    setResult(subtract(rows));
    setResultCase(subtractCase(rows));
  }, [rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [filterTrade, setFilterTrade] = useState({
    id: 0,
    operationTypeList: [],
    statusList: [],
    orderTypeList: [],
    offerIdList: [],
    companyList: [],
    approvalStatusList: [],
    orderStatusList: [],
    itemIdList: [],
    stakeholderIdList: [],
    customerList: [],
    firstSampleId: "",
    secondSampleId: "",
    costId: "",
    warehouse: "",
    storage: "",
    firstSampleStatusList: [],
    secondSampleStatusList: [],
    paymentTerm: "",
    paymentTermList: [],
    incotermList: [],
    packagingList: [],
    volumeUnitList: [],
    remark: "",
    documentStatusList: [],
    tobaccoCode: "",
    salesGrade: "",
    buyingGrade: "",
    customerGrade: "",
    originList: [],
    typeList: [],
    leafFormList: [],
    colorList: [],
    storageList: [],
    recordStatusList: ["ACTIVE"],
  });

  useEffect(() => retrieveTrades(), []);

  function retrieveTrades() {
    retrieveAllTradeRecordsApi()
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveStakeholders() {
    Promise.all([
      retrieveAllStakeholdersApi("VENDOR"),
      retrieveAllStakeholdersApi("CUSTOMER")
    ])
      .then(([vendorResponse, customerResponse]) => {
        setStakeholders([
          ...vendorResponse?.data,
          ...customerResponse?.data
        ]);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [editTrade, setEditTrade] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }else{
      setSelected([]);
      setSelectedData([]);
    }
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editTrade],
  );

  return (
    <div>
      {addView ? (
        <AddTrade
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
        />
      ) : editTrade.visible ? (
        <EditTrade
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editTrade={editTrade}
          setEditTrade={setEditTrade}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  label="Operation Type"
                  type="search"
                  id="outlined-select-operation-type"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.operationTypeList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        operationTypeList: e.target.value,
                      });
                    },
                  }}
                >
                  {operationType.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="date"
                  type="date"
                  label="Issue Date"
                  value={filterTrade?.operationDate || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      operationDate: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Status"
                  type="search"
                  id="outlined-select-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.statusList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        statusList: e.target.value,
                      });
                    },
                  }}
                >
                  {status.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Order Type"
                  type="search"
                  id="outlined-select-order-type"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.orderTypeList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        orderTypeList: e.target.value,
                      });
                    },
                  }}
                >
                  {orderTypes.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Company"
                  type="search"
                  id="outlined-select-company"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.companyList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        companyList: e.target.value,
                      });
                    },
                  }}
                >
                  {companies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Item"
                  type="search"
                  id="outlined-select-item"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.itemIdList || [],
                    onMouseDown: (e) => {
                      retrieveItems();
                    },
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        itemIdList: e.target.value,
                      });
                    },
                  }}
                >
                  <MenuItem>-</MenuItem>
                  {items.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.id} - {option.buyingGrade} - {option.salesGrade} -{" "}
                      {option.crop}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-search"
                  label="Tobacco Code"
                  value={filterTrade?.tobaccoCode || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      tobaccoCode: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Sales Grade"
                  value={filterTrade?.salesGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      salesGrade: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Buying Grade"
                  value={filterTrade?.buyingGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      buyingGrade: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Customer Grade"
                  value={filterTrade?.customerGrade || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      customerGrade: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Origin"
                  type="search"
                  id="outlined-select-origin"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.originList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        originList: e.target.value,
                      });
                    },
                  }}
                >
                  {origin.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Type"
                  type="search"
                  id="outlined-select-type"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.typeList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        typeList: e.target.value,
                      });
                    },
                  }}
                >
                  {type.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Leaf Form"
                  type="search"
                  id="outlined-select-leaf-form"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.leafFormList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        leafFormList: e.target.value,
                      });
                    },
                  }}
                >
                  {leafForm.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Color"
                  type="search"
                  id="outlined-select-color"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.colorList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        colorList: e.target.value,
                      });
                    },
                  }}
                >
                  {color.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-search"
                  label="Crop Year"
                  value={filterTrade?.crop || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      crop: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Nicotine"
                  value={filterTrade?.nicotine || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      nicotine: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Sugar"
                  value={filterTrade?.sugar || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      sugar: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Sender"
                  type="search"
                  id="outlined-select-stakeholder"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.stakeholderIdList || [],
                    onMouseDown: (e) => {
                      retrieveStakeholders();
                    },
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        stakeholderIdList: e.target.value,
                      });
                    },
                  }}
                >
                  {stakeholders.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.id} - {option.type} - {option.name}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="outlined-number"
                  label="Warehouse"
                  value={filterTrade?.warehouse || ""}
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      warehouse: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  value={filterTrade?.storage || ""}
                  label="Current Location"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      storage: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  value={filterTrade?.volume || ""}
                  label="Volume"
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      volume: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Volume Unit"
                  type="search"
                  id="outlined-select-volume-unit"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.volumeUnitList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        volumeUnitList: e.target.value,
                      });
                    },
                  }}
                >
                  {units.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-number"
                  label="Number Of Cases"
                  value={filterTrade?.numberOfCases || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      numberOfCases: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Net Weight Per Case"
                  value={filterTrade?.netWeightPerCase || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      netWeightPerCase: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Incoterm"
                  type="search"
                  id="outlined-select-incoterm"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.incotermList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        incotermList: e.target.value,
                      });
                    },
                  }}
                >
                  {incoterms.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="outlined-number"
                  label="First Price"
                  value={filterTrade?.firstOfferPrice || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      firstOfferPrice: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Final Price"
                  value={filterTrade?.finalPrice || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      finalPrice: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Total Amount"
                  value={filterTrade?.totalAmount || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      totalAmount: e.target.value,
                    })
                  }
                />
                <TextField
                  id="date"
                  type="date"
                  label="Invoice Date"
                  value={filterTrade?.invoiceDate || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      invoiceDate: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="SAP PO Number"
                  value={filterTrade?.purchaseOrderNumber || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      purchaseOrderNumber: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="SAP SO Number"
                  value={filterTrade?.salesOrderNumber || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      salesOrderNumber: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="SI Id"
                  value={filterTrade?.shippingInstructionId || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      shippingInstructionId: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="BL Number"
                  value={filterTrade?.billOfLadingNumber || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      billOfLadingNumber: e.target.value,
                    })
                  }
                />
                <TextField
                  id="date"
                  type="date"
                  label="BL Date"
                  value={filterTrade?.billOfLadingDate || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      billOfLadingDate: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Destination"
                  value={filterTrade?.shipmentDestination || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      shipmentDestination: e.target.value,
                    })
                  }
                />
                <TextField
                  id="date"
                  type="date"
                  label="Shipment Date"
                  value={filterTrade?.shipmentDate || ""}
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      shipmentDate: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Quantity Remaining (Case)"
                  value={filterTrade?.quantityRemainingInNetCase || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      quantityRemainingInNetCase: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Quantity Remaining (Kg)"
                  value={filterTrade?.quantityRemainingInNetKg || ""}
                  type="number"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      quantityRemainingInNetKg: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-search"
                  label="Remark"
                  value={filterTrade?.remark || ""}
                  type="search"
                  onChange={(e) =>
                    setFilterTrade({
                      ...filterTrade,
                      remark: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Record Status"
                  type="search"
                  id="outlined-select-record-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterTrade.recordStatusList || [],
                    onChange: (e) => {
                      setFilterTrade({
                        ...filterTrade,
                        recordStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <br></br>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      retrieveFilteredRecordsApi(filterTrade)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    size="small"
                    onClick={() => {
                      setFilterTrade({
                        id: 0,
                        operationTypeList: [],
                        statusList: [],
                        orderTypeList: [],
                        offerIdList: [],
                        companyList: [],
                        approvalStatusList: [],
                        orderStatusList: [],
                        itemIdList: [],
                        stakeholderIdList: [],
                        operationDate: null,
                        statusDeadline: null,
                        invoiceDate: null,
                        billOfLadingDate: null,
                        shipmentDate: null,
                        customerList: [],
                        firstSampleId: "",
                        secondSampleId: "",
                        costId: "",
                        warehouse: "",
                        storage: "",
                        firstSampleDate: null,
                        secondSampleDate: null,
                        firstSampleStatusList: [],
                        secondSampleStatusList: [],
                        paymentTerm: "",
                        paymentTermList: [],
                        incotermList: [],
                        packagingList: [],
                        orderDate: null,
                        validityDate: null,
                        volumeUnitList: [],
                        remark: "",
                        documentStatusList: [],
                        tobaccoCode: "",
                        salesGrade: "",
                        buyingGrade: "",
                        customerGrade: "",
                        crop: null,
                        nicotine: null,
                        sugar: null,
                        volume: null,
                        numberOfCases: null,
                        netWeightPerCase: null,
                        firstOfferPrice: null,
                        finalPrice: null,
                        totalAmount: null,
                        purchaseOrderNumber: null,
                        salesOrderNumber: null,
                        shippingInstructionId: null,
                        billOfLadingNumber: null,
                        quantityRemainingInNetCase: null,
                        quantityRemainingInNetKg: null,
                        originList: [],
                        typeList: [],
                        leafFormList: [],
                        colorList: [],
                        storageList: [],
                        recordStatusList: ["ACTIVE"],
                      });
                    }}
                  >
                    Clear Filters
                  </Button>
                </Grid>
                <Grid item xs={3} sm="auto">
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    onClick={() => setAddView(true)}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <ExportExcel excelData={selectedData} fileName={"HistoryExport"} buttonLabel="Export Table"/>
                </Grid>
              </Grid>
              <br></br>
            </Box>
          )}

          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                selected={selected}
                selectedData={selectedData}
              />
              <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  striped="columns"
                  style={{ width: "max-content" }}
                  sx={{
                    minWidth: 750,
                    '& .MuiTableCell-head': {
                      backgroundColor: '#20315d',
                      color: 'white',
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#f0f0f0" }
                              : { background: "white" }
                          }
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              onClick={(e) => {
                                setEditTrade({
                                  visible: true,
                                  data: row,
                                  index: index,
                                });
                                e.preventDefault();
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell align="center">{row?.id}</TableCell>
                          <TableCell align="center">
                            {row?.operationType}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.operationDate
                              ? format(
                                  new Date(row?.order?.operationDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.orderStatus}
                          </TableCell>
                          <TableCell align="center">{row?.order?.id}</TableCell>
                          <TableCell align="center">
                            {row?.order?.orderType}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.company}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.item?.id}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.item?.buyingGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.item?.salesGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.item?.customerGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.item?.nicotine}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.item?.sugar}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.item?.moisture}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.item?.origin}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.item?.type}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.stakeholder?.type}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.stakeholder?.code}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.stakeholder?.name}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.warehouse}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.item?.storage}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.volume ? `${new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.order?.volume)} ${row?.order?.volumeUnit}`: ''}
                            </TableCell>
                          <TableCell align="center">
                            {row?.order?.numberOfCases}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.netWeightPerCase}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.incoterm}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.offer?.firstOfferPrice ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.order?.offer?.firstOfferPrice)} ${row?.order?.offer?.firstOfferPriceCurrency}`: ''}
                          </TableCell>
                          <TableCell align="center">
                          {row?.order?.finalPrice ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.order?.finalPrice)} ${row?.order?.finalPriceCurrency}`: ''}
                          </TableCell>
                          <TableCell align="center">
                          {row?.order?.totalAmount ? `${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2 }).format(row?.order?.totalAmount)} ${row?.order?.finalPriceCurrency}`: ''}
                          </TableCell>
                          <TableCell align="center">
                            {row?.invoiceDate
                              ? format(new Date(row?.invoiceDate), "dd/MM/yyyy")
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.purchaseOrderNumber}
                          </TableCell>
                          <TableCell align="center">
                            {row?.order?.salesOrderNumber}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingInstructionId}
                          </TableCell>
                          <TableCell align="center">
                            {row?.billOfLadingNumber}
                          </TableCell>
                          <TableCell align="center">
                            {row?.billOfLadingDate
                              ? format(
                                  new Date(row?.billOfLadingDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentNetKg}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentDestination}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shipmentDate
                              ? format(
                                  new Date(row?.shipmentDate),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>

                          <TableCell align="center">{row?.remark}</TableCell>
                          <TableCell align="center">{row?.timestamp ? format(new Date(row.timestamp), 'dd/MM/yyyy HH:mm'): ''}</TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">
                            {row?.recordStatus}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <Stack direction="row" spacing={1} alignItems={"center"}>
              <FormControlLabel
                control={
                  <Switch checked={dense} onChange={handleChangeDense} />
                }
                label="Dense padding"
              />
              <Typography variant="subtitle2">
                Quantity remaining in net kg: {result}
              </Typography>
              <Typography variant="subtitle2">
                Quantity remaining in net case: {resultCase}
              </Typography>
            </Stack>
          </Box>
        </>
      )}
    </div>
  );
}
