import { apiClient } from "./ApiClient";

export const retrieveAllOffersApi = (offerType) =>
  apiClient.get(`/api/offer-view/offers/${offerType}`);

export const createOfferApi = (offer) =>
  apiClient.post(`/api/offer-view/add`, offer);

export const createMultipleOffersApi = (offerList) =>
  apiClient.post(`/api/offer-view/add-multiple`, offerList);

export const updateOfferApi = (offerId, offer) =>
  apiClient.put(`/api/offer-view/edit/${offerId}`, offer);

export const approveOfferApi = (offerId) =>
  apiClient.put(`/api/offer-view/approve/${offerId}`);

export const retrieveFilteredOffersApi = (offer) =>
  apiClient.post(`/api/offer-view/filter`, offer);

export const retrieveSelectedOffersByIdApi = (offerIdList, offerType) =>
  apiClient.post(`/api/offer-view/offers/get-selected/${offerType}`, offerIdList);

export const retrieveMaxOfferNumberApi = (offerType) =>
  apiClient.get(`/api/offer-view/max-offer-number/${offerType}`);

export const retrieveOfferByIdApi = (offerId) => 
  apiClient.get(`/api/offer-view/offers/get/${offerId}`);

export const deleteOffersApi = (offerIdList) =>
  apiClient.put(`/api/offer-view/delete-list`, offerIdList);