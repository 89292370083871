import DeleteIcon from "@mui/icons-material/Delete";
import { MenuItem, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { format } from "date-fns";
import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import { retrieveAllCostsApi } from "../../../components/api/CostApiService";
import {
  retrieveAllInventoryApi,
  retrieveFilteredInventoryApi,
} from "../../../components/api/InventoryApiService";
import { retrieveAllItemsApi } from "../../../components/api/ItemApiService";
import ExportExcel from "../../../utils/excelExport";
import AddInventory from "./AddInventory";
import EditInventory from "./EditInventory";
import {
  color,
  companies,
  incoterms,
  leafForm,
  offerStatus,
  origin,
  paymentTerm,
  recordStatus,
  sampleStatus,
  status,
  type,
  units,
} from "../../../utils/menu-items";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const companyList = [
  {
    value: "SGE",
    label: "SGE",
  },
  {
    value: "SGI",
    label: "SGI",
  },
];

const storage = [
  {
    value: "CHINA",
    label: "CHINA",
  },
  {
    value: "THAILAND-BANGKOK",
    label: "THAILAND-BANGKOK",
  },
  {
    value: "BANGLADESH",
    label: "BANGLADESH",
  },
  {
    value: "BRAZIL",
    label: "BRAZIL",
  },
];

const headCells = [
  {
    id: "editButton",
    disablePadding: true,
    label: "",
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "ownership",
    numeric: false,
    disablePadding: true,
    label: "Ownership",
  },
  {
    id: "itemId",
    numeric: false,
    disablePadding: true,
    label: "Item ID",
  },
  {
    id: "buyingGrade",
    numeric: false,
    disablePadding: true,
    label: "Buying Grade",
  },
  {
    id: "salesGrade",
    numeric: false,
    disablePadding: true,
    label: "Sales Grade",
  },
  {
    id: "customerGrade",
    numeric: false,
    disablePadding: true,
    label: "Customer Grade",
  },
  {
    id: "nicotine",
    numeric: true,
    disablePadding: true,
    label: "Nicotine",
  },
  {
    id: "sugar",
    numeric: true,
    disablePadding: true,
    label: "Sugar",
  },
  {
    id: "moisture",
    numeric: true,
    disablePadding: true,
    label: "Moisture",
  },
  {
    id: "origin",
    numeric: false,
    disablePadding: true,
    label: "Origin",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
  },
  {
    id: "quantityRemainingCase",
    numeric: true,
    disablePadding: true,
    label: "Quantity Remaining (Case)",
  },
  {
    id: "quantityRemainingKg",
    numeric: true,
    disablePadding: true,
    label: "Quantity Remaining",
  },
  {
    id: "quantityRemainingInVendorCase",
    numeric: true,
    disablePadding: true,
    label: "Quantity Remaining In Vendor (Case)",
  },
  {
    id: "quantityRemainingInVendorKg",
    numeric: true,
    disablePadding: true,
    label: "Quantity Remaining In Vendor",
  },
  {
    id: "quantityReservedInCase",
    numeric: true,
    disablePadding: true,
    label: "Quantity Reserved To Customer (Case)",
  },
  {
    id: "quantityReserevdInKg",
    numeric: true,
    disablePadding: true,
    label: "Quantity Reserved To Customer",
  },
  {
    id: "quantityRemainingInWarehouseCase",
    numeric: true,
    disablePadding: true,
    label: "Quantity Remaining In Warehouse (Case)",
  },
  {
    id: "quantityRemainingInWarehouseKg",
    numeric: true,
    disablePadding: true,
    label: "Quantity Remaining In Warehouse",
  },
  {
    id: "quantitySentToProductionCase",
    numeric: true,
    disablePadding: true,
    label: "Quantity Sent To Production (Case)",
  },
  {
    id: "quantitySentToProductionKg",
    numeric: true,
    disablePadding: true,
    label: "Quantity Sent To Production",
  },
  {
    id: "quantitySoldToProductionCase",
    numeric: true,
    disablePadding: true,
    label: "Quantity Sold To Production (Case)",
  },
  {
    id: "quantitySoldToProductionKg",
    numeric: true,
    disablePadding: true,
    label: "Quantity Sold To Production",
  },
  {
    id: "storage",
    numeric: false,
    disablePadding: false,
    label: "Storage",
  },
  {
    id: "storageType",
    numeric: false,
    disablePadding: false,
    label: "Storage Type",
  },
  {
    id: "costId",
    numeric: true,
    disablePadding: false,
    label: "Cost ID",
  },
  {
    id: "storageCost",
    numeric: true,
    disablePadding: false,
    label: "Storage Cost",
  },
  {
    id: "paymentAmount",
    numeric: true,
    disablePadding: true,
    label: "Payment Amount",
  },
  {
    id: "paymentStatus",
    numeric: false,
    disablePadding: true,
    label: "Payment Status",
  },
  {
    id: "remainingPayment",
    numeric: true,
    disablePadding: false,
    label: "Remaining Payment",
  },
  {
    id: "paymentDeadline",
    numeric: false,
    disablePadding: false,
    label: "Payment Deadline",
  },
  {
    id: "inventoryType",
    numeric: false,
    disablePadding: false,
    label: "Inventory Type",
  },
  {
    id: "entryDate",
    numeric: false,
    disablePadding: false,
    label: "Entry Date",
  },
  {
    id: "exitDate",
    numeric: false,
    disablePadding: false,
    label: "Exit Date",
  },
  {
    id: "shippingDeadline",
    numeric: false,
    disablePadding: false,
    label: "Shipping Deadline",
  },
  {
    id: "rejection",
    numeric: false,
    disablePadding: false,
    label: "Rejection",
  },
  {
    id: "remark",
    numeric: false,
    disablePadding: false,
    label: "Remark",
  },
  {
    id: "creationTime",
    numeric: false,
    disablePadding: true,
    label: "Creation Time",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: true,
    label: "Created By",
  },
  {
    id: "recordStatus",
    numeric: false,
    disablePadding: false,
    label: "Record Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, showFilters, setShowFilters, rows, selected,
    selectedData } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" spacing={1} alignItems={"center"}>
          <Typography
          sx={{ fontWeight: 600, fontSize: "20px" }}
            variant="h6"
            id="tableTitle"
            component="div"
            color="#1E2685"
          >
            Inventory
          </Typography>

          <Typography></Typography>
          {numSelected > 0 ? (
          <Typography
          sx={{ fontWeight: 200, fontSize: "16px" }}
            color="#1E2685"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (<Typography></Typography>)}
        
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      <Stack direction={"row"} spacing={1} sx={{ mr: 2 }}>
        <ExportExcel excelData={selectedData} fileName={"InventoryExport"} buttonLabel="Export Table"/>

        <Button disableElevation variant="outlined" size="small">
          Add
        </Button>
        <Button
          variant="outlined"
          onClick={() => setShowFilters(!showFilters)}
          sx={{ minWidth: "130px" }}
          size="small"
        >
          {showFilters ? "Hide Filters" : "Show Filters"}
        </Button>
      </Stack>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function InventoryView() {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [showFilters, setShowFilters] = useState(false);
  const [addView, setAddView] = useState(false);
  const [addViewStates, setAddViewStates] = useState(null);
  const [rows, setRows] = useState([]);
  const [items, setItems] = useState([]);
  const [costs, setCosts] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const [filterInventory, setFilterInventory] = useState({
    id: 0,
    ownershipList: [],
    itemIdList: [],
    costIdList: [],
    storageList: [],
    inventoryTypeList: [],
    storageTypeList: [],
    paymentStatusList: [],
    originList: [],
    remark: "",
    recordStatusList: ["ACTIVE"],
  });

  useEffect(() => retrieveInventory(), []);

  function retrieveInventory() {
    retrieveAllInventoryApi()
      .then((response) => {
        setRows(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveItems() {
    retrieveAllItemsApi()
      .then((response) => {
        setItems(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  function retrieveCosts() {
    retrieveAllCostsApi()
      .then((response) => {
        setCosts(response?.data);
      })
      .catch((error) => console.log(error.response?.data));
  }

  const [editInventory, setEditInventory] = useState({
    visible: false,
    data: null,
    index: null,
  });

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      setSelectedData(rows);
      return;
    }else{
      setSelected([]);
      setSelectedData([]);
    }
  };

  const handleClick = (event, name, row) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    const selectedDataIndex = selectedData.indexOf(row);
    let newSelectedData = [];

    if (selectedDataIndex === -1) {
      newSelectedData = newSelectedData.concat(selectedData, row);
    } else if (selectedDataIndex === 0) {
      newSelectedData = newSelectedData.concat(selectedData.slice(1));
    } else if (selectedDataIndex === selectedData.length - 1) {
      newSelectedData = newSelectedData.concat(selectedData.slice(0, -1));
    } else if (selectedDataIndex > 0) {
      newSelectedData = newSelectedData.concat(
        selectedData.slice(0, selectedDataIndex),
        selectedData.slice(selectedDataIndex + 1),
      );
    }
    setSelectedData(newSelectedData);

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows, editInventory],
  );

  return (
    <div>
      {addView ? (
        <AddInventory
          setAddViewStates={setAddViewStates}
          addViewStates={addViewStates}
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
        />
      ) : editInventory.visible ? (
        <EditInventory
          rows={rows}
          setRows={setRows}
          setAddView={setAddView}
          editInventory={editInventory}
          setEditInventory={setEditInventory}
        />
      ) : (
        <>
          {showFilters && (
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <Grid
                container
                spacing={1}
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  label="Ownership"
                  type="search"
                  id="outlined-select-ownership"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterInventory.ownershipList || [],
                    onChange: (e) => {
                      setFilterInventory({
                        ...filterInventory,
                        ownershipList: e.target.value,
                      });
                    },
                  }}
                >
                  {companyList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Item"
                  type="search"
                  id="outlined-select-item"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterInventory.itemIdList || [],
                    onMouseDown: (e) => {
                      retrieveItems();
                    },
                    onChange: (e) => {
                      setFilterInventory({
                        ...filterInventory,
                        itemIdList: e.target.value,
                      });
                    },
                  }}
                >
                  <MenuItem>-</MenuItem>
                  {items.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.id} - {option.buyingGrade} - {option.salesGrade} -{" "}
                      {option.crop}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-number"
                  label="Buying Grade"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      buyingGrade: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Sales Grade"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      salesGrade: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Customer Grade"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      customerGrade: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Origin"
                  type="search"
                  id="outlined-select-origin"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterInventory.originList || [],
                    onChange: (e) => {
                      setFilterInventory({
                        ...filterInventory,
                        originList: e.target.value,
                      });
                    },
                  }}
                >
                  {origin.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-number"
                  label="Nicotine"
                  type="number"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      nicotine: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Sugar"
                  type="number"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      sugar: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Current Location"
                  type="search"
                  id="outlined-select-storage"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterInventory.storageList || [],
                    onChange: (e) => {
                      setFilterInventory({
                        ...filterInventory,
                        storageList: e.target.value,
                      });
                    },
                  }}
                >
                  {storage.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Storage Type"
                  type="search"
                  id="outlined-select-storage-type"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterInventory.storageTypeList || [],
                    onChange: (e) => {
                      setFilterInventory({
                        ...filterInventory,
                        storageTypeList: e.target.value,
                      });
                    },
                  }}
                >
                  {storage.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  label="Cost"
                  type="search"
                  id="outlined-select-cost"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterInventory.costIdList || [],
                    onMouseDown: (e) => {
                      retrieveCosts();
                    },
                    onChange: (e) => {
                      setFilterInventory({
                        ...filterInventory,
                        costIdList: e.target.value,
                      });
                    },
                  }}
                >
                  {costs.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.id} - {option.storageCost}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-number"
                  label="Storage Cost"
                  type="number"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      storageCost: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Payment Amount"
                  type="number"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      paymentAmount: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Payment Status"
                  type="search"
                  id="outlined-select-payment-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterInventory.paymentStatusList || [],
                    onChange: (e) => {
                      setFilterInventory({
                        ...filterInventory,
                        paymentStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {companyList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-number"
                  label="Remaining Payment"
                  type="number"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      remainingPayment: e.target.value,
                    })
                  }
                />
                <TextField
                  id="date"
                  type="date"
                  label="Payment Deadline"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      paymentDeadline: e.target.value,
                    })
                  }
                />

                <TextField
                  label="Inventory Type"
                  type="search"
                  id="outlined-select-inventory-type"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterInventory.inventoryTypeList || [],
                    onChange: (e) => {
                      setFilterInventory({
                        ...filterInventory,
                        inventoryTypeList: e.target.value,
                      });
                    },
                  }}
                >
                  {companyList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>

                <TextField
                  id="outlined-number"
                  label="Net Weight Per Case"
                  type="number"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      netWeightPerCase: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Number Of Cases"
                  type="number"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      numberOfCases: e.target.value,
                    })
                  }
                />

                <TextField
                  id="date"
                  type="date"
                  label="Entry Date"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      entryDate: e.target.value,
                    })
                  }
                />

                <TextField
                  id="date"
                  type="date"
                  label="Exit Date"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      exitDate: e.target.value,
                    })
                  }
                />

                <TextField
                  id="date"
                  type="date"
                  label="Shipping Deadline"
                  inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      shippingDeadline: e.target.value,
                    })
                  }
                />

                <TextField
                  id="outlined-number"
                  label="Shipped Out In Case"
                  type="number"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      shippedOutCase: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Shipped Out In Kg"
                  type="number"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      shippedOutKg: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Shipped In In Case"
                  type="number"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      shippedInCase: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Shipped In In Kg"
                  type="number"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      shippedInKg: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Final Stock"
                  type="number"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      finalStock: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Rejection"
                  type="search"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      rejection: e.target.value,
                    })
                  }
                />
                <TextField
                  id="outlined-number"
                  label="Remark"
                  type="search"
                  onChange={(e) =>
                    setFilterInventory({
                      ...filterInventory,
                      remark: e.target.value,
                    })
                  }
                />
                <TextField
                  label="Record Status"
                  type="search"
                  id="outlined-select-record-status"
                  select
                  SelectProps={{
                    multiple: true,
                    value: filterInventory.recordStatusList || [],
                    onChange: (e) => {
                      setFilterInventory({
                        ...filterInventory,
                        recordStatusList: e.target.value,
                      });
                    },
                  }}
                >
                  {recordStatus.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <br></br>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={3} sm={1}>
                  <Button
                    disableElevation
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      retrieveFilteredInventoryApi(filterInventory)
                        .then((response) => {
                          setRows(response?.data);
                        })
                        .catch((error) => {
                          console.log(error?.response?.data);
                        });
                    }}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={3} sm="auto">
                  <Button
                    disableElevation
                    variant="outlined"
                    fullWidth
                    onClick={() => setAddView(true)}
                  >
                    Add
                  </Button>
                </Grid>
                <Grid item xs="auto">
                  <ExportExcel excelData={rows} fileName={"ExcelExport"} buttonLabel="Export Table"/>
                </Grid>
              </Grid>
            </Box>
          )}

          <Box sx={{ width: "100%", mt: 2 }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <EnhancedTableToolbar
                numSelected={selected.length}
                showFilters={showFilters}
                setShowFilters={setShowFilters}
                rows={rows}
                selected={selected}
                selectedData={selectedData}
              />
             <TableContainer style={{ maxHeight: 700 }}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  sx={{
                    minWidth: 750,
                    '& .MuiTableCell-head': {
                      backgroundColor: '#20315d',
                      color: 'white',
                    },
                  }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                  style={{ width: "max-content" }}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {visibleRows.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          style={
                            index % 2
                              ? { background: "#f0f0f0" }
                              : { background: "white" }
                          }
                          hover
                          onClick={(event) => handleClick(event, row.id, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                "aria-labelledby": labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell padding="normal">
                            <Button
                              onClick={(e) => {
                                setEditInventory({
                                  visible: true,
                                  data: row,
                                  index: index,
                                });
                                e.preventDefault();
                              }}
                            >
                              Edit
                            </Button>
                          </TableCell>
                          <TableCell align="center">{row?.id}</TableCell>
                          <TableCell align="center">{row?.ownership}</TableCell>
                          <TableCell align="center">{row?.quantity?.item?.id}</TableCell>
                          <TableCell align="center">
                            {row?.quantity?.item?.buyingGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.item?.salesGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.item?.customerGrade}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.item?.nicotine}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.item?.sugar}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.item?.moisture}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.item?.origin}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.item?.type}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.quantityRemainingInNetCase}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.quantityRemainingInNetKg}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.quantityRemainingInVendorInNetCase}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.quantityRemainingInVendorInNetKg}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.quantityReservedToCustomerInNetCase}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.quantityReservedToCustomerInNetKg}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.quantityRemainingInWarehouseInNetCase}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.quantityRemainingInWarehouseInNetKg}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.quantityRemainingInProductionInNetCase}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.getQuantityRemainingInProductionInNetKg}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.quantitySoldToProductionInNetCase}
                          </TableCell>
                          <TableCell align="center">
                            {row?.quantity?.quantitySoldToProductionInNetKg}
                          </TableCell>
                          <TableCell align="center">{row?.storage}</TableCell>
                          <TableCell align="center">
                            {row?.storageType}
                          </TableCell>
                          <TableCell align="center">{row?.cost?.id}</TableCell>
                          <TableCell align="center">
                            {row?.cost?.storageCost}
                          </TableCell>
                          <TableCell align="center">
                            {row?.paymentAmount}
                          </TableCell>
                          <TableCell align="center">
                            {row?.paymentStatus}
                          </TableCell>
                          <TableCell align="center">
                            {row?.remainingPayment}
                          </TableCell>
                          <TableCell align="center">
                            {row?.paymentDeadline
                              ? format(
                                  new Date(row?.paymentDeadline),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.inventoryType}
                          </TableCell>
                          <TableCell align="center">
                            {row?.entryDate
                              ? format(new Date(row?.entryDate), "dd/MM/yyyy")
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.exitDate
                              ? format(new Date(row?.exitDate), "dd/MM/yyyy")
                              : ""}
                          </TableCell>
                          <TableCell align="center">
                            {row?.shippingDeadline
                              ? format(
                                  new Date(row?.shippingDeadline),
                                  "dd/MM/yyyy",
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="center">{row?.rejection}</TableCell>
                          <TableCell align="center">{row?.remark}</TableCell>
                          <TableCell align="center">{row?.timestamp ? format(new Date(row.timestamp), 'dd/MM/yyyy HH:mm'): ''}</TableCell>
                          <TableCell align="center">{row?.creator}</TableCell>
                          <TableCell align="center">
                            {row?.recordStatus}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
          </Box>
        </>
      )}
    </div>
  );
}
