import { Autocomplete, Grid, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import {
  color,
  companies,
  incoterms,
  leafForm,
  offerStatus,
  origin,
  paymentTerm,
  recordStatus,
  sampleStatus,
  status,
  type,
  currencies,
  units,
  portsOfLoading,
  portsOfDischarge,
} from "../../../utils/menu-items";

const CustomerOfferSearch = (props) => {
  const {
    setFilterCustomerOffer,
    filterCustomerOffer,
    retrieveItems,
    items,
    retrieveVendorOffers,
    referenceVendorOffers,
    retrieveCustomers,
    customers,
    retrieveCosts,
    costs,
    retrieveSamples,
    samples,
  } = props;

  const [componentsState, setComponentsState] = useState({
    origin: {
      dropdownOpen: false,
      inputValue: "",
    },
    salesOrigin: {
      dropdownOpen: false,
      inputValue: "",
    },
    item: {
      dropdownOpen: false,
      inputValue: "",
    },
    customer: {
      dropdownOpen: false,
      inputValue: "",
    },
    type: {
      dropdownOpen: false,
      inputValue: "",
    },
    leafForm: {
      dropdownOpen: false,
      inputValue: "",
    },
    storage: {
      dropdownOpen: false,
      inputValue: "",
    },
    cost: {
      dropdownOpen: false,
      inputValue: "",
    },
    firstSample: {
      dropdownOpen: false,
      inputValue: "",
    },
    secondSample: {
      dropdownOpen: false,
      inputValue: "",
    },
    paymentTerm: {
      dropdownOpen: false,
      inputValue: "",
    },
     portOfDischarge: {
      dropdownOpen: false,
      inputValue: "",
    },
    portOfLoading: {
      dropdownOpen: false,
      inputValue: "",
    },
    referenceVendorOffer: {
      dropdownOpen: false,
      inputValue: "",
    },
  });

  // Function to update state for a specific component
  const updateComponentState = (componentKey, newState) => {
    setComponentsState((prevState) => ({
      ...prevState,
      [componentKey]: {
        ...prevState[componentKey],
        ...newState,
      },
    }));
  };

  return (
    <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
         <TextField
        id="outlined-search"
        label="Offer Year"
        value={filterCustomerOffer?.offerYear || ""}
        type="number"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            offerYear: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-search"
        label="Offer Number"
        value={filterCustomerOffer?.offerGroupNumber || ""}
        type="number"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            offerGroupNumber: e.target.value,
          })
        }
      />
      <Autocomplete
        multiple
        id="outlined-select-vendor-offer"
        disableClearable
        open={componentsState.referenceVendorOffer.dropdownOpen}
        onOpen={() => updateComponentState("referenceVendorOffer", { dropdownOpen: true })}
        inputValue={componentsState.referenceVendorOffer.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("referenceVendorOffer", { inputValue: newInputValue });
          }
        }}
        options={referenceVendorOffers}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.offerYear || ""} - ${
            option?.offerGroupNumber || ""
          } Item: ${option?.item?.id || ""} - ${option?.item?.buyingGrade || ""}`
        }
        value={referenceVendorOffers.filter((referenceVendorOffer) =>
          filterCustomerOffer.referenceVendorOfferIdList.includes(referenceVendorOffer.id),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            referenceVendorOfferIdList: newValue.map((referenceVendorOffer) => referenceVendorOffer.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.offerYear || ""} - ${
              option?.offerGroupNumber || ""
            } Item: ${option?.item?.id || ""} - ${option?.item?.buyingGrade || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.referenceVendorOfferIdList.length > 0
              ? `Reference Vendor Offers Selected (${filterCustomerOffer?.referenceVendorOfferIdList.length})`
              : "Reference Vendor Offer";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.referenceVendorOffer.inputValue}
              onChange={(e) =>
                updateComponentState("referenceVendorOffer", { inputValue: e.target.value })
              }
              onMouseDown={retrieveVendorOffers}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("referenceVendorOffer", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

<Autocomplete
        multiple
        id="outlined-select-item"
        disableClearable
        open={componentsState.item.dropdownOpen}
        onOpen={() => updateComponentState("item", { dropdownOpen: true })}
        inputValue={componentsState.item.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("item", { inputValue: newInputValue });
          }
        }}
        options={items}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.buyingGrade || ""} - ${
            option?.crop || ""
          }`
        }
        value={items.filter((item) =>
          filterCustomerOffer.itemIdList.includes(item.id),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            itemIdList: newValue.map((item) => item.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.buyingGrade || ""} - ${
              option?.crop || ""
            }`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.itemIdList.length > 0
              ? `Items Selected (${filterCustomerOffer?.itemIdList.length})`
              : "Item";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.item.inputValue}
              onChange={(e) =>
                updateComponentState("item", { inputValue: e.target.value })
              }
              onMouseDown={retrieveItems}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("item", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        id="outlined-search"
        label="Tobacco Code"
        value={filterCustomerOffer?.tobaccoCode || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            tobaccoCode: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Sales Grade"
        value={filterCustomerOffer?.salesGrade || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            salesGrade: e.target.value,
          })
        }
      />

<Autocomplete
        multiple
        id="outlined-select-sales-origin"
        disableClearable
        open={componentsState.salesOrigin.dropdownOpen}
        onOpen={() => updateComponentState("salesOrigin", { dropdownOpen: true })}
        inputValue={componentsState.salesOrigin.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("salesOrigin", { inputValue: newInputValue });
          }
        }}
        options={origin}
        getOptionLabel={(option) => option.label || ""}
        value={origin.filter((salesOriginOption) =>
          filterCustomerOffer.salesOriginList.includes(salesOriginOption.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            salesOriginList: newValue.map((salesOriginOption) => salesOriginOption.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label || ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.salesOriginList.length > 0
              ? `Sales Origins Selected (${filterCustomerOffer?.salesOriginList.length})`
              : "Sales Origin";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.salesOrigin.inputValue}
              onChange={(e) =>
                updateComponentState("salesOrigin", { inputValue: e.target.value })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("salesOrigin", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

                <TextField
        id="outlined-search"
        label="Sales Crop"
        value={filterCustomerOffer?.salesCrop || ""}
        type="numeric"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            salesCrop: e.target.value,
          })
        }
      />


<TextField
        id="outlined-search"
        label="Sales Nicotine"
        value={filterCustomerOffer?.salesNicotine || ""}
        type="numeric"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            salesNicotine: e.target.value,
          })
        }
      />

      
<TextField
        id="outlined-search"
        label="Sales Sugar"
        value={filterCustomerOffer?.salesSugar || ""}
        type="numeric"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            salesSugar: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Buying Grade"
        value={filterCustomerOffer?.buyingGrade || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            buyingGrade: e.target.value,
          })
        }
      />

      <TextField
        id="outlined-search"
        label="Customer Grade"
        value={filterCustomerOffer?.customerGrade || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            customerGrade: e.target.value,
          })
        }
      />
      <Autocomplete
        multiple
        id="outlined-select-origin"
        disableClearable
        open={componentsState.origin.dropdownOpen}
        onOpen={() => updateComponentState("origin", { dropdownOpen: true })}
        inputValue={componentsState.origin.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("origin", { inputValue: newInputValue });
          }
        }}
        options={origin}
        getOptionLabel={(option) => option.label || ""}
        value={origin.filter((originOption) =>
          filterCustomerOffer.originList.includes(originOption.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            originList: newValue.map((originOption) => originOption.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label || ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.originList.length > 0
              ? `Origins Selected (${filterCustomerOffer?.originList.length})`
              : "Origin";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.origin.inputValue}
              onChange={(e) =>
                updateComponentState("origin", { inputValue: e.target.value })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("origin", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <Autocomplete
        multiple
        id="outlined-select-type"
        disableClearable
        open={componentsState.type.dropdownOpen}
        onOpen={() => updateComponentState("type", { dropdownOpen: true })}
        inputValue={componentsState.type.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("type", { inputValue: newInputValue });
          }
        }}
        options={type}
        getOptionLabel={(option) => option.label || ""}
        value={type.filter((typeOption) =>
          filterCustomerOffer.typeList.includes(typeOption.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            typeList: newValue.map((typeOption) => typeOption.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label || ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.typeList.length > 0
              ? `Types Selected (${filterCustomerOffer?.typeList.length})`
              : "Type";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.type.inputValue}
              onChange={(e) =>
                updateComponentState("type", { inputValue: e.target.value })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("type", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <Autocomplete
        multiple
        id="outlined-select-leaf-form"
        disableClearable
        open={componentsState.leafForm.dropdownOpen}
        onOpen={() => updateComponentState("leafForm", { dropdownOpen: true })}
        inputValue={componentsState.leafForm.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("leafForm", { inputValue: newInputValue });
          }
        }}
        options={leafForm}
        getOptionLabel={(option) => option.label || ""}
        value={leafForm.filter((leafFormOption) =>
          filterCustomerOffer.leafFormList.includes(leafFormOption.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            leafFormList: newValue.map(
              (leafFormOption) => leafFormOption.value,
            ),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option.label || ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.leafFormList.length > 0
              ? `Leaf Forms Selected (${filterCustomerOffer?.leafFormList.length})`
              : "Leaf Form";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.leafForm.inputValue}
              onChange={(e) =>
                updateComponentState("leafForm", { inputValue: e.target.value })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("leafForm", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Color"
        type="search"
        id="outlined-select-color"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.colorList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              colorList: e.target.value,
            });
          },
        }}
      >
        {color.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="outlined-search"
        label="Crop Year"
        value={filterCustomerOffer?.crop || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            crop: e.target.value,
          })
        }
      />
      <TextField
        label="Current Location"
        type="search"
        value={filterCustomerOffer?.itemStorage || ""}
        id="outlined-storage"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            itemStorage: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-number"
        label="Nicotine"
        value={filterCustomerOffer?.nicotine || ""}
        type="number"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            nicotine: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-number"
        label="Sugar"
        value={filterCustomerOffer?.sugar || ""}
        type="number"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            sugar: e.target.value,
          })
        }
      />
      <TextField
        label="Company"
        type="search"
        id="outlined-select-company"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.companyList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              companyList: e.target.value,
            });
          },
        }}
      >
        {companies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="SG Approval Status"
        type="search"
        id="outlined-select-approval-status"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.approvalStatusList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              approvalStatusList: e.target.value,
            });
          },
        }}
      >
        {status.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Status"
        type="search"
        id="outlined-select-status"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.statusList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              statusList: e.target.value,
            });
          },
        }}
      >
        {offerStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id="date"
        type="date"
        label="Status Deadline"
        value={filterCustomerOffer?.statusDeadline || ""}
        inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            statusDeadline: e.target.value,
          })
        }
      />
      <Autocomplete
        multiple
        id="outlined-select-customer"
        disableClearable
        open={componentsState.customer.dropdownOpen}
        onOpen={() => updateComponentState("customer", { dropdownOpen: true })}
        inputValue={componentsState.customer.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("customer", { inputValue: newInputValue });
          }
        }}
        options={customers}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.name || ""}`
        }
        value={customers.filter((customer) =>
          filterCustomerOffer.stakeholderIdList.includes(customer.id),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            stakeholderIdList: newValue.map((customer) => customer.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.name || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.stakeholderIdList.length > 0
              ? `Customers Selected (${filterCustomerOffer?.stakeholderIdList.length})`
              : "Customer";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.customer.inputValue}
              onChange={(e) =>
                updateComponentState("customer", { inputValue: e.target.value })
              }
              onMouseDown={retrieveCustomers}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("customer", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <Autocomplete
        multiple
        id="outlined-select-cost"
        disableClearable
        open={componentsState.cost.dropdownOpen}
        onOpen={() => updateComponentState("cost", { dropdownOpen: true })}
        inputValue={componentsState.cost.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("cost", { inputValue: newInputValue });
          }
        }}
        options={costs}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.remark || ""}`
        }
        value={costs.filter((costOption) =>
          filterCustomerOffer.costIdList.includes(costOption.id),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            costIdList: newValue.map((costOption) => costOption.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.remark || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.costIdList.length > 0
              ? `Costs Selected (${filterCustomerOffer?.costIdList.length})`
              : "Cost";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.cost.inputValue}
              onChange={(e) =>
                updateComponentState("cost", { inputValue: e.target.value })
              }
              onMouseDown={retrieveCosts}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("cost", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        id="outlined-number"
        label="First Offer"
        value={filterCustomerOffer?.firstOfferPrice || ""}
        type="number"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            firstOfferPrice: e.target.value,
          })
        }
      />


  <TextField
        label="First Offer Price Currency"
        type="search"
        id="outlined-select-first-offer-currency"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.firstPriceCurrencyList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              firstPriceCurrencyList: e.target.value,
            });
          },
        }}
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        id="outlined-number"
        label="Final Offer"
        value={filterCustomerOffer?.finalOfferPrice || ""}
        type="number"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            finalOfferPrice: e.target.value,
          })
        }
      />

<TextField
        label="Final Offer Price Currency"
        type="search"
        id="outlined-select-final-offer-currency"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.finalPriceCurrencyList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              finalPriceCurrencyList: e.target.value,
            });
          },
        }}
      >
        {currencies.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        id="outlined-select-first-sample"
        disableClearable
        open={componentsState.firstSample.dropdownOpen}
        onOpen={() =>
          updateComponentState("firstSample", { dropdownOpen: true })
        }
        inputValue={componentsState.firstSample.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("firstSample", { inputValue: newInputValue });
          }
        }}
        options={samples}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.remark || ""}`
        }
        value={samples.filter((sample) =>
          filterCustomerOffer.firstSampleIdList.includes(sample.id),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            firstSampleIdList: newValue.map((sample) => sample.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.remark || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.firstSampleIdList.length > 0
              ? `First Samples Selected (${filterCustomerOffer?.firstSampleIdList.length})`
              : "First Sample";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.firstSample.inputValue}
              onChange={(e) =>
                updateComponentState("firstSample", {
                  inputValue: e.target.value,
                })
              }
              onMouseDown={retrieveSamples}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("firstSample", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="First Sample Status"
        type="search"
        id="outlined-select-first-sample-status"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.firstSampleStatusList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              firstSampleStatusList: e.target.value,
            });
          },
        }}
      >
        {sampleStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        id="outlined-select-second-sample"
        disableClearable
        open={componentsState.secondSample.dropdownOpen}
        onOpen={() =>
          updateComponentState("secondSample", { dropdownOpen: true })
        }
        inputValue={componentsState.secondSample.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("secondSample", { inputValue: newInputValue });
          }
        }}
        options={samples}
        getOptionLabel={(option) =>
          `${option?.id || ""} - ${option?.remark || ""}`
        }
        value={samples.filter((sample) =>
          filterCustomerOffer.secondSampleIdList.includes(sample.id),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            secondSampleIdList: newValue.map((sample) => sample.id),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {`${option?.id || ""} - ${option?.remark || ""}`}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.secondSampleIdList.length > 0
              ? `Second Samples Selected (${filterCustomerOffer?.secondSampleIdList.length})`
              : "Second Sample";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.secondSample.inputValue}
              onChange={(e) =>
                updateComponentState("secondSample", {
                  inputValue: e.target.value,
                })
              }
              onMouseDown={retrieveSamples}
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("secondSample", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />
      <TextField
        label="Second Sample Status"
        type="search"
        id="outlined-select-second-sample-status"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.secondSampleStatusList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              secondSampleStatusList: e.target.value,
            });
          },
        }}
      >
        {sampleStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

      <Autocomplete
        multiple
        id="outlined-select-payment-term"
        disableClearable
        open={componentsState.paymentTerm.dropdownOpen}
        onOpen={() =>
          updateComponentState("paymentTerm", { dropdownOpen: true })
        }
        inputValue={componentsState.paymentTerm.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("paymentTerm", { inputValue: newInputValue });
          }
        }}
        options={paymentTerm}
        getOptionLabel={(option) =>
          option ? `${option.value} - ${option.label}` : ""
        }
        value={paymentTerm.filter((term) =>
          filterCustomerOffer.paymentTermList.includes(term.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            paymentTermList: newValue.map((term) => term.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option ? `${option.value} - ${option.label}` : ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.paymentTermList.length > 0
              ? `Payment Terms Selected (${filterCustomerOffer?.paymentTermList.length})`
              : "Payment Term";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.paymentTerm.inputValue}
              onChange={(e) =>
                updateComponentState("paymentTerm", {
                  inputValue: e.target.value,
                })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("paymentTerm", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Incoterm"
        type="search"
        id="outlined-select-incoterm"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.incotermList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              incotermList: e.target.value,
            });
          },
        }}
      >
        {incoterms.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="outlined-number"
        label="Number Of Cases"
        value={filterCustomerOffer?.numberOfCases || ""}
        type="number"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            numberOfCases: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-number"
        label="Net Weight Per Case"
        value={filterCustomerOffer?.netWeightPerCase || ""}
        type="number"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            netWeightPerCase: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-number"
        label="Volume"
        value={filterCustomerOffer?.volume || ""}
        type="number"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            volume: e.target.value,
          })
        }
      />
      <TextField
        label="Volume Unit"
        type="search"
        id="outlined-select-volume-unit"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.volumeUnitList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              volumeUnitList: e.target.value,
            });
          },
        }}
      >
        {units.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        id="outlined-number"
        label="Total Amount"
        value={filterCustomerOffer?.totalAmount || ""}
        type="number"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            totalAmount: e.target.value,
          })
        }
      />
      <TextField
        id="date"
        type="date"
        label="Offer Date"
        value={filterCustomerOffer?.offerDate || ""}
        inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            offerDate: e.target.value,
          })
        }
      />
      <TextField
        id="date"
        type="date"
        label="Validity Date"
        value={filterCustomerOffer?.validityDate || ""}
        inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            validityDate: e.target.value,
          })
        }
      />
      <TextField
        id="outlined-search"
        label="Remark"
        value={filterCustomerOffer?.remark || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            remark: e.target.value,
          })
        }
      />
            <TextField
        id="outlined-search"
        label="Comment"
        value={filterCustomerOffer?.comment || ""}
        type="search"
        onChange={(e) =>
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            comment: e.target.value,
          })
        }
      />

<Autocomplete
        multiple
        id="outlined-select-port-of-loading"
        disableClearable
        open={componentsState.portOfLoading.dropdownOpen}
        onOpen={() =>
          updateComponentState("portOfLoading", { dropdownOpen: true })
        }
        inputValue={componentsState.portOfLoading.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("portOfLoading", { inputValue: newInputValue });
          }
        }}
        options={portsOfLoading}
        getOptionLabel={(option) =>
          option ? `${option.value} - ${option.label}` : ""
        }
        value={portsOfLoading.filter((term) =>
          filterCustomerOffer.portOfLoadingList.includes(term.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            portOfLoadingList: newValue.map((term) => term.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option ? `${option.value} - ${option.label}` : ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.portOfLoadingList.length > 0
              ? `Ports Of Loading Selected (${filterCustomerOffer?.portOfLoadingList.length})`
              : "Port Of Loading";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.portOfLoading.inputValue}
              onChange={(e) =>
                updateComponentState("portOfLoading", {
                  inputValue: e.target.value,
                })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("portOfLoading", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

<Autocomplete
        multiple
        id="outlined-select-port-of-discharge"
        disableClearable
        open={componentsState.portOfDischarge.dropdownOpen}
        onOpen={() =>
          updateComponentState("portOfDischarge", { dropdownOpen: true })
        }
        inputValue={componentsState.portOfDischarge.inputValue}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            updateComponentState("portOfDischarge", { inputValue: newInputValue });
          }
        }}
        options={portsOfDischarge}
        getOptionLabel={(option) =>
          option ? `${option.value} - ${option.label}` : ""
        }
        value={portsOfDischarge.filter((term) =>
          filterCustomerOffer.portOfDischargeList.includes(term.value),
        )}
        onChange={(event, newValue) => {
          setFilterCustomerOffer({
            ...filterCustomerOffer,
            portOfDischargeList: newValue.map((term) => term.value),
          });
        }}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              ...props.style,
              backgroundColor: selected ? "lightblue" : undefined,
              fontWeight: selected ? 700 : 400,
            }}
          >
            {option ? `${option.value} - ${option.label}` : ""}
          </li>
        )}
        renderInput={(params) => {
          const label =
            filterCustomerOffer?.portOfDischargeList.length > 0
              ? `Ports Of Discharge Selected (${filterCustomerOffer?.portOfDischargeList.length})`
              : "Port Of Discharge";

          return (
            <TextField
              {...params}
              label={label}
              type="search"
              value={componentsState.portOfDischarge.inputValue}
              onChange={(e) =>
                updateComponentState("portOfDischarge", {
                  inputValue: e.target.value,
                })
              }
              InputProps={{
                ...params.InputProps,
                style: { height: 42 },
              }}
              onBlur={() =>
                updateComponentState("portOfDischarge", { dropdownOpen: false })
              }
            />
          );
        }}
        renderTags={() => null}
      />

      <TextField
        label="Document Status"
        type="search"
        id="outlined-select-document-status"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.documentStatusList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              documentStatusList: e.target.value,
            });
          },
        }}
      >
        {status.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Record Status"
        type="search"
        id="outlined-select-record-status"
        select
        SelectProps={{
          multiple: true,
          value: filterCustomerOffer.recordStatusList || [],
          onChange: (e) => {
            setFilterCustomerOffer({
              ...filterCustomerOffer,
              recordStatusList: e.target.value,
            });
          },
        }}
      >
        {recordStatus.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

export default CustomerOfferSearch;
