import {
  Button,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import React from "react";
import { updateInventoryApi } from "../../../components/api/InventoryApiService";
import { companies, recordStatus } from "../../../utils/menu-items";

const EditInventory = (props) => {
  const { editInventory, setEditInventory } = props;
  return (
    <>
      <Stack direcion="column">
        <Typography variant="body1" mb={2}>
          Edit Inventory Record
        </Typography>
        <Grid container item xs={12} spacing="8px">
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Ownership</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-ownership"
              select
              value={editInventory?.data?.ownership}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    ownership: e.target.value,
                  },
                })
              }
            >
              {companies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Item</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editInventory?.data?.item?.id || ""}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    item: {
                      ...(editInventory.data.item || {}),
                      id:
                        e.target.value !== ""
                          ? parseInt(e.target.value, 10)
                          : null,
                    },
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Current Location</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.storage}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    storage: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Storage Type</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.storageType}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    storageType: e.target.value,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Cost</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="number"
              value={editInventory?.data?.cost?.id || ""}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    cost: {
                      ...(editInventory.data.cost || {}),
                      id:
                        e.target.value !== ""
                          ? parseInt(e.target.value, 10)
                          : null,
                    },
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Payment Amount</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.paymentAmount}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    paymentAmount: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Payment Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.paymentStatus}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    paymentStatus: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remaining Payment</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.remainingPayment}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    remainingPayment: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Payment Deadline</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editInventory?.data?.paymentDeadline
                  ? format(
                      parseISO(editInventory.data.paymentDeadline),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    paymentDeadline: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Inventory Type</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.inventoryType}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    inventoryType: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Net Weight Per Case</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.netWeightPerCase}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    netWeightPerCase: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Number Of Cases</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.numberOfCases}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    numberOfCases: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Entry Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editInventory?.data?.entryDate
                  ? format(parseISO(editInventory.data.entryDate), "yyyy-MM-dd")
                  : ""
              }
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    entryDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Exit Date</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editInventory?.data?.exitDate
                  ? format(parseISO(editInventory.data.exitDate), "yyyy-MM-dd")
                  : ""
              }
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    exitDate: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipping Deadline</Typography>
            <TextField
              id="date"
              type="date"
              inputProps={{ min: "1999-01-01", max: "2999-01-01" }}
              InputLabelProps={{
                shrink: true,
              }}
              onKeyDown={(e) => e.preventDefault()}
              variant="outlined"
              size="small"
              fullWidth
              value={
                editInventory?.data?.shippingDeadline
                  ? format(
                      parseISO(editInventory.data.shippingDeadline),
                      "yyyy-MM-dd",
                    )
                  : ""
              }
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    shippingDeadline: e.target.value
                      ? e.target.value + "T00:00:00.000+00:00"
                      : null,
                  },
                })
              }
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipped Out In Case</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.shippedOutCase}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    shippedOutCase: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipped Out In Kg</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.shippedOutKg}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    shippedOutKg: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipped In In Case</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.shippedInCase}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    shippedInCase: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Shipped In In Kg</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.shippedInKg}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    shippedInKg: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Final Stock</Typography>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.finalStock}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    finalStock: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Rejection</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.rejection}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    rejection: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Remark</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.remark}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    remark: e.target.value,
                  },
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Record Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              id="outlined-select-status"
              select
              value={editInventory?.data?.recordStatus}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    recordStatus: e.target.value,
                  },
                })
              }
            >
              {recordStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography variant="body2">Edit Status</Typography>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              value={editInventory?.data?.editingPermission}
              onChange={(e) =>
                setEditInventory({
                  ...editInventory,
                  data: {
                    ...editInventory.data,
                    editingPermission: e.target.value,
                  },
                })
              }
            />
          </Grid>
        </Grid>
        <Stack direction="row" spacing={1} mt={1}>
          <Button
            variant="outlined"
            onClick={() =>
              setEditInventory({ visible: false, data: null, index: null })
            }
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              updateInventoryApi(editInventory.data.id, editInventory.data)
                .then((response) => {
                  window.location.reload();

                  setEditInventory({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  console.log(error.response.data);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              editInventory.data.recordStatus = "PASSIVE";
              updateInventoryApi(editInventory.data.id, editInventory.data)
                .then((response) => {
                  window.location.reload();
                  setEditInventory({ visible: false, data: null, index: null });
                })
                .catch((error) => {
                  console.log(error.response.data);
                });
            }}
          >
            Delete
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default EditInventory;
