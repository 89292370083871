import { apiClient } from "./ApiClient";

export const createSampleApi = (sample) =>
  apiClient.post(`/sample-view/samples/add`, sample);

export const retrieveAllSamplesApi = () =>
  apiClient.get(`/sample-view/samples`);

export const updateSampleApi = (sampleId, sample) =>
  apiClient.put(`/sample-view/samples/edit/${sampleId}`, sample);

export const retrieveFilteredSamplesApi = (sample) =>
  apiClient.post(`/sample-view/samples/filter`, sample);

export const retrievePurposeFilteredSamplesApi = (samplePurpose) =>
  apiClient.post(`/sample-view/samples/purpose-filter/${samplePurpose}`);

export const retrieveSampleByIdApi = (sampleId) => apiClient.get(`/sample-view/samples/get/${sampleId}`);